import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Pagination from 'partials/pagination';
import Breadcrumbs from 'partials/breadcrumbs';
import Image from 'partials/image';
import Helper from 'helpers';
import Lang from '../lang';
import { ShopView } from 'grips';
import queryString from 'query-string'
import Cookie from 'assets/js/cookie-client/collect-cookie';
import Loader from 'partials/loader';
import ShopWidget from 'views/shop/widget';

class ProductList extends React.PureComponent {
    state = {
        products: [],
        view_mode: 'grid',
        params: {
            per_page: 12,
            page: 1,
            sorted: ''
        },
        meta: {
            total: false,
            from: 1,
            last_page: 4,
            to: 10,
        },
        category_name: this.props.match.params.category_name ? this.props.match.params.category_name : '',
        loader: false,
        brands: [],
        brand_models: [],
        activeIsChecked: true,
        brand: null,
        model: null
    }

    _isMounted = false;
    lang = Lang[global.language];

    viewModeGrid = () => {
        if (this._isMounted) {
            this.setState({ view_mode: 'grid' });
        }
    }
    viewModeList = () => {
        if (this._isMounted) {
            this.setState({ view_mode: 'list' });
        }
    }

    renderGrips(area) {
        return (
            ShopView[area].map((Controller, index) => <Controller {...this.props.signpost} key={index} />)
        )
    }

    render() {
        let { state } = this;
        let { brand, model } = state;
        let { wishList, filtered_brand_models } = this.props;

        return (
            !state.loader 
                ? 
                <React.Fragment>
                    <div className='main-header background' style={{ background: 'linear-gradient(rgba(28, 28, 28, 0.3), rgba(28, 28, 28, 0.3)), url("/themes/default/img/backgrounds/background-product-grid.jpg")' }}>
                        <div className="container">
                            <h1 className="main-header-title text-center block">
                                <span>{this.lang.shop}</span>
                            </h1>
                        </div>
                    </div>

                    <Breadcrumbs sites={[{ name: this.lang.products_list_title }]} />
                    <div className='container'>
                        <div className='row'>

                            <div className='col-md-12' style={{ marginBottom: '25px', textAlign: 'center', padding: '20px 15px', background: '#434343 url("/themes/default/img/backgrounds/config-bg.png") no-repeat left center' }}>
                                <h2 style={{ textTransform: 'uppercase', color: '#fff', fontSize: '1.28em', margin: '0 0 15px' }}>{this.lang.select_car_brand_model}</h2>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <ShopWidget.ProductFilter 
                                            name={'brand'}
                                            url={'/api/shop/brands'}
                                            onChange={this.handleChangeBrand}
                                            value={brand}
                                            renderParam={ { value: 'name' } }
                                            setValue={this.setBrands}
                                            async={true}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <ShopWidget.ProductFilter 
                                            name={'model'}
                                            onChange={this.handleChangeModel}
                                            value={model}
                                            data={filtered_brand_models}
                                            no_options_message={this.lang.select_brand}
                                        />
                                    </div>
                                    <div className='col-md-4'>
                                        {this.renderGrips(3)}
                                    </div>
                                    <div className='col-md-2'>
                                        <input type="checkbox" id="activeProducts" value={state.activeIsChecked} checked={state.activeIsChecked} onChange={this.toggleCheckboxActiveChange} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                                        <label htmlFor="activeProducts" style={{ color: '#fff', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} >
                                            {this.lang.only_available}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-9 col-md-push-3'>

                                <div className='product-header-actions'>
                                    <form action='product-grid.html' method='POST' className='form-inline' >
                                        <div className='row'>
                                            <div className='col-md-4 col-sm-6'>
                                                <div className='view-icons'>
                                                    <span className={`view-icon ${state.view_mode === 'grid' ? 'active' : ''}`} onClick={this.viewModeGrid}><span className='icon icon-th'></span></span>
                                                    <span className={`view-icon ${state.view_mode === 'list' ? 'active' : ''}`} onClick={this.viewModeList}><span className='icon icon-th-list'></span></span>
                                                </div>
                                                {
                                                    this.state.meta.total &&
                                                    <div className='view-count'>
                                                        <span className='text-muted'>{this.lang.from} {this.state.meta.from} {this.lang.to} {this.state.meta.to} {this.lang.of} {this.state.meta.total}</span>
                                                    </div>
                                                }

                                            </div>

                                            <div className='col-md-8 col-sm-6 col-xs-12'>
                                                <div className='form-show-sort'>
                                                    <div className='form-group pull-left'>
                                                        <label htmlFor='p_show'>{this.lang.show}</label>
                                                        <select
                                                            className='form-control input-sm'
                                                            value={this.state.params.per_page}
                                                            onChange={this.changePerPage}
                                                        >
                                                            <option value='12' >12</option>
                                                            <option value='24' >24</option>
                                                            <option value='48' >48</option>
                                                            <option value='96' >96</option>
                                                        </select>
                                                        <strong>{this.lang.on_page}</strong>
                                                    </div>

                                                    <div className='form-group pull-right text-right'>
                                                        <label htmlFor='p_sort_by'>{this.lang.sort}</label>
                                                        <select className='form-control input-sm' onChange={this.sortBy} style={{ maxWidth: '130px'}}>
                                                            <option value='' > --{this.lang.none}-- </option>
                                                            <option value='ASC' >{this.lang.price_asc}</option>
                                                            <option value='DESC' >{this.lang.price_desc}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {model &&                
                                <div className="menu_search_category_description mrg-b">
                                    <div className="config_description">
                                        <div className="config_dscr clearfix">
                                            <div className="config_img col-md-6 col-xs-12">
                                                <img src={model.image ? `${model.image.path}300x250.jpg` : '/themes/default/img/samples/products/grid/1.jpg'} border="0" alt="" />
                                            </div>
                                            <div className="config_dscr_sub col-md-6 col-xs-12">
                                                <strong className="config_name">{`${model.brand_name} ${model.label}`}</strong>
                                                <ul className="config_traits">
                                                    <li>
                                                        <strong>{this.lang.brand}: </strong>{model.brand_name}
                                                    </li>
                                                    <li>
                                                        <strong>{this.lang.model}: </strong>{model.label}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <div className='products'>
                                    {
                                        this.state.products.map((product, key) => {
                                            return (
                                                <React.Fragment key={key}>
                                                    <div key={product.id} className={`${state.view_mode === 'list' ? 'col-md-12' : 'col-md-4 col-sm-4 col-xs-12'}`}>
                                                        <div className={`product product-${state.view_mode}`}>
                                                            <div className='product-media'>
                                                                <div className='product-thumbnail border'>
                                                                    <Link to={`${this.props.moduleUrl}/${product.slug}`} title={product.name}>
                                                                        {product.has_promotion !== 0 && <div className='product-promotion'>{this.lang.promotion}</div>}
                                                                        {
                                                                            product.image ?
                                                                                <React.Fragment>
                                                                                    <Image
                                                                                        image={product.image}
                                                                                        size='300x300'
                                                                                    />
                                                                                </React.Fragment>
                                                                                : <img src='/themes/default/img/samples/products/grid/1.jpg' alt='' className='current' />
                                                                        }

                                                                    </Link>
                                                                </div>


                                                                <div className='product-hover'>
                                                                    <div className='product-actions'>
                                                                        {
                                                                            wishList && wishList.find(item => item.id == product.id) 
                                                                                ?
                                                                                <Link to={`/${this.lang.slug.shop}/${this.lang.slug.wish_list}`} className='Awe_add_to_wishlist awe-button added' title={this.lang.wish_list}>
                                                                                    <span className='icon icon-star'></span>
                                                                                </Link>
                                                                                :
                                                                                <a 
                                                                                    className='Awe_add_to_wishlist awe-button cursor-pointer' 
                                                                                    title={this.lang.add_to_whishlist} 
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        this.addToWishList(product);
                                                                                        return false;
                                                                                    }}
                                                                                >
                                                                                    <span className='icon icon-star'></span>
                                                                                </a>
                                                                        }
                                                                        <Link to={`${this.props.moduleUrl}/${product.slug}`} className='product-quick-view awe-button' title={this.lang.preview}>
                                                                            <span className='fa fa-eye'></span>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='product-body'>
                                                                <h2 className='product-name'>
                                                                    <Link to={`${this.props.moduleUrl}/${product.slug}`} title={product.name}>{product.name}</Link>
                                                                </h2>

                                                                <div className='product-price'>
                                                                    <span className='amount'>{`${Helper.currencyFormatter().format(product.min_selling_price)}`}</span>
                                                                </div>

                                                                {
                                                                    state.view_mode === 'list' &&
                                                                    <div className='product-description' dangerouslySetInnerHTML={{ __html: product.description }}></div>
                                                                }

                                                                <div className='product-category'>
                                                                    <span>{`${this.lang.category_title}:`}</span>
                                                                    <Link to={`${this.props.moduleUrl}/${this.lang.slug.category}/${product.category_slug}`} title={product.category}>{product.category}</Link>
                                                                </div>

                                                                {/* <div className='product-list-actions'>
                                                                    <Link to={`${this.props.moduleUrl}/${product.slug}`} className='btn btn-lg btn-primary' type='button' >{this.lang.product_details}</Link>
                                                                </div> */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {(key+1)%3 === 0 && <div className="clearfix"></div>}
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 overflow-auto'>
                                        <Pagination
                                            page={this.state.params.page}
                                            last_page={this.state.meta.last_page}
                                            onChange={this.changePage}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3 col-md-pull-9'>
                                <div id='shop-widgets-filters' className='shop-widgets-filters'>

                                    <div id='widget-area' className='widget-area'>
                                        <aside id='shop-widgets-filters-category' className='widget woocommerce widget_product_categories'>
                                            {this.renderGrips(0)}
                                        </aside>

                                        <aside id='shop-widgets-filters-producer' className='widget woocommerce widget_layered_nav woocommerce-widget-layered-nav'>
                                            {this.renderGrips(1)}
                                        </aside>

                                        <aside id='shop-widgets-filters-attributes' className='widget woocommerce widget_layered_nav woocommerce-widget-layered-nav'>
                                            {this.renderGrips(2)}
                                        </aside>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </React.Fragment>
                :
                <div className='preloader'>
                    <div className='preloader-wrapper'>
                        <Loader type='small' />
                    </div>
                </div>
        )
    }

    updateData(data) {
        if (this._isMounted) {
            this.setState({ products: data.data });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        let query = queryString.parse(this.props.location.search);
        // Sprawdzamy czy w zapytaniu url znajdują się określone parametry
        if ('search' in query) {
            this.setSearchProduct(query.search);
        } else {
            this.loadProductsListByCategoryName().then(() => {  
                if (!this.props.list_product) {
                    let filter = { availbility: this.state.activeIsChecked };
                    this.props.updateValue({ state: 'poduct_list_filters', data: filter, join: true });
                } else {
                    this.updateData(this.props.list_product);
                    if (this._isMounted) {
                        this.setState({ 
                            meta: this.props.meta || this.state.meta, 
                            params: this.props.params || this.state.params 
                        });
                    }
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.reset_products_list) {
            this.resetProductsList();
            this.resetFilters();
            this.resetAttributes();
            let query = `?`;
            query += this.filterQuery();
            this.loadData({}, query);
        } else {
            this.filterListProducts(prevProps.filters, prevProps.attributes);
        }
    }

    /**
     * Pobiera z url i ustawia szukaną nazwę produktu w reduxowym store
     * 
     * @param {String} query Parametry wyszukiwania
     * @return {undefined}
     */
    setSearchProduct = (query) => {
        this.props.updateValue({ state: 'poduct_list_filters', data: { name:  query }, join: true });
    }

    /**
     * Resetuje listę produktów
     * 
     * @return { undefined }
     */
    resetProductsList() {
        this.props.updateValue({ state: 'reset_shop_products_list', data: false });
    }

    /**
     * Resetuje filtry
     * 
     * @return { undefined }
     */
    resetFilters() {
        this.props.updateValue({ state: 'poduct_list_filters' });
        let filter = { availbility: this.state.activeIsChecked };
        this.props.updateValue({ state: 'poduct_list_filters', data: filter, join: false });
    }

    /**
     * Resetuje filtry atrybutów
     * 
     * @return { undefined }
     */
    resetAttributes() {
        this.props.updateValue({ state: 'poduct_list_attribute_filter' });
    }

    /**
     * Tworzy żądanie na podstawie filtrów
     * 
     * @return { string }
     */
    filterQuery() {
        let filters = this.props.filters;
        let queryArray = [];
        let filter_id = 0;

        for (let filter_name in filters) {
            if (filters.hasOwnProperty(filter_name)) {
                let filter = filters[filter_name];
                if (filter_name === 'category') {
                    this.sendCookie(filter);
                }
                if (filter) {
                    queryArray.push(`filtered[${filter_id}][id]=${filter_name}&filtered[${filter_id}][value]=${filter}`);
                    filter_id++;
                }
            }
        }

        let queryString = queryArray.length ? `${queryArray.join('&')}` : '';
        return queryString;
    }

    sendCookie = (id) => {
        let cookie = {
            "category_id": id,
            "url": `/${this.lang.shop}/${this.lang.category}/${this.props.shop_category_slug}`
        };
        Cookie.collect(cookie);
    }

    /**
     * Tworzy żądanie na podstawie atrybutów
     * 
     * @return { string }
     */
    filterQueryAttributes() {
        let attributes = this.props.attributes;
        let queryArray = [];
        let filter_id = 0;

        for (let attribute_name in attributes) {
            if (attributes.hasOwnProperty(attribute_name)) {
                let value = attributes[attribute_name].label;
                queryArray.push(`attributes[${filter_id}][id]=${attribute_name}&attributes[${filter_id}][value]=${value}`);
                filter_id++;
            }
        }

        let queryString = queryArray.length ? `${queryArray.join('&')}&` : '';
        return queryString;
    }

    /**
     * Filtruje listę produktów na zmianę parametrów filtrów
     * 
     * @param { object } filters - Filtry
     * @param { object } attributes - Atrybuty
     * @return { undefined }
     */
    filterListProducts(filters, attributes) {
        if ((JSON.stringify(attributes) !== JSON.stringify(this.props.attributes))
            || (JSON.stringify(filters) !== JSON.stringify(this.props.filters))) {
            let { params } = this.state;
            let query = '';
            query += this.filterQueryAttributes();
            query += this.filterQuery();
            query = query ? '?' + query : '';
            params.page = 1;
            this.loadData(params, query);
        }
    }

    /**
     * Filtruje listę produktów na podstawie sluga kategorii
     * 
     * @return { undefined }
     */
    loadProductsListByCategoryName() {
        return new Promise ((resolve) => {
            let { category_name } = this.state;
            if (category_name) {
                this.props.load({ url: `/api/shop/categories/${category_name}` }).then(res => {
                    if (!res.error && res.data.id) {
                        let data = res.data;
                        let category_branch = [...data.ancestors, { ...data }];
                        this.props.updateValue({ state: 'category_branch', data: this.toObject(category_branch) });
                        this.props.updateValue({ state: 'poduct_list_filters', data: { category: data.id }, join: true });
                    } else if (res.status && res.status === 404) {
                        this.props.history.push({
                            pathname: '/404',
                            state: {
                                pathname: this.props.match.url
                            }
                        })
                    }
                    resolve(true);
                });
                this.setCategorySlug(category_name);
            } else {
                resolve(true);
            }
        })
    }
    
    /**
     * Ustawia slug kategorii w reduxowym store
     * 
     * @param {String} slug - Nazwa kategorii 
     */
    setCategorySlug(slug) {
        this.props.updateValue({ state: 'shop_category_slug', data: slug });
    }

    /**
     * Konwertuję tablicą obiektów kategorii na obiekt
     * 
     * @param { array } arr - Tablica obiektów kategorii
     * @return { object }
     */
    toObject(arr) {
        let rv = {};
        for (let i = 0; i < arr.length; ++i)
            rv[arr[i].id] = arr[i];
        return rv;
    }

    loadData(data = {}, query = '') {
        if (!data.sorted) {
            delete data.sorted;
        }

        if (!Object.keys(data).length) {
            data = {
                page: 1,
                per_page: 12
            };
        }

        this.props.load({ url: `api/shop/search${query}`, state: 'list_product', data }).then(res => {
            if (res.meta) {
                let params = {
                    per_page: res.meta.per_page,
                    page: res.meta.current_page,
                    sorted: data.sorted
                }
                let meta = {
                    total: res.meta.total,
                    from: res.meta.from,
                    last_page: res.meta.last_page,
                    to: res.meta.to,
                }
                this.props.updateValue({
                    state: 'list_product_params',
                    data: params
                })
                this.props.updateValue({
                    state: 'list_product_meta',
                    data: meta
                })
                if (this._isMounted) {
                    this.setState({ meta, params });
                }
                this.updateData(res);
            }
        });
    }

    changePage = (page) => {
        let { params } = this.state;
        params.page = page;
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        // Filtrowanie
        let query = '';
        query += this.filterQueryAttributes();
        query += this.filterQuery();
        query = query ? '?' + query : '';
        this.loadData(params, query);
    }

    sortBy = (e) => {
        let { params } = this.state;
        let value = e.target.value;
        if (!value) {
            delete params.sorted;
        } else {
            params.sorted = this.parseToGetString([{
                id: 'min_selling_price',
                desc: value == 'ASC'
            }], 'sorted');

        }

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        // Filtrowanie
        let query = '';
        query += this.filterQueryAttributes();
        query += this.filterQuery();
        query = query ? '?' + query : '';

        this.loadData(params, query);
    }

    changePerPage = (e) => {
        let { params } = this.state;
        params.per_page = e.target.value;
        params.page = 1;
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        // Filtrowanie
        let query = '';
        query += this.filterQueryAttributes();
        query += this.filterQuery();
        query = query ? '?' + query : '';

        this.loadData(params, query);
    }

    parseToGetString(target, name) {
        let url = '';
        target.map((value, index) => {
            if (typeof value == 'object') {
                Object.keys(value).map(key => {
                    url += `${name}[${index}][${key}]=${value[key]}&`;
                })
            } else {
                url += `${name}[]=${value}&`
            }
        })
        url = url.slice(0, url.length - 1);
        return url;
    }

    addToCart = (product) => {
        if (this.props.addCart) {
            this.props.addCart(product);
        }
    }

    addToWishList = (product) => {
        if (this._isMounted) {
            this.setState({
                loader: true
            });
        }
        this.props.load({ url: `/api/shop/products-by-slug/${product.slug}` }).then(res => {
            if (!res.error) {
                let variations = res.data.variations;
                product = { ...product, ...{ currentVariation: variations[0], variations: variations } };
                this.props.addWishList(product);
            }
            if (this._isMounted) {
                this.setState({
                    loader: false
                });
            }
        });
    }

    setBrands = (data) => {
        let brand_models = [];

        let brands = data.map(brand => {
            brand_models = [ ...brand_models, ...brand.brand_models ];
            return {
                value: brand.id,
                label: brand.name
            }
        });

        brand_models = brand_models.map(model => {
            return {
                value: model.id,
                label: model.name,
                brand_id: model.brand_id,
                brand_name: model.brand_name,
                image: model.image
            }
        });
        if (this._isMounted) {
            this.setState({ brand_models, brands });
        }
        return brands;
    }

    /**
     * Ustawia modele dla wybranej marki
     * 
     * @param { Integer } brand_id Identyfikator marki
     * @return { undefined }
     */
    setModels = (brand_id) => {
        let { brand_models } = this.state;
        let filtered_brand_models = brand_models
            .filter(model => model.brand_id == brand_id)
            .sort((a, b) => {
                if(a.label < b.label) { return -1; }
                if(a.label > b.label) { return 1; }
                return 0;
            });
        this.props.updateValue({ state: 'filtered_brand_models', data: filtered_brand_models });
    }

    /**
     * Przechwytuje zmianę wartości elementu select i ustawia state
     * 
     * @param { Object } value - Obiekt filtrujący
     * @return { undefined }
     */
    handleChangeBrand = (value) => {
        let stringValue = !!value ? value.value : null;
        this.setModels(stringValue);
        let filter = { brand: stringValue, model: null };
        let brand = this.setSelectedBrand(filter);
        let model = this.setSelectedModel(filter);
        if (this._isMounted) {
            this.setState({ brand, model });
        }
        this.props.updateValue({ state: 'poduct_list_filters', data: filter, join: true });
    }

    /**
     * Przechwytuje zmianę wartości elementu select i ustawia state
     * 
     * @param { Object } value - Obiekt filtrujący
     * @return { undefined }
     */
    handleChangeModel = (value) => {
        let stringValue = !!value ? value.value : null;
        let filter = { model: stringValue };
        let model = this.setSelectedModel(filter);
        if (this._isMounted) {
            this.setState({ model });
        }
        this.props.updateValue({ state: 'poduct_list_filters', data: filter, join: true });
    }

    /**
     * Zwraca obiekt reprezentujący wartość select modelu
     * 
     * @param {Object|undefined} filter Obiekt filtrów
     * @return {Object|null}
     */
    setSelectedModel(filter) {
        let { filtered_brand_models } = this.props;
        let model = null;
        if (!!filtered_brand_models) {
            let value = !!filter && filter.model;
            value = filtered_brand_models.find(item => item.value == value);
            model = value !== undefined ? value : null;
        }
        return model;
    }
    
    /**
     * Zwraca obiekt reprezentujący wartość select marki
     * 
     * @param {Object|undefined} filter Obiekt filtrów
     * @return {Object|null}
     */
    setSelectedBrand(filter) {
        let { brands } = this.state;
        let value = !!filter && filter.brand;
        value = brands.find(item => item.value == value);
        let brand = value !== undefined ? value : null;
        return brand;
    }

    toggleCheckboxActiveChange = () => {
        let checked = !this.state.activeIsChecked;
        if (this._isMounted) {
            this.setState({
                activeIsChecked: checked
            }, () => {
                let filter = { availbility: checked };
                this.props.updateValue({ state: 'poduct_list_filters', data: filter, join: true });
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

}

const mapStateToProps = (state) => {
    return {
        loader: state.boost.loader,
        list_product: state.boost.list_product,
        params: state.boost.list_product_params,
        meta: state.boost.list_product_meta,
        addCart: state.boost['shop.widget.cart.add'],
        filters: state.boost.poduct_list_filters,
        attributes: state.boost.poduct_list_attribute_filter,
        reset_products_list: state.boost.reset_shop_products_list,
        shop_category_slug: state.boost.shop_category_slug,
        addWishList: state.boost['shop.widget.wishList.add'],
        wishList: state.boost[`shop.widget.wishList`],
        filtered_brand_models: state.boost.filtered_brand_models
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue,
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductList);