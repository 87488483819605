import React from 'react';
import { Link } from 'react-router-dom';
import Lang from './lang';

class Slider extends React.PureComponent {

    lang = Lang[global.language];

    render() {
        return (
            <div className='container main-slider-wrapper'>

                <div className="vc_row wpb_row vc_row-fluid">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div className="vc_empty_space" style={{ height: '50px' }}>
                                    <span className="vc_empty_space_inner"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="vc_row wpb_row vc_row-fluid">
                    <div className="wpb_column vc_column_container vc_col-sm-6">
                        <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                                <div className="wpb_single_image wpb_content_element vc_align_center   awe-media-image">
                                    <figure className="wpb_wrapper vc_figure">
                                        <Link to={`/${this.lang.slug.slug_shop}/${this.lang.slug.slug_category}/${this.lang.slug_part_car_body}`} className="vc_single_image-wrapper   vc_box_border_grey">
                                            <img width="1024" height="800" src="/themes/default/img/slider/czesci_karoserii.png" className="vc_single_image-img attachment-full" alt="" srcSet="/themes/default/img/slider/czesci_karoserii.png 1024w, /themes/default/img/slider/czesci_karoserii.png 300w, /themes/default/img/slider/czesci_karoserii.png 768w, /themes/default/img/slider/czesci_karoserii.png 600w" sizes="(max-width: 1024px) 100vw, 1024px" />
                                        </Link>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-6">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div className="wpb_single_image wpb_content_element vc_align_center   awe-media-image">
                                    <figure className="wpb_wrapper vc_figure">
                                        <Link to={`/${this.lang.slug.slug_shop}/${this.lang.slug.slug_category}/${this.lang.slug_workshop_tools}`} className="vc_single_image-wrapper   vc_box_border_grey">
                                            <img width="1024" height="800" src="/themes/default/img/slider/narzedzia_warsztatowe.png" className="vc_single_image-img attachment-full" alt="" srcSet="/themes/default/img/slider/narzedzia_warsztatowe.png 1024w, /themes/default/img/slider/narzedzia_warsztatowe.png 300w, /themes/default/img/slider/narzedzia_warsztatowe.png 768w, /themes/default/img/slider/narzedzia_warsztatowe.png 600w" sizes="(max-width: 1024px) 100vw, 1024px" />
                                        </Link>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-6">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div className="wpb_single_image wpb_content_element vc_align_center   awe-media-image">
                                    <figure className="wpb_wrapper vc_figure">
                                        <Link to={`/${this.lang.slug.slug_shop}/${this.lang.slug.slug_category}/${this.lang.slug_lighting}`} className="vc_single_image-wrapper   vc_box_border_grey">
                                            <img width="1024" height="800" src="/themes/default/img/slider/oswietlenie.png" className="vc_single_image-img attachment-full" alt="" srcSet="/themes/default/img/slider/oswietlenie.png 1024w, /themes/default/img/slider/oswietlenie.png 300w, /themes/default/img/slider/oswietlenie.png 768w, /themes/default/img/slider/oswietlenie.png 600w" sizes="(max-width: 1024px) 100vw, 1024px" />
                                        </Link>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-6">
                        <div className="vc_column-inner ">
                            <div className="wpb_wrapper">
                                <div className="wpb_single_image wpb_content_element vc_align_center   awe-media-image">
                                    <figure className="wpb_wrapper vc_figure">
                                        <Link to={`/${this.lang.slug.slug_shop}/${this.lang.slug.slug_category}/${this.lang.slug_car_windows}`} className="vc_single_image-wrapper   vc_box_border_grey">
                                            <img width="1024" height="800" src="/themes/default/img/slider/szyby_samochodowe.png" className="vc_single_image-img attachment-full" alt="" srcSet="/themes/default/img/slider/szyby_samochodowe.png 1024w, /themes/default/img/slider/szyby_samochodowe.png 300w, /themes/default/img/slider/szyby_samochodowe.png 768w, /themes/default/img/slider/szyby_samochodowe.png 600w" sizes="(max-width: 1024px) 100vw, 1024px" />
                                        </Link>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            // <div className='main-slider-wrapper'>
            //     <div className='main-slider owl-carousel owl-carousel-inset'>

            //         <div className='main-slider-item'>
            //             <div className='main-slider-image'>
            //                 <img src='/themes/default/img/slider/slider1.jpg' alt='' />
            //             </div>

            //             <div className='main-slider-text'>
            //                 <div className='fp-table'>
            //                     <div className='fp-table-cell center'>
            //                         <div className='container'>
            //                             <h3>The Shoes For Summer</h3>
            //                             <h2>Shoes Vans<br />$160.00</h2>

            //                             <div className='button'>
            //                                 <a href='#' className='btn btn-lg btn-primary margin-right-15'>Shop now</a>
            //                                 <a href='#' className='btn btn-lg btn-white btn-outline'>Browse category</a>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>

            //         <div className='main-slider-item'>
            //             <div className='main-slider-image'>
            //             <img src='/themes/default/img/slider/slider2.jpg' alt='' />
            //             </div>

            //             <div className='main-slider-text'>
            //                 <div className='fp-table'>
            //                     <div className='fp-table-cell center'>
            //                         <div className='container'>
            //                             <h3 className='small'>New Fashion For Winter</h3>
            //                             <h2 className='small'>New Jacket <br /> only $60</h2>

            //                             <div className='button'>
            //                                 <a href='#' className='btn btn-lg btn-primary margin-right-15'>Shop now</a>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>

            //         <div className='main-slider-item'>
            //             <div className='main-slider-image'>
            //             <img src='/themes/default/img/slider/slider3.jpg' alt='' />
            //             </div>

            //             <div className='main-slider-text'>
            //                 <div className='fp-table'>
            //                     <div className='fp-table-cell center'>
            //                         <div className='container'>
            //                             <h3 className='light'>New T-shirt</h3>
            //                             <h2 className='small margin-bottom-25'>Vintage Raglan</h2>

            //                             <div className='dots-color center margin-bottom-35'>
            //                                 <span className='orange'></span>
            //                                 <span className='blue'></span>
            //                                 <span className='green'></span>
            //                                 <span className='red'></span>
            //                                 <span className='black'></span>
            //                             </div>

            //                             <div className='button'>
            //                                 <a href='#' className='btn btn-lg btn-primary'>Shop now</a>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        )
    }

    componentDidMount() {
        var $slider = $('.main-slider');

        $slider.owlCarousel({
            items: 1,
            nav: true,
            loop: true,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            dots: true,
            onInitialized: function () {
                $slider.find('.owl-item').each(function () {
                    var $owlItem = $(this);

                    var $mainSlide = $owlItem.find('.main-slider-item');
                    var $mainImage = $owlItem.find('.main-slider-image > img');

                    if ($mainSlide.length && $mainImage.length) {
                        $mainSlide.addClass('background');
                        $mainSlide.css('background-image', 'url(' + $mainImage.attr('src') + ')');

                        $mainImage.css({
                            opacity: 0,
                            visibility: 'hidden'
                        });
                    }
                });
            }
        });
    }
}

export default Slider;