import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Lang from './lang';

const Breadcrumbs = (props) => (
    <div id='breadcrumb'>
        <div className='container'>
            <ol className='breadcrumb'>
                <li><Link to='/'>{Lang[global.language].home}</Link></li>
                {
                    props.sites.map( (site, key) =>
                        key === props.sites.length - 1 ?
                        <li key={key} className='active'> <span>{site.name}</span></li>
                        :   <li key={key}><Link to={site.to}>{site.name}</Link> </li>
                            
                       
                    )
                }
            </ol>

        </div>
    </div>
)

Breadcrumbs.defaultProps = {
    sites: [],
}

Breadcrumbs.propTypes = {
    sites: PropTypes.array,
}

export default Breadcrumbs;