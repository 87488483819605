import React from 'react';
import Menu from 'widget/menu';
import PopupModal from 'widget/popup-modal';
import Cart from 'views/shop/widget/cart';
import WishList from 'views/shop/widget/wish-list'
import Slider from 'widget/slider';
import Newsletter from 'widget/newsletter';
import NewsletterModal from 'widget/newsletter/modal';
import ShopWidget from 'views/shop/widget';
import UserWidget from 'views/user/widget';
import LangWidget from 'views/shop/widget/lang'

export const Home = {
    0: [Cart, WishList, UserWidget],
    1: [Menu],
    2: [PopupModal],
    3: [NewsletterModal],
    4: [LangWidget]
}

export const HomeView = {
    0: [Slider],
    1: [
        { innerRender: (props) => <ShopWidget.Category {...props} uniq={'last-watched'}/> },
        { innerRender: (props) => <ShopWidget.Category {...props} uniq={'you-may-like'}/> }
    ],
    2: [],
    3: [Newsletter]
}

export const ProductView = {
    0: [
        { innerRender: (props) => <ShopWidget.Category {...props} uniq={'fit-into'}/> }
    ]
}

export const ShopView = {
    0: [ShopWidget.CategoryTree],
    1: [ShopWidget.Producers],
    2: [ShopWidget.Attributes],
    3: [ShopWidget.ProductSearch]
}