import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import { Link } from 'react-router-dom';
import Lang from '../lang';
import Helper from 'helpers';
import Loader from 'partials/loader';
import Cookie from 'assets/js/cookie-client/collect-cookie';

export const MAX_QTY = 10000;

class ShopCart extends React.PureComponent {
    lang = Lang[global.language];

    state = {
        errors: {},
        loader: false,
        regulation: false,
        privacy_policy: false
    }

    render() {
        let total = 0;
        return (
            !this.state.loader ?
            <div id='main'>

                <div className='main-header background' style={{ background: 'linear-gradient(rgba(28, 28, 28, 0.3), rgba(28, 28, 28, 0.3)), url("/themes/default/img/backgrounds/background-about.jpg")' }}>
                    <div className="container">
                        <h1 className="main-header-title text-center block">
                            <span>{this.lang.cart_title}</span>
                        </h1>
                    </div>
                </div>

                <div id='breadcrumb'>
                    <div className='container'>
                        <ol className='breadcrumb'>
                            <li><Link to='/'>{this.lang.home}</Link></li>
                            <li className='active'><span>{this.lang.cart_title}</span></li>
                        </ol>
                    </div>
                </div>

                <div className='cart-page'>
                    <main className='container'>
                        {
                            this.props.cart && this.props.cart.length ?
                                <React.Fragment>
                                    <div className='table-responsive'>
                                        <table className='table table-cart'>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th className='absorbing-column'>{this.lang.product}</th>
                                                    <th className='absorbing-column'>{this.lang.unit}</th>
                                                    <th className='absorbing-column'>{this.lang.qty}</th>
                                                    <th className='absorbing-column'>{this.lang.subtotal}</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    this.props.cart.map((product, key) =>
                                                        <tr key={key}>
                                                            <td className='product-remove'>
                                                                <a
                                                                    className='remove'
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        this.removeCart(key);
                                                                        return false;
                                                                    }}
                                                                >
                                                                    &times;
                                                                </a>
                                                            </td>
                                                            <td className='product-thumbnail'>
                                                                <Link to={`${this.props.moduleUrl}/${product.slug}/${product.id}`} title='' className='product-cart'>
                                                                    {
                                                                        product.images.length ?
                                                                            <img src={product.images[0].thumb} alt='' />
                                                                            :   <img src='/themes/default/img/samples/products/cart/1.jpg' alt='' />
                                                                    }
                                                                </Link>
                                                            </td>
                                                            <td className='cart-product'>
                                                                {(() => { total += product.price * product.count })()}
                                                                <Link to={`${this.props.moduleUrl}/${product.slug}/${product.id}`} title='' className='product-cart'>
                                                                    {product.name}
                                                                </Link>
                                                            </td>

                                                            <td><span className='amount'>{`${Helper.currencyFormatter().format(product.price)}`}</span></td>
                                                            <td>
                                                                <div className="input-number">
                                                                    <input type="number" className="input-text qty text" step="1" min="1" max={MAX_QTY} value={product.count} onChange={this.handleOnChange.bind(this, key, product)} onBlur={this.handleOnBlur.bind(this, key)} onFocus={this.handleOnFocus.bind(this, key)} />
                                                                    <button className="number-act number-inc" onClick={() => this.updateCount(product.count + 1, key, product, 1)}><i className="fa fa-plus"></i></button>
                                                                    <button className="number-act number-dec" onClick={() => this.updateCount(product.count - 1, key, product, -1)}><i className="fa fa-minus"></i></button>
                                                                </div>
                                                            </td>
                                                            <td><span className='amount lowercase'>{`${Helper.currencyFormatter().format(product.price * product.count)}`}</span></td>
                                                        </tr>
                                                    )
                                                }

                                                <tr>
                                                    <td colSpan='6' className='actions'>
                                                        <div className='coupon'>
                                                            <form onSubmit={this.submit} className='code-form'>
                                                                <input 
                                                                    type='text' 
                                                                    id='code' 
                                                                    name='code' 
                                                                    placeholder={this.lang.coupon_code_title} 
                                                                    className='input-text form-control' 
                                                                    onChange={this.updateCode} 
                                                                    value={this.props.code || ''}
                                                                />
                                                                <input type='submit' value={this.lang.apply_coupon_title} className='btn btn-primary' />
                                                                {this.showError('code')}
                                                            </form>
                                                        </div>
                                                    </td>
                                                </tr>   

                                            </tbody>
                                        </table>
                                    </div>
                                    <form onSubmit={e => {
                                        e.preventDefault();
                                        this.goto(`${this.props.moduleUrl}/${this.lang.slug.checkout}`);
                                        return false;
                                    }}>                
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='col-md-6'></div>
                                                <div className='col-md-6'>
                                                    <h2 className='upper'>{this.lang.cart_summary_title}</h2>
                                                    <table className='table'>
                                                        <tbody>
                                                            <tr className='order-total'>
                                                                <th>{this.lang.to_pay}</th>
                                                                <td><strong><span className='amount lowercase'>{`${Helper.currencyFormatter().format(total)}`}</span></strong></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='col-md-6'>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label htmlFor='terms_of_use'>
                                                            <input type='checkbox' name='regulation' id='terms_of_use' className='terms_of_use' value={this.state.regulation} checked={this.state.regulation} onChange={this.onCheckboxChange} required />
                                                            {this.lang.i_accept_the_terms_of_the}
                                                            <a onClick={e => {
                                                                e.preventDefault();
                                                                this.goto(`/${this.lang.slug.page}/${this.lang.slug.terms_conditions}`);
                                                                return false;
                                                            }}
                                                            >{` ${this.lang.regulations}`}</a> <sup>*</sup>
                                                        </label>
                                                    </div>
                                                    <div className='form-group'>
                                                        <label htmlFor='withdrawal_from_contract'>
                                                            <input type='checkbox' name='privacy_policy' id='withdrawal_from_contract' className='withdrawal_from_contract' value={this.state.privacy_policy} checked={this.state.privacy_policy} onChange={this.onCheckboxChange} required />
                                                            {this.lang.i_accept_the_terms_of_the}
                                                            <a onClick={e => {
                                                                e.preventDefault();
                                                                this.goto(`/${this.lang.slug.page}/${this.lang.slug.policies_slug}`);
                                                                return false;
                                                            }}
                                                            >{` ${this.lang.privacy_policy}`}</a> <sup>*</sup>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <button onClick={this.handleClickLeaveCart} className='checkout-button leave-cart-button button alt wc-forward'>
                                                    {this.lang.leave_cart_title}
                                                </button>
                                                <button type='submit' className='go-to-checkout-button button alt wc-forward'>
                                                    {this.lang.proceed_checkout_title}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </React.Fragment>
                                : <p className="cart-empty">{this.lang.cart_empty}</p>
                        }
                    </main>
                </div>
            </div>
            :
            <div className='preloader'>
                <div className='preloader-wrapper'>
                    <Loader type='small'/>
                </div>
            </div>
        )
    }

    /**
     * Przechwytuje zdarzenie kliknięcia przycisku porzucenia koszyka
     * 
     * @return {undefined}
     */
    handleClickLeaveCart = () => {
        this.props.updateValue({ state: 'shop.widget.cart' });
        localStorage.removeItem('shop.cart');
        this.leaveCart();
        // Po wyczyszczeniu koszyka przechodzimy do sklepu
        this.props.history.push(`${this.props.moduleUrl}`);
        this.updateCartTooltip(this.lang.items_removed_shopping_cart);
    }

    /**
     * Wyświetla tooltip informulący o zmianie wartości koszyka
     * 
     * @param {String} message - Komunikat
     * @return {undefined}
     */
    updateCartTooltip(message) {
        toastr.success(message);
    }

    /**
     * Wysyła informację o opuszczeniu koszyka przez użytkownika
     * 
     * @return {undefined}
     */
    leaveCart = () => {
        let leave_cart_products = this.props.cart.map(product => ({
            product_id: product.product_id,
            quantity: -product.count
        }));
        Cookie.collect(leave_cart_products,'leaveCart')
    }

    /**
     * Przechwytuje zdarzenie dezaktywacji pola wprowadzania ilości produktów
     * 
     * @param {Integer} index - Indeks produktu w koszyku
     * @param {Object} e - Event
     * @return {undefined}
     */
    handleOnBlur = (index, e) => {
        let count = parseInt(e.target.value, 10);
        if (isNaN(count) || count <= 0) {
            this.props.addCart(false, {update_count: 1, id: index});
            this.forceUpdate();
        }
    }

    /**
     * Przechwytuje zdarzenie aktywacji pola wprowadzania ilości produktów
     * 
     * @param {Integer} index - Indeks produktu w koszyku
     * @param {Object} e - Event
     * @return {undefined}
     */
    handleOnFocus = (index, e) => {
        let count = parseInt(e.target.value, 10);
        if (isNaN(count) || count === 0) {
            this.props.addCart(false, {update_count: '', id: index});
            this.forceUpdate();
        }
    }

    /**
     * Przechwytuje zdarzenie zmiany wartości pola wprowadzania ilości produktów
     * 
     * @param {Integer} index - Indeks produktu w koszyku
     * @param {Object} product - Obiekt produktu z koszyka
     * @param {Object} e - Event
     * @return {undefined}
     */
    handleOnChange = (index, product, e) =>{
        let count = e.target.value;

        if (count > MAX_QTY) {
            return;
        }

        if(count <= product.quantity) {

            // Zabezpieczenie przed podaniem przez użytkownika 0 w polu ilości produktu
            count = count <= 0 ? '' : count ;

            // Jeżeli wartość jest większa od 0 (string) rzutujemy ją na number 
            // aby przy inkrementacj i dekrementacji (przyciski +/-) nie łączyło string z integer
            count = parseInt(count, 10) >  0 ? parseInt(count, 10) : count

            this.props.addCart(false, {update_count: count, id: index});
        } else if(count > product.quantity) {
            toastr.warning(this.lang.noAmountAvailable);
        }

        this.forceUpdate();
    }

    componentDidMount() {
        let code = this.getCode();
        this.props.updateValue({ state: 'code', data: code });
    }

    componentDidUpdate() {
        this.removeFromCartWhenNoCount();
    }

    /**
     * Usuwa z koszyka pozycję gdy brak dostępnej ilości
     *
     * @return {undefined}
     */
    removeFromCartWhenNoCount = () => {
        let { cart } = this.props;
        if (cart && cart.length) {
            cart.map((item, key) => {
                if (item.count === 0) {
                    this.removeCart(key);
                }
            });
        }
    }

    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    updateCode = (e) => {
        let code = e.target.value;
        this.props.updateValue({ state: 'code', data: code });
    }

    /**
     * Przechwytuje zdarzenie przesłania formularza dodawania kodu rabatowego
     * 
     * @return {undefined}
     */
    submit = (e) => {
        e.preventDefault();
        this.props.updateValue({ state: 'errors', data: {} });
        let { code, cart } = this.props;
        if (!code) {
            toastr.warning('Należy wprowadzić kod rabatowy');
        } else {
            this.saveCodeLocalStorage(code); // Zapisujemy kod w localStorage
            this.updateCartPrice(cart); // Aktualizujemy koszyk
        }
    }

    /**
     * Usuwa z obiektu błedów informacje o błedach dla wybranego pola formularza
     * 
     * @param {String} property - Nazwa pola, dla którego występują błędy walidacji
     */
    clearErrors(property) {
        let errors = this.state.errors;
        delete errors[property];
        this.setState({ errors });
    }

    /**
     * Zwraca kod rabatowy
     * 
     * @return {String}
     */
    getCode() {
        if (localStorage.getItem('shop.cart.code') !== null) {
            return localStorage.getItem('shop.cart.code');
        }
        return '';
    }

    /**
     * Wyświetla lub ukrywa loader
     * 
     * @return {undefined}
     */
    showLoader() {
        this.setState({ loader: !this.state.loader });
    }

    /**
     * Aktualizuje ceny produktów w koszyku
     * 
     * @param {Object} data - Dane produktów po rabacie
     * @param {undefined}
     */
    updateCartPrice = (data) => {
        let { calculateCart } = this.props;
        calculateCart(data);
    }

    /**
     * Zapisuje kod rabatowy w localStorage
     * 
     * @param {String} code - Kod rabatowy
     * @return {undefined}
     */
    saveCodeLocalStorage(code = '') {
        localStorage.setItem('shop.cart.code', code);
    }

    /**
     * Wyświetla błędy walidacji
     * 
     * @param {String} name - Nazwa input
     * @return {undefined}
     */
    showError = (name) => {
        let { errors } = this.props;
        if (errors[name]) {
            return (
                <React.Fragment>
                    {
                        errors[name].map((error, key) =>
                            <div className={`text-danger`} style={{ textAlign: 'left' }} key={key}>{error.replace(name, this.lang[name])}</div>
                        )
                    }
                </React.Fragment>
            )
        }
    }

    /**
     * Aktualizuje wartość state'ów
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    onCheckboxChange = (e) => {
        this.setState({[e.target.name]: !(e.target.value === 'true')});
    }

    /**
     * Przenosi na określony adres url
     * 
     * @param {String} url - Adres url
     * @return {undefined}
     */
    goto = (url) => {
        window.location.href = url;
    }

    updateCount = (value, index, product, quantity) => {
        let count = value;

        if (count > MAX_QTY) {
            return;
        }

        if(count > 0 && count <= product.quantity) {
            let cookie = {
                "product_id": product.product_id, 
                "quantity": quantity
            };
            Cookie.collect(cookie, 'add2cart');
            this.props.addCart(false, {update_count: count, id: index});
        } else if(count > product.quantity) {
            toastr.warning(this.lang.noAmountAvailable);
        }
        this.forceUpdate();
    }

    removeCart = (key) => {
        this.forceUpdate();
        this.props.removeCart(key)
    }
}

ShopCart.defaultProps = {

}

const mapStateToProps = (state) => {
    return {
        loader: state.boost.loader,
        cart: state.boost[`shop.widget.cart`],
        addCart: state.boost['shop.widget.cart.add'],
        removeCart: state.boost['shop.widget.cart.remove'],
        calculateCart: state.boost['shop.widget.cart.calculate'],
        code: state.boost.code,
        errors: state.boost.errors
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShopCart);