export default {
    on_page: 'on page',
    show: 'Show',
    cart_title: 'Cart',
    product_name: 'Product name',
    qty: 'Quantity',
    subtotal: 'Total',
    unit: 'Price',
    cart_empty: 'Your cart is currently empty',
    sort: 'Sort',
    none: 'None',
    price_asc: 'Price ascending',
    price_desc: 'Price descending',
    add_to_cart: 'Add to cart',
    products: 'Products',
    product: 'Product',
    from: 'from',
    to: 'to',
    of: 'of',
    available: 'available',
    unavailable: 'unavailable',
    view_cart: 'View cart',
    process_checkout: 'Go to checkout',
    total: 'Total',
    price: 'Price',
    checkout_title: 'Summary',
    categories: 'Categories',
    shop: 'shop',
    category: 'categories',
    cart: 'cart',
    checkout: 'checkout-process',
    order_summary: 'order-summary',
    order_summary_title: 'Order summary',
    producers_title: 'Manufacturers',
    start_writing: 'Start typing',
    attributes_title: 'Attributes',
    search_product_title: 'Search product',
    notification: 'notification',
    recommendation: 'recommendation',
    isReminderDesc: 'Notification about product availability',
    isRecommendationDesc: 'Recommend product',
    variantsTitle: 'Variants',
    selectTitle: 'Select',
    sendTitle: 'Send',
    price_from_title: 'From',
    category_title: 'Category',
    promotion: 'promotion',
    customer_panel: 'Customer panel',
    slug_user_account: 'user account',
    couponTitle: 'Coupon',
    enterRebateCodeTitle: 'Enter your discount code',
    checkDiscountTitle: 'Check discount',
    cartAddError: 'There was an error during adding a product to cart',
    noAmountAvailable: 'There is not enough quantity available at the warehouse',
    no_products_to_display_title: 'No products to display',
    'last-watched': 'Watched lately',
    'you-may-like': 'You may like',
    'fit-into': 'Match with',
    coupon_code_title: 'Coupon code',
    apply_coupon_title: 'Use code',
    proceed_checkout_title: 'Go to checkout',
    cart_summary_title: 'Cart summary',
    amount_title: 'Amount',
    new: 'Waiting for payment',
    failed: 'No payment',
    success: 'Paid',
    canceled: 'Canceled',
    leave_cart_title: 'Abandon cart',
    home: 'Home',
    product_details: 'view product',
    product_description_title: 'Product description',
    variants_title: 'Variants',
    currency: 'zł',
    no_comments_for_product_title: 'There are no comments for this product',
    products_list_title: 'List of products',
    first_name: 'First name',
    add_first_name: 'Add first name',
    last_name: 'Last name',
    add_last_name: 'Add last name',
    firm_name_optional: 'Firm name (optional)',
    add_firm_name_optional: 'Add firm name (optional)',
    nip_optional: 'Nip (optional)',
    add_nip_optional: 'Add nip (optional)',
    country: 'Country',
    street: 'Street',
    add_street: 'Add street',
    zip_code: 'Zip code',
    add_zip_code: 'Add zip code',
    city: 'City',
    add_city: 'Add city',
    phone: 'Phone',
    add_phone: 'Add phone',
    email: 'Email',
    add_email: 'Add email',
    your_order: 'Your order',
    contact_address: 'Contact address',
    copy_from_contact_address: 'Copy from contact address',
    shipping_address: 'Shipping address',
    add_shipment_address: 'Add shipment address',
    not_need_invoice: 'I don\'t need an invoice',
    add_invoice_address: 'Add invoice data',
    invoice_address: 'Invoice address',
    product_name: 'Product name',
    quantity: 'Quantity',
    value: 'Value',
    total: 'Total',
    summary: 'Summary',
    shipment: 'Shipment',
    courier: 'courier',
    on_line_payments: 'On-line payments',
    cash_on_delivery: 'Cash on delivery',
    delivery_options: 'Delivery options',
    payment_methods: 'Payment methods',
    select: 'Select',
    buy_and_pay: 'I order with a payment obligation',
    order_number: 'Order number',
    date: 'Date',
    payment_method: 'Payment method',
    payment_status: 'Payment status',
    order_details: 'Order details',
    received_your_order: 'Thank you. We have received your order',
    comments: 'Comments',
    country_name_lang: 'name_en',
    bank_transfer: 'Bank transfer',
    slug_image_preview: 'image-preview',
    preview: 'Preview',
    description: 'Description',
    additional_information: 'Additional information',
    resources: 'Resources',
    reviews: 'Reviews',
    no_opinion_about_product_yet: 'There is no opinion about the product yet',
    cart_value_updated: 'Shopping cart has been updated',
    item_removed_shopping_cart: 'Item has been removed from shopping cart',
    items_removed_shopping_cart: 'Items have been removed from shopping cart',
    order: 'Order',
    comments_to_order: 'Comments to order',
    optional: 'optional',
    add_comments_to_order: 'Add comments to order',
    to_pay: 'To pay',
    agree_with: 'I agree with ',
    site_regulations: ' terms & conditions',
    page: 'page',
    terms_conditions: 'terms-conditions',
    reviewed_right_withdraw_from: 'I have reviewed the right to withdraw from the',
    contract: ' contract',
    returns_and_replacements: 'returns-and-replacements',
    // Wish list
    wish_list_empty: 'No products were added to the wishlist',
    add_to_whishlist: 'Add to wishlist',
    view_wishlist: 'View wishlist',
    wish_list: 'Wish list',
    wishlist_empty: 'No products have been added to the wish list',
    availability: 'Availability',
    available_title: 'Available',
    unavailable_title: 'Unavailable',
    product_added_wish_list: 'Product added to wish list',
    personalize: 'Personalize',
    // slugi
    slug: {
        shop: 'sklep',
        page: 'strona',
        checkout: 'proces-zamawiania',
        cart: 'koszyk',
        order_summary: 'podsumowanie-zamowienia',
        category: 'kategoria',
        slug_image_preview: 'podglad-zdjecia',
        returns_and_replacements: 'zwroty-i-reklamacje',
        terms_conditions: 'regulamin',
        wish_list: 'lista-zyczen',
        policies_slug: 'warunki-umowy',
        shipping_costs: 'koszty-wysylki',
    },
    '0d': 'immediately',
    '1d': '1 day',
    '2d': '2 days',
    '3d': '3 days',
    estimated_delivery_time: 'Estimated delivery time',
    traditional_transfer: 'Traditional transfer',
    bank_transfer_details: 'Bank transfer details',
    bank_name: 'Bank name',
    bank_account_number: 'Bank account number',
    include_order_number: 'The title of the transfer should include the order number',
    firm_name: 'Firm name',
    address: 'Address',
    delivery_cost: 'Delivery cost',
    package_quantity: 'Package quantity',
    dedicated_cost: 'Dedicated cost',
    not_added: 'Not added',
    dimensions: 'Dimensions',
    length: 'Length', 
    width: 'Width',
    height: 'Height',
    custom_delivery_cost: 'Custom delivery cost',
    not_applicable: 'Not applicable',
    target_point: 'Destination point name',
    set_target_point: 'Enter destination point name',
    brand: 'Marka',
    model: 'Model',
    fits_to: 'Fits to',
    no_matching_models_given: 'No matching models given',
    no_options_message: 'No options',
    select_brand: 'Select a brand to get a list of models',
    code: 'Code',
    select_car_brand_model: 'Select car brand and model',
    only_available: 'Only available',
    producer_nr: 'Producer nr',
    delivery_and_cost: 'Delivery and cost',
    i_accept_the_terms_of_the: 'I accept the terms',
    regulations: 'regulations',
    privacy_policy: 'privacy policy',
    producer: 'Producer',
    quality: 'quality',
    producer_name: 'Producer name',
    country_origin: 'Country of origin',
    delivery_costs_as_in: 'Delivery costs as in the',
    price_list: 'price list'
}