import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Lang from '../lang';
import Select from 'react-select';
import PropTypes from 'prop-types';
import reactSelectStyles from 'partials/react-select-styles';

let attributes = {};

class AttributesWidget extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        attributes: []
    }

    render() {
        let { attributes } = this.state;
        return (
            <React.Fragment>
                <h4 className='text-uppercase widget-title'>
                    <span>{this.lang.attributes_title}</span>
                </h4>
                <ul>
                    {
                        attributes.map(({ name, values, system }, key) => {
                            return !system && <AttributesWidgetHelper { ...this.props } key={key} name={name} values={values} />
                        })
                    }
                </ul>
            </React.Fragment>   
        );
    }

    componentDidMount() {
        if (!this.props.shop_attributes) {
            this.props.load({ url: '/api/shop/search/attributes' }).then(res => {
                this.props.updateValue({ state: 'shop_attributes', data: res });
                this.loadData(res);
            })
        } else {
            this.loadData(this.props.shop_attributes);
        }
    }

    /**
     * Przypisuję listę atrybutów do state
     * 
     * @param { object } res - Obiekt odpowiedzi
     * @param { array } red.data - Tablica obiektów atrybutów
     * @return { undefined }
     */
    loadData = ({ data }) => {
        this.setState({ attributes: data });
    }

}

class AttributesWidgetHelper extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        attribute: null
    }

    render() {
        let { name, values } = this.props;
        let { attribute } = this.state;
        let options = this.createOptions(values);
        return (
            <li>
                <h5>{name}</h5>
                <Select
                    value={attribute}
                    onChange={this.handleChange}
                    options={options}
                    isSearchable={true}
                    isClearable={true}
                    placeholder={this.lang.start_writing}
                    styles={reactSelectStyles}
                />
            </li>
        );
    }

    /**
     * Przechwytuje zmianę wartości elementu select i ustawia state
     * 
     * @param { object } attribute - Obiekt wartości atrybutu
     * @return { undefined }
     */
    handleChange = (attribute) => {
        let name = this.props.name;

        if (attribute !== null) {
            attributes[name] = attribute;
        } else {
            delete attributes[name];
        }

        this.setState({ attribute });
        this.props.updateValue({ state: 'poduct_list_attribute_filter', data: { ...attributes } });
        this.scrollToFilters();
    }

    scrollToFilters = () => {
        document.getElementById('shop-widgets-filters-attributes').scrollIntoView();
    }

    /**
     * Tworzy tablicę obiektów opcji odpowiednią dla select
     * 
     * @param { array } attribute - Tablica wartości atrybutów
     * @return { array }
     */
    createOptions = (attribute) => {
        return attribute.map(({id, value }) => ({
            value: id,
            label: value
        }));
    }

}

AttributesWidgetHelper.propTypes = {
    name: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
    return {
        shop_attributes: state.boost.shop_attributes
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateValue,
    load
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AttributesWidget);