import React from 'react';
import { Route } from 'react-router-dom';

class ContainerDefault extends React.Component {
    render() {
        console.log('ContainerDefault', this.props)
        return (
            <div>
                Wybrany kontener: ContainerDefault
                {this.props.children}
            </div>
        )
    }
}

export default ContainerDefault;