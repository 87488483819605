import React from 'react';
import Modal from 'react-responsive-modal';
import Newsletter from './index';

const ONE_SECOND = 1000;
const ONE_HOUR = ONE_SECOND * 3600;
const DELAY = 12;
const DURATION = ONE_SECOND * 15;

class NewsletterModal extends React.Component {

    interval = false;

    state = {
        open: false
    }

    render() {
        return (
            <Modal 
                open={this.state.open} 
                onClose={this.close} 
                styles={{
                    overlay: {
                        zIndex: 9999
                    },
                    modal: {
                        minWidth: '50%',
                        backgroundImage: 'url("/themes/default/img/slider/czesci_karoserii_no_txt.png")',
                        backgroundSize: '100% 100%'
                    }
                }}
                closeIconSize={15}
                center
            >
                <div className='newsletter-modal'>
                    <Newsletter inModal={true} close={this.close}/>
                </div>
            </Modal>
        );
    }

    /**
     * Inicjalizuje wyświetlanie okienka popup
     * 
     * @return {undefined}
     */
    intDisplayModal = () => {
        this.interval = setInterval(this.displayModal, DURATION)
    }

    /**
     * Wyświetla okienko popup
     * 
     * @return {undefined}
     */
    displayModal = () => {
        let display = this.windowCanBeDisplayed();

        if (display) {
            this.setState({
                open: true
            });
            localStorage.setItem('shop.newsletter.popup.lastDisplayTime', new Date().getTime());
        }
    }

    /**
     * Określa czy okienko popup powinno być wyświetlone
     * 
     * @return {Boolean}
     */
    windowCanBeDisplayed = () => {
        let lastDisplayTime = localStorage.getItem('shop.newsletter.popup.lastDisplayTime');

        if (lastDisplayTime !== null) {
            let hours = (new Date().getTime() - lastDisplayTime) / ONE_HOUR;
            hours = parseInt(hours);
            // Sprawdzenie czy miną wymagany czas od ostatniego wyświetlenia aby okienko mogło być kolejny raz wyświetlone
            if (hours < DELAY) {
                return false;
            }
        }

        return true;
    }

    /**
     * Zamyka okienko popup
     * 
     * @return {undefined}
     */
    close = () => {
        this.setState({
            open: false
        });
    }

    componentDidMount() {
        this.intDisplayModal();
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

}

export default NewsletterModal;