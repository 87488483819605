import React from 'react';
import API from 'boost/API';
import Modal from 'react-responsive-modal';

const ONE_SECOND = 1000;
const ONE_HOUR = ONE_SECOND * 3600;
const DELAY = 12;
const DURATION = ONE_SECOND * 30;

class PopupModal extends React.PureComponent {

    duration = DURATION;
    
    key = 0;

    slugs = [
        'newsletter',
        'discount',
        'promotion',
        'new_collection'
    ];

    interval = false;

    state = {
        open: false,
        title: '',
        content: '',
    }

    render() {

        let { open, title, content } = this.state;

        return (
            <Modal 
                open={open} 
                onClose={this.close} 
                styles={{
                    overlay: {
                        zIndex: 9999
                    },
                    modal: {
                        minWidth: '50%'
                    }
                }}
                closeIconSize={15}
                center
            >
                <div style={{ padding: '15px' }}>
                    <h2>{title}</h2>
                    <div dangerouslySetInnerHTML={{__html: content}}></div> 
                </div>
            </Modal>
        );
    }

    componentDidMount() {
        this.intDisplayModal();
    }

    /**
     * Ustawia zawartość okienka popup
     * 
     * @param {String} slug - Slug
     * @return {undefined}
     */
    setModatData = (slug) => {
        API.get({ url: `/api/popup-manager/item/${slug}` }).then(res => {
            let data = res.data;

            if (!data.error) {
                let { title, content, dateFrom, dateTo } = data.data;
                let date_from_api = new Date(dateFrom);
                let date_from = new Date();
                let date_to_api = new Date(dateTo);
                let date_to = new Date();

                let update = (date_from_api.getTime() <= date_from.getTime()) && (date_to_api.getTime() >= date_to.getTime());

                if (update) {
                    let open = true;
                    this.setState({
                        title, 
                        content,
                        open
                    });
                    this.duration = DURATION;
                } else {
                    // Gdy okienko straciło ważność czas trwania interwału jest skracany aby 
                    // użytkonkik mógł jak najszybciej zobaczyć kolejne ważne okienko
                    this.duration = ONE_SECOND;
                }
            } else {
                this.duration = ONE_SECOND;
            }

            clearInterval(this.interval);
            this.interval = setInterval(this.updateModalData, this.duration);
        });
    }

    /**
     * Pobiera z localStorage klucz sluga z ostatniej sesji
     * 
     * @return {undefined}
     */
    startKey = () => {
        if (localStorage.getItem('shop.popup.last_interval_key') !== null) {
            this.key = localStorage.getItem('shop.popup.last_interval_key');
        }
    }

    /**
     * Pobiera sluga na podstawie, którego są pobierane dane dla okienka popup
     * 
     * @return {undefined}
     */
    updateModalData = () => {
        let slug = this.slugs[this.key];

        if (typeof slug === 'undefined') {
            this.key = 0;
            slug = this.slugs[this.key];
        }

        let show = this.windowCanBeDisplayed();

        if (show) {

            let popup_windows = localStorage.getItem('shop.popup.windows_displayed');

            if (popup_windows === null) {
                popup_windows = {};
            } else {
                popup_windows = JSON.parse(localStorage.getItem('shop.popup.windows_displayed'));
            }

            // Aktualizacja czasu ostatniego wyświetlenia
            popup_windows = { ...popup_windows, ...{ [this.key]: {
                    slug: slug,
                    time: new Date().getTime()
                } }
            };

            localStorage.setItem('shop.popup.windows_displayed', JSON.stringify(popup_windows));

            this.setModatData(slug);

        }

        localStorage.setItem('shop.popup.last_interval_key', this.key);
        this.key++;
    }

    /**
     * Określa czy okienko popup powinno być wyświetlone
     * 
     * @return {Boolean}
     */
    windowCanBeDisplayed = () => {
        let popup_windows = localStorage.getItem('shop.popup.windows_displayed');

        if (popup_windows !== null) {
            popup_windows = JSON.parse(localStorage.getItem('shop.popup.windows_displayed'));
            let slug_object = popup_windows[this.key];

            if (typeof slug_object !== 'undefined') {
                let hours = (new Date().getTime() - slug_object.time) / ONE_HOUR;
                hours = parseInt(hours, 10);

                // Sprawdzenie czy miną wymagany czas od ostatniego wyświetlenia aby okienko mogło być kolejny raz wyświetlone
                if (hours < DELAY) {
                    return false;
                }
            }
        }

        return true;
    }

    /**
     * Inicjalizuje wyświetlanie okienka popup
     * 
     * @return {undefined}
     */
    intDisplayModal = () => {
        this.startKey();
        this.interval = setInterval(this.updateModalData, this.duration)
    }

    /**
     * Zamyka okienko popup
     * 
     * @return {undefined}
     */
    close = () => {
        this.setState({
            open: false
        })
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

}

export default PopupModal;