import React from 'react';
import { connect } from 'react-redux';
import Lang from '../lang';
import { Link } from 'react-router-dom';

class WishList extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        loader: false
    }

    render() {

        let { wishList } = this.props;

        return (
            <div id='main'>

                <div className='main-header background' style={{ background: 'linear-gradient(rgba(28, 28, 28, 0.3), rgba(28, 28, 28, 0.3)), url("/themes/default/img/backgrounds/background-about.jpg")' }}>
                    <div className="container">
                        <h1 className="main-header-title text-center block">
                            <span>{this.lang.wish_list}</span>
                        </h1>
                    </div>
                </div>

                <div id='breadcrumb'>
                    <div className='container'>
                        <ol className='breadcrumb'>
                            <li><Link to='/'>{this.lang.home}</Link></li>
                            <li className='active'><span>{this.lang.wish_list}</span></li>
                        </ol>
                    </div>
                </div>

                <div className='whish-list-page'>
                    <main className='container'>
                        {
                            wishList && wishList.length 
                                ?
                                <div className='col-md-9'>
                                    <div className='table-responsive'>
                                        <table className='table table-wishlist'>

                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>{this.lang.product_name}</th>
                                                    <th>{this.lang.unit}</th>
                                                    <th>{this.lang.availability}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    wishList.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className='product-remove'>
                                                                    <a 
                                                                        className='remove cursor-pointer' 
                                                                        onClick={e => {
                                                                            e.preventDefault();
                                                                            this.removeWishList(key);
                                                                            return false;
                                                                        }}>
                                                                        &times;
                                                                    </a>
                                                                </td>
                                                                <td className='product-thumbnail'>
                                                                    <Link to={`${this.props.moduleUrl}/${item.slug}/${item.id}`} title=''>
                                                                        {
                                                                            item.image
                                                                                ?
                                                                                <img src={item.image.thumb} alt='' />
                                                                                :   
                                                                                <img src='/themes/default/img/samples/products/cart/1.jpg' alt='' />
                                                                        }
                                                                    </Link>
                                                                </td>
                                                                <td className='product-wishlist'>
                                                                    <Link to={`${this.props.moduleUrl}/${item.slug}/${item.id}`} title=''>
                                                                        {item.name}
                                                                    </Link>
                                                                </td>
                                                                <td className='product-price'>{this.renderPrice(item.currentVariation)}</td>
                                                                <td className='product-stock-status'>
                                                                    {
                                                                        item.variations.reduce((prevVal, curVal) => (prevVal.quantity || 0) + curVal.quantity, 0) > 0 
                                                                            ?
                                                                            <span className="wishlist-in-stock">{this.lang.available_title}</span>
                                                                            :
                                                                            <span className="wishlist-out-of-stock">{this.lang.unavailable_title}</span>
                                                                    }
                                                                </td>
                                                                <td className='product-add-to-cart'>
                                                                    {this.renderActionContent(item, key)}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                                <tr>
                                                    <td colSpan='6'>
                                                    </td>
                                                </tr> 

                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                                :
                                <p className="wishlist-empty">{this.lang.wishlist_empty}</p>
                        }
                    </main>
                </div>

            </div>
        );
    }

    removeWishList = (key) => {
        this.props.removeWishList(key);
        this.forceUpdate();
    }

    addCart = (product, key) => {
        this.props.addCart(product, { update_count: 1 });
        this.props.removeWishList(key);
        this.forceUpdate();
    }

    renderPrice = (variarion) => {
        return this.props.renderPrice(variarion);
    }

    renderActionContent = (item, key) => {
        if (item.variations.length === 1) {
            return !!item.variations[0].quantity 
                && <button role="button" className="btn btn-primary" onClick={() => this.addCart(item.currentVariation, key)}>{this.lang.add_to_cart}</button>
        }
        return !!item.variations.reduce((prevVal, curVal) => (prevVal.quantity || 0) + curVal.quantity, 0)
            && <Link className="btn btn-primary" to={`${this.props.moduleUrl}/${item.slug}`} title=''>
                {this.lang.personalize}
            </Link>
    }
}

const mapStateToProps = (state) => {
    return {
        wishList: state.boost[`shop.widget.wishList`],
        removeWishList: state.boost[`shop.widget.wishList.remove`],
        renderPrice: state.boost[`shop.widget.wishList.renderPrice`],
        addCart: state.boost['shop.widget.cart.add']
    }
}

export default connect(mapStateToProps)(WishList);