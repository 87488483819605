import React, { Suspense } from 'react';
// const Home = React.lazy(() => import("./home"));
// const News = React.lazy(() => import("./news"));
// const DescriptionPages = React.lazy(() => import("./description-pages"));
// const Shop = React.lazy(() => import("./shop"));
// const User = React.lazy(() => import("./user"));
import Home from "./home";
import News from "./news";
import DescriptionPages from "./description-pages";
import Shop from "./shop";
import User from "./user";
import Container from 'container';
import Loader from 'partials/loader';

const LoadModule = (props) => {
    return (
        <Container
            type={props.type || 'home'}
            signpost = {signpost(props)}
            { ...props }
        >
            <Suspense fallback={
                <div className='preloader'>
                    <div className='preloader-wrapper'>
                        <Loader type='small'/>
                    </div>
                </div>
            }>
                {props.children}
            </Suspense>
        </Container>
    )
}

const signpost = (props) => {
    return (
        {
            module: props.module,
            params: props.params,
            route: props.route,
            route_map : props.route_map,
            slug : props.slug
      }
    )
}


export default {
    'news': {
        component: (props) =>
            <Container type='home' {...props}>
                <Suspense fallback={<div>Loading...</div>}>
                    <News {...props} />
                </Suspense>
            </Container>
    },
    'home': {
        component: (props) => <LoadModule {...props}>
            <Home {...props} signpost = {signpost(props)}/>
        </LoadModule>
    },
    'shop': {
        component: (props) => <LoadModule {...props}> <Shop {...props} signpost = {signpost(props)}/> </LoadModule>
    },

    'description-pages': {
        component: (props) => <LoadModule {...props}> <DescriptionPages {...props} signpost = {signpost(props)}/> </LoadModule>
    },
    
    'user': {
        component: (props) => <LoadModule {...props}> <User {...props} /> </LoadModule>
    }
}


