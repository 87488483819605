import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ListProducts from './list-products';
import Product from './product';
import ImagePreviev from './product/image-preview'
import Cart from './cart';
import Checkout from './checkout';
import OrderSummary from './order-summary';
import WishList from './wish-list';
import Lang from './lang';
import Page404 from '../../404';

class Shop extends React.Component {

    lang = Lang[global.language];

    component = {};

    uniqURL = {
        [this.lang.slug.cart]: 'cart',
        [this.lang.slug.checkout]: 'checkout',
        [this.lang.slug.order_summary]: 'order_summary',
        [this.lang.slug.wish_list]: 'wish_list',
    }
    components = {
        cart: Cart,
        checkout: Checkout,
        order_summary: OrderSummary,
        wish_list: WishList
    }

    checkUniqURL(slug) {
        if (this.uniqURL[slug]) {
            this.component = this.components[this.uniqURL[slug]]
            return slug
        }
        return 'not-uniq';
    }

    render() {
        return (
            <Switch>
                <Route exact path={`${this.props.route}`} component={(props) => <ListProducts {...props} moduleUrl={'/sklep'} />} />
                <Route exact path={`${this.props.route}/${this.lang.slug.category}/:category_name`} component={(props) => <ListProducts {...props} moduleUrl={this.props.route} />} />
                <Route exact path={`${this.props.route}/${this.checkUniqURL(this.props.params[0])}`} component={(props) => <this.component {...props} moduleUrl={this.props.route} />} />
                <Route exact path={`${this.props.route}/${this.lang.slug.slug_image_preview}/:image_path`} component={(props) => <ImagePreviev {...props} moduleUrl={this.props.route} />} />
                <Route exact path={`${this.props.route}/:slug/:variation_id?`} component={(props) => <Product {...props} moduleUrl={this.props.route} />} />
            </Switch>
        )
    }
}

export default Shop;