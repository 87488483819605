import React from 'react';
import Lang from '../lang';
import countries from './countries.json';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from 'partials/loader';
import API from 'boost/API';
import { updateValue } from 'boost/dispatch';

class EditAddress extends React.PureComponent {

    lang = Lang[global.language];

    _isMounted = false;

    form_fields = {
        country: '',
        street: '',
        post_code: '',
        city: '',
    }

    state = {
         ...this.form_fields,
        address_type: this.props.match.params.address_type,
        errors: {},
        loader: false
    }

    render() {
        return (
            !this.state.loader ? 
            <form onSubmit={this.submit}>
                <h3 className='upper'>{this.lang[`${this.state.address_type}_address_title`]}</h3>
                <div className='woocommerce-address-fields'>
                    <div className='woocommerce-address-fields__field-wrapper'>

                        <input type='hidden' name='id' value={this.state.id} />

                        <div className='form-group'>
                            <label htmlFor='country'>{this.lang.country_title} <sup>*</sup></label>
                            <select className='form-control' name='country' value={this.state.country} id='country' onChange={this.updateState} required>
                                <option value={''}>{this.lang.select}</option>
                                {
                                    countries.map((country, key) => <option value={country[this.lang.country_name_lang]} key={key}>{country[this.lang.country_name_lang]}</option>)
                                }
                            </select>
                            {this.showError('country')}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='street'>{this.lang.street_title} <sup>*</sup></label>
                            <input type='text' className='form-control' id='street' name='street' value={this.state.street} onChange={this.updateState} placeholder={this.lang.add_street_title} required/>
                            {this.showError('street')}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='post_code'>{this.lang.post_code_title} <sup>*</sup></label>
                            <input type='text' className='form-control' id='post_code' name='post_code' value={this.state.post_code} onChange={this.updateState} placeholder={this.lang.add_post_code_title} required/>
                            {this.showError('post_code')}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='city'>{this.lang.city_title} <sup>*</sup></label>
                            <input type='text' className='form-control' id='city' name='city' value={this.state.city} onChange={this.updateState} placeholder={this.lang.add_city_title} required/>
                            {this.showError('city')}
                        </div>

                        <div className='form-submit'>
                            <button type='submit' className='btn btn-primary margin-right-5'>{this.lang.save_title}</button>
                            <button type='button' className='btn btn-primary' onClick={this.goBack}>{this.lang.back_title}</button>
                        </div>

                    </div>
                </div>
            </form>
            :
            <div className='preloader'>
                <div className='preloader-wrapper'>
                    <Loader type='small'/>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this._isMounted = true;
        if (typeof this.props.user !== 'undefined') {
            let user = this.props.user.data;
            let addresses = user.addresses;
            let address = this.getLoginUserAddress(this.state.address_type, addresses);
            let data = this.filter(address, this.form_fields);
            this.setState(data);
        }
    }

    /**
     * Przechwytuje zdarzenie wysłania danych formularza aktualizacji adresów
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    submit = (e) => {
        e.preventDefault();
        this.showLoader();
        this.clearErrors();

        let data = {};
        let user = { ...this.props.user.data };
        let address = { ...this.state };

        // Sprawdzenie czy użytkownik dodaje adres
        if (address.id === 0) {
            delete address.id;
        } 

        delete address.errors;
        delete address.loader;

        data.id = user.contractor_id;
        data.email = user.email;
        data.name = user.name;
        data.first_name = user.first_name;
        data.last_name = user.last_name;
        data.first = user.firm;
        data.nip = user.nip;
        data.phone_number = user.phone_number;
        data.terms_of_use = user.terms_of_use;
        data.personal_information = user.personal_information;
        data.newsletter = user.newsletter;
        data.addresses = { 0: address };

        // Sprawdzenie czy w reduxowym store są dane wybranego adresu
        let have_address = user.addresses.find(address => address.address_type == this.state.address_type);

        let new_address = [];

        if (typeof have_address !== 'undefined') {
            // Aktualizujemy istniejący adres z reduxa
            new_address = user.addresses.map(address => {
                if (address.address_type == this.state.address_type) {
                    return { ...address, ...data.addresses[0] };
                }
                return address;
            });
        } else {
            // Dodajemy pozycję do tablicy
            new_address = [ ...user.addresses, address ];
        }

        user.addresses = new_address;

        API.put(`/api/shop/customer/account`, data ).then(res => {
            if (!res.error) {
                this.props.updateValue({ state: 'user', data: { data: { ...user } } } );
                toastr.success(res.data.message);
                this.props.history.goBack();
            } else {
                if (res.data.errors) {
                    this.setState({
                        errors: res.data.errors
                    })
                } else {
                    toastr.error(res.data.message);
                }
            }
            this.showLoader();
        });
    }

    /**
     * Wyświetla lub ukrywa loader
     * 
     * @return {undefined}
     */
    showLoader = () => {
        if (this._isMounted) {
            this.setState({ loader: !this.state.loader });
        }
    }

    /**
     * Usuwa z obiektu błedów informacje o błedach walidacji pól formularza
     * 
     * @return {undefined} 
     */
    clearErrors = () => {
        this.setState({
            errors: {}
        }); 
    }

    /**
     * Filtruje dane
     * 
     * @param {Object} data - Obiekt filtrowany
     * @param {Object} filter - Obiekt filtrujący
     * @return {Object}
     */
    filter = (data, filter) => {
        let filtered = {};

        for (let property in filter) {
            filtered[property] = data[property] || ''
        }
        
        return filtered;
    } 

    /**
     * Tworzy obiekt danych adresowych zalogowanego użytkownika
     * 
     * @param {String} address_type - Typ adresu
     * @param {Array} addresses - Tablica adresów
     * @return {Object|Boolean}
     */
    getLoginUserAddress(address_type, addresses) {
        let address = addresses.find(address => address.address_type == address_type);
        return typeof address !== 'undefined' ? address : false;
    }

    /**
     * Aktualizuje wartość state
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    /**
     * Wyświetla błędy walidacji
     * 
     * @param {String} name - Nazwa input
     * @return {undefined}
     */
    showError = (name) => {
        let { errors } = this.state;
        // Konwertuje nazwy pól błędów aby mogły być rozpoznane
        errors = this.nestedFormFieldErrorsName(errors);
        if (errors[name]) {
            return (
                <React.Fragment>
                    {
                        errors[name].map((error, key) =>
                            <div className={`text-danger`}  key={key}>{error}</div>
                        )
                    }
                </React.Fragment>
            )
        }
    }

    /**
     * Konwertuje obiekt błędów zwrócony z API
     * 
     * @param {Object} errors - Obiekt błędów
     * @return {undefined}
     */
    nestedFormFieldErrorsName(errors) {
        let nested_errors = {};

        Object.keys(errors).map(key => {
            let arr = key.split('.');
            nested_errors[arr[arr.length - 1]] = errors[key];
        });

        return nested_errors;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Umożliwia powrót na poprzednią stronę
     * 
     * @return {undefined}
     */
    goBack = () => {
        this.props.history.goBack();
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.boost.user
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateValue
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAddress);