import React from 'react';
import { Link } from 'react-router-dom';
import { Home } from 'grips';
import Lang from './lang';

class ContainerHome extends React.PureComponent {

    renderGrips(area) {
        return (
            Home[area].map((Controller, index) => <Controller {...this.props.signpost} key={index} />)
        )
    }

    lang = Lang[global.language];

    $searchForm = {};
    $awemenuNav = {};

    state = {
        search: '',
        url: this.props.match.url
    }

    render() {

        let { url } = this.state;

        return (
            <div id='wrapper' className='main-wrapper '>
                <header id='header' className='awe-menubar-header'>
                    <nav className='awemenu-nav awemenu-mobile awemenu-mobile-default awemenu-mobile-outleft' data-responsive-width='1200'>
                        <div className='container'>
                            <div className='awemenu-container'>

                                <div className='navbar-header'>

                                    <ul className='navbar-icons'>
                                        {this.renderGrips(0)}
                                    </ul>

                                    {/* <ul className='navbar-lang'>
                                        {this.renderGrips(4)}
                                    </ul> */}

                                    <ul className='navbar-lang'>
                                        <li>
                                            <a style={{ display: 'table' }}>
                                                <div id="google_translate_element"></div>
                                            </a>
                                        </li>
                                    </ul>

                                    <ul className='navbar-search'>
                                        <li>
                                            <a 
                                                title='' 
                                                className='awemenu-icon awe-menubar-search' 
                                                id='open-search-form' 
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.handleClickLoupe();
                                                    return false;
                                                }}
                                            >
                                                <span className='sr-only'>{this.lang.search_title}</span>
                                                <span className='icon icon-search'></span>
                                            </a>

                                            <div className='menubar-search-form' id='menubar-search-form'>
                                                <form onSubmit={this.submit}>
                                                    <input type='search' name='search' className='form-control' value={this.state.search} onChange={this.updateState} placeholder={this.lang.enter_searching_product_title} required />
                                                    <div className='menubar-search-buttons'>
                                                        <button type='submit' className='btn btn-sm btn-white'>{this.lang.search_title}</button>
                                                        <button type='button' className='btn btn-sm btn-white' id='close-search-form' onClick={this.handleClickCloseSearch}>
                                                            <span className='icon icon-remove'></span>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className='awe-logo'>
                                    <Link to='/home' title=''>
                                        <img src='/themes/default/img/logo.png' alt='' />
                                    </Link>
                                </div>

                                <div className="awemenu-bars"  onClick={this.showHideMenu}>
                                    <span className="fa fa-bars"></span>
                                </div>
                                
                                {this.renderGrips(1)}
                            </div>
                        </div>

                    </nav>
                </header>
                <div id='main'>
                    {this.renderGrips(2)}
                    {this.renderGrips(3)}
                    {this.props.children}
                </div>
                <footer className='footer'>
                    <div className='footer-wrapper'>
                        <div className='footer-widgets'>


                            <div className='container'>
                                <div className='row'>

                                    <div className='col-md-3 col-sm-6'>

                                        <div className='widget'>
                                            <h3 className='widget-title'>{this.lang.info}</h3>

                                            <ul>
                                                <li><Link to={{ pathname: `/${this.lang.slug.page}/${this.lang.slug.slug_about_us}`, prevUrl: url }}>{this.lang.about_us_title}</Link></li>
                                                <li><Link to={{ pathname: `/${this.lang.slug.page}/${this.lang.slug.slug_policies}`, prevUrl: url }}>{this.lang.privacy_policy}</Link></li>
                                                <li><Link to={{ pathname: `/${this.lang.slug.page}/${this.lang.slug.slug_contact}`, prevUrl: url }}>{this.lang.contact_title}</Link></li>
                                            </ul>
                                        </div>

                                    </div>

                                    <div className='col-md-3 col-sm-6'>

                                        <div className='widget'>
                                            <h3 className='widget-title'>{this.lang.shopping}</h3>

                                            <ul>
                                                <li><Link to={{ pathname: `/${this.lang.slug.page}/${this.lang.slug.slug_how_to_buy}`, prevUrl: url }}>{this.lang.how_to_buy_title}</Link></li>
                                                <li><Link to={{ pathname: `/${this.lang.slug.page}/${this.lang.slug.shipping_costs}`, prevUrl: url }}>{this.lang.shipping_costs}</Link></li>
                                                <li><Link to={{ pathname: `/${this.lang.slug.page}/${this.lang.slug.payment_methods}`, prevUrl: url }}>{this.lang.payment_methods}</Link></li>
                                            </ul>
                                        </div>

                                    </div>

                                    <div className='col-md-3'>
                                        <div className='widget'>
                                            <h3 className='widget-title'>{this.lang.package_received}</h3>

                                            <ul>
                                                <li><Link to={{ pathname: `/${this.lang.slug.page}/${this.lang.slug.damaged_goods}`, prevUrl: url }}>{this.lang.damaged_goods}</Link></li>
                                                <li><Link to={{ pathname: `/${this.lang.slug.page}/${this.lang.slug.i_want_to_exchange_the_goods}`, prevUrl: url }}>{this.lang.i_want_to_exchange_the_goods}</Link></li>
                                                <li><Link to={{ pathname: `/${this.lang.slug.page}/${this.lang.slug.refund_or_complaint}`, prevUrl: url }}>{this.lang.refund_or_complaint}</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='row'>
                                            <div className='col-md-12 col-sm-6'>
                                                <div className='widget'>
                                                    <h3 className='widget-title'>{this.lang.quick_payments}</h3>

                                                    <ul className='list-socials'>
                                                        <li>
                                                            <a href='#' title='' >
                                                                <i className='fa'>
                                                                    <img 
                                                                        src="/themes/default/img/payments/PAYU_LOGO_BLACK.png" 
                                                                        style={{ width: "29px", backgroundColor: "grey", marginTop: "-3px" }} 
                                                                    />
                                                                </i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href='#' title=''>
                                                                <i className='fa fa-cc-paypal'></i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href='#' title=''>
                                                                <i className='fa fa-cc-visa'></i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href='#' title=''>
                                                                <i className='fa fa-cc-mastercard'></i>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href='#' title=''>
                                                                <i className='fa'>
                                                                    <span style={{ 
                                                                        backgroundColor: 'grey',
                                                                        color: 'rgb(37, 37, 37)',
                                                                        fontSize: '16px',
                                                                        padding: '0px 2px',
                                                                        fontWeight: 'bold'
                                                                    }}>
                                                                        blik
                                                                    </span>
                                                                </i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>

                                            <div className='col-md-12 col-sm-6'>

                                                <div className='widget'>
                                                    <h3 className='widget-title'>{this.lang.like_us}</h3>

                                                    <ul className='list-socials'>
                                                        <li><a href='#' title=''><i className='icon icon-twitter'></i></a></li>
                                                        <li><a href='#' title=''><i className='icon icon-facebook'></i></a></li>
                                                        <li><a href='#' title=''><i className='icon icon-google-plus'></i></a></li>
                                                        <li><a href='#' title=''><i className='icon icon-pinterest'></i></a></li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className='footer-copyright'>
                            <div className='container'>
                                <div className='copyright'>
                                    <p>Copyright &copy; 2017 DWSport - All Rights Reserved.</p>
                                </div>

                                <div className='footer-nav'>
                                    {this.lang.cookie_info}
                                </div>
                            </div>
                        </div>
                    </div>

                    <a onClick={e => {
                            e.preventDefault();
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                            return false;
                        }} 
                        className='back-top' title=''>
                        <span className='back-top-image'>
                            <img src='/themes/default/img/back-top.png' alt='' />
                        </span>
                        <small>{this.lang.back_top}</small>
                    </a>
                </footer>
            </div>
        )
    }

    componentDidMount() {
        this.$searchForm = $('#menubar-search-form');
        this.$awemenuNav = $('.awemenu-nav');
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        let $awemenuNav = this.$awemenuNav;
        if (window.innerWidth < 980) {
            $awemenuNav.removeClass('awemenu-default awemenu-standard headroom headroom--top awemenu-animation')
                .addClass('awemenu-mobile awemenu-mobile-default awemenu-mobile-outleft');
        }

        if (window.innerWidth >= 980) {
            $awemenuNav.removeClass('awemenu-mobile awemenu-mobile-default awemenu-mobile-outleft')
                .addClass('awemenu-default awemenu-standard headroom headroom--top awemenu-animation');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    showHideMenu = () => {
        let $awemenuNav = this.$awemenuNav;
        if ($awemenuNav.hasClass('awemenu-active')) {
            $awemenuNav.removeClass('awemenu-active');
        } else {
            $awemenuNav.addClass('awemenu-active');
        }
    }

    /**
     * Przechwytuje zdarzenie kliknięcia wysuwania formularza wyszukiwania
     * 
     * @return {undefined}
     */
    handleClickLoupe = () => {
        this.$searchForm.toggleClass('open');

        if (this.$searchForm.hasClass('open')) {
          setTimeout(() => {
            this.$searchForm.find('input[type="search"]').focus();
          }, 100);
        }
    }

    /**
     * Przechwytuje zdarzenie kliknięcia ukrywania formularza wyszukiwania
     * 
     * @return {undefined}
     */
    handleClickCloseSearch = () => {
        this.$searchForm.removeClass('open');
    }

    /**
     * Aktualizuje wartośc state
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    /**
     * Przechwytuje zdarzenie kliknięcia przycisku wysłania formularza
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    submit = (e) => {
        e.preventDefault();
        window.location.href = `/${this.lang.slug.shop}?search=${this.state.search}`;
    }

}

export default ContainerHome;