import React from 'react';
import PropTypes from 'prop-types';

const Image = (props) =>
    <img
        src={`${props.image.path}${props.size}${props.image.name.substring(props.image.name.indexOf('.'),props.image.name.length)}`}
        alt={props.image.alt}
        className={props.class}
        style={props.style}
    />

Image.defaultProps = {
    image: {},
    class: '',
    style: {},
    size: '120x150'
}

Image.propTypes = {
    image: PropTypes.object,
    class: PropTypes.string,
    style: PropTypes.object,
    size: PropTypes.string,
}

export default Image;