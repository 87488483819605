import React from 'react';
import Lang from '../lang';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class EditAddresses extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        firm: '',
        first_name: '',
        last_name: '',
        addresses: [
            { address_type: 'AF' },
            { address_type: 'AW' },
            { address_type: 'AK' }
        ]
    }

    render() {

        let { addresses } = this.state;

        return (
            <section>
                <p>{this.lang.addresses_will_used_desc_title}</p>
                <div className='u-columns woocommerce-Addresses col2-set addresses'>
                    {
                        addresses.map((address, key) => {
                                return <div className={`u-column1 col-${key % 2 === 0 ? '1': '2'} woocommerce-Address`} key={key}>
                                    <header className='woocommerce-Address-title title container-full'>
                                        <h3 className='upper pull-left'>{this.lang[`${address.address_type}_address_title`]}</h3>
                                        <Link 
                                            to={`${this.props.route}/${this.lang.slug_edit_address}/${address.address_type}`} 
                                            className="edit pull-right">{this.lang.edit_title}
                                        </Link>
                                    </header>
                                    <address className='pull-left container-full'>
                                        {
                                            Object.keys(address).length > 1 ?
                                                this.addresData(address)
                                                : this.lang.not_set_up_address_yet
                                                
                                        }
                                    </address>
                                </div>
                            }
                        )
                    }    
                </div>
            </section>
        );
    }

    componentDidMount() {
        if (typeof this.props.user !== 'undefined') {
            let user = this.props.user.data;

            this.setState({
                firm: user.firm,
                first_name: user.first_name,
                last_name: user.last_name
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (typeof nextProps.user !== 'undefined') {
            if (JSON.stringify(nextProps.user) !== JSON.stringify(prevState.user)) {
                let next_props_address = nextProps.user.data.addresses;

                let addresses = prevState.addresses.map(state_address => {
                    next_props_address.map(next_props_address => {
                        if (state_address.address_type == next_props_address.address_type) {
                            state_address = { ...state_address, ...next_props_address };
                        }
                    })
                    return state_address;
                });

                return { addresses };
            }
        }
        return null;
    }

    /**
     * Wyświetla dane adresowe
     * 
     * @param {Object} address - Obiekt danych adresowych
     * @return {Object}
     */
    addresData = (address) => {
        return (
            <React.Fragment>
                {this.state.firm || ''}
                <br />
                {`${this.state.first_name || ''} ${this.state.last_name || ''}`}
                <br />
                {address.street || ''}
                <br/>
                {`${address.city || ''} ${address.post_code || ''}`}
                <br/>
                {address.country || ''}
            </React.Fragment>
        )
    }

    /**
     * Tworzy obiekt danych adresowych zalogowanego użytkownika
     * 
     * @param {String} address_type - Typ adresu
     * @param {Array} addresses - Tablica adresów
     * @return {Object}
     */
    getLoginUserAddress(address_type, addresses) {
        let address = addresses.find(address => address.address_type == address_type);
        return typeof address !== 'undefined' ? address : {};
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.boost.user
    }
}

export default connect(
    mapStateToProps
)(EditAddresses);
