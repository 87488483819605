import React from 'react';
import Lang from './lang';
import API from 'boost/API';
import Loader from 'partials/loader';

class ContactUs extends React.Component {

    lang = Lang[global.language];

    state = {
        email: '',
        name: '',
        website: '',
        subject: '',
        content: '',
        errors: {},
        loader: false
    }

    baseState = this.state;

    render() {
        return (
            !this.state.loader 
            ?
            <div className='col-md-5 contact-content contact-content-right wpb_column vc_column_container vc_col-sm-6'>
                <div className='vc_column-inner'>
                    <div className='wpb_wrapper'>
                        <div className='wpb_text_column wpb_content_element'>
                            <div className='wpb_wrapper'>
                                <div className='contact-form-heading'>
                                    <h2>{this.lang.contact_us}</h2>
                                    <p>{this.lang.contact_us_description}</p>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={this.submit}>
                            <div className='form-group'>
                                <label>{this.lang.email}</label>
                                <input type="email" name="email" value={this.state.email} size="40" className="form-control" onChange={this.updateState} aria-required="true" aria-invalid="false" required/>
                                {this.showError('email')}
                            </div>
                            <div className='form-group'>
                                <label>{this.lang.name}</label>
                                <input type="text" name="name" value={this.state.name} size="40" className="form-control" onChange={this.updateState} aria-required="true" aria-invalid="false" required/>
                                {this.showError('name')}
                            </div>
                            <div className='form-group'>
                                <label>{this.lang.website}</label>
                                <input type="url" name="website" value={this.state.website} size="40" className="form-control" onChange={this.updateState} aria-invalid="false" placeholder="http://" />
                                {this.showError('website')}
                            </div>
                            <div className='form-group'>
                                <label>{this.lang.subject}</label>
                                <input type="text" name="subject" value={this.state.subject} size="40" className="form-control" onChange={this.updateState} aria-required="true" aria-invalid="false" required/>
                                {this.showError('subject')}
                            </div>
                            <div className='form-group'>
                                <label>{this.lang.your_message}</label>
                                <span className='content'>
                                    <textarea name="content" cols="40" rows="10" value={this.state.content} className="form-control dark" onChange={this.updateState} aria-invalid="false" required/>
                                </span>
                                {this.showError('content')}
                            </div>
                            <div className='form-button'>
                                <button type='submit' className='btn btn-lg btn-dark'>{this.lang.send_message}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            :      
            <div className='preloader'>
                <div className='preloader-wrapper'>
                    <Loader type='small'/>
                </div>
            </div>
        );
    }

    resetFormInput = () => {
        this.setBaseState();
    }

    setBaseState = () => {
        this.setState(this.baseState);
    }

    submit = (e) => {
        e.preventDefault();
        this.hideShowLoader();
        this.clearErrors();
        let { email, name, website, subject, content } = this.state;
        let data = { email, name, website, subject, content };
        API.post(`/api/users/email`, data).then(res => {
            if (!res.error) {
                toastr.success(res.data.message);
                this.resetFormInput();
            } else {
                if (res.data.errors) {
                    this.setState({ errors: res.data.errors });
                } else {
                    toastr.error(res.data.message);
                }
                this.hideShowLoader();
            }
        });
    }

    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    /**
     * Wyświetla błędy formularza zwrócone przez API
     * 
     * @param { string } name - Nazwa pola formularza
     * @return { undefined }
     */
    showError = (name) => {
        let { errors } = this.state;
        if (errors[name]) {
            return (
                <React.Fragment>
                    {
                        errors[name].map((error, key) =>
                            <div className={`text-danger`}  key={key}>{error}</div>
                        )
                    }
                </React.Fragment>
            )
        }
    }

    /**
     * Ukrywa lub wyświetla loader w zależności od wartości w state
     * 
     * @return { undefined }
     */
    hideShowLoader() {
        this.setState({
            loader: !this.state.loader
        });
    }

    
    /**
     * Usuwa błędy walidacji danych formularza zapisane w state
     * 
     * @return { undefined }
     */
    clearErrors() {
        this.setState({
            errors: {}
        });
    }

}

export default ContactUs;