import React from "react";
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateValue } from 'boost/dispatch';
import Helper from 'helpers';
import Lang from '../lang';

class WhishList extends React.PureComponent {

    state = {
        module_url: ''
    }

    lang = Lang[global.language];

    render() {

        let { wishList } = this.props;

        return (
            <li className='menubar-wishlist'>
                <Link to={`${this.state.module_url}/${this.lang.slug.wish_list}`} title='' className='awemenu-icon menu-wishlist'>
                    <i className='icon icon-star'></i>
                    <span className='awe-hidden-text'>WishList</span>
                    {
                        wishList && wishList.length > 0 && <span className='wishlist-number'>{this.wishListCount()}</span>
                    }
                </Link>

                <ul className='submenu megamenu'>
                    <li>
                        <div className='container-fluid'>
                            {
                                wishList && wishList.length > 0  
                                    ?
                                    <React.Fragment>
                                        <ul className='whishlist wishlist-list wishlist-list-scroll'>
                                            {
                                                wishList.map((item, key) => {
                                                    return (
                                                        <li key={key}>

                                                            <div className='whishlist-item'>
                                                                <div className='product-image'>
                                                                    <Link to={`${this.state.module_url}/${item.slug}`} title={item.name}>
                                                                        {
                                                                            item.image 
                                                                                ?
                                                                                <div className='border'><img src={item.image.thumb} alt={item.name} /></div>
                                                                                :
                                                                                <div className='border'><img src='/themes/default/img/samples/products/cart/1.jpg' alt={item.name} /></div>
                                                                        }
                                                                    </Link>
                                                                </div>

                                                                <div className='product-body'>
                                                                    <div className='whishlist-name'>
                                                                        <h3>
                                                                            <Link to={`${this.state.module_url}/${item.slug}`} title={item.name}>{item.name}</Link>
                                                                        </h3>
                                                                    </div>
                                                                    <div className='whishlist-price'>
                                                                        <span>{this.lang.price}: </span>
                                                                        <strong>{this.renderPrice(item.currentVariation)}</strong>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <a 
                                                                title='' 
                                                                className='remove cursor-pointer' 
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.removeFromWishList(key);
                                                                    return false;
                                                                }}
                                                            >
                                                                <i className='icon icon-remove'></i>
                                                            </a>

                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>

                                        <div className='cart-action'>
                                            <Link to={`/${this.lang.slug.shop}/${this.lang.slug.wish_list}`} title='' className='btn btn-lg btn-dark btn-outline btn-block'>{this.lang.view_wishlist}</Link>
                                        </div>

                                    </React.Fragment>
                                    : 
                                    <p>{this.lang.wish_list_empty}</p>
                            }
                        </div>
                    </li>
                </ul>

            </li>
        );
    }

    componentDidMount() {
        if(this.props.route_map) {
            let to_url = this.props.route_map.filter(element => element.module == 'shop' && element.slug != '');
            if(to_url.length) {
                this.setState({module_url: `/${to_url[0].slug}`});
            }
        }
        this.loadWishListFromLocalStorage();
        this.addManagementMethodsToStore();
    }

    loadWishListFromLocalStorage = () => {
        let wishList = localStorage.getItem('shop.wishList');
        this.props.updateValue({ state: 'shop.widget.wishList', data: JSON.parse(wishList) });
    }

    addManagementMethodsToStore = () => {
        this.props.updateValue({ state: 'shop.widget.wishList.add', data: this.addToWishList });
        this.props.updateValue({ state: 'shop.widget.wishList.remove', data: this.removeFromWishList });
        this.props.updateValue({ state: 'shop.widget.wishList.renderPrice', data: this.renderPrice });
    }

    addToWishList = (product) => {
        let wishList = this.props.wishList;
        if (!wishList) {
            wishList = [];
        }
        wishList = [ ...wishList, product ];
        this.updateWishList(wishList);
        toastr.success(this.lang.product_added_wish_list);
    }

    /**
     * Renderuje widok ceny produktu
     * 
     * @param {Object} variation - Wariant
     * @return {Object}
     */
    renderPrice(variation) {
        return (
            variation.selling_price !== variation.default_price
                ?
                <React.Fragment>
                    <del>
                        <span className='amount'>
                            {Helper.currencyFormatter().format(variation.default_price)}
                        </span>
                    </del>
                    {` `}
                    <ins>
                        <span className='amount'>
                            {`${Helper.currencyFormatter().format(variation.selling_price)}`}
                        </span>
                    </ins>
                </React.Fragment>
                :
                <span className='amount'>
                    {`${Helper.currencyFormatter().format(variation.selling_price)}`}
                </span>
        );
    }

    /**
     * Aktualizuje listę życzeń
     * 
     * @param {Array} wishList - Lista życzeń
     * @return {undefined}
     */
    updateWishList(wishList) {
        this.props.updateValue({
            state: 'shop.widget.wishList',
            data: wishList
        });
        
        localStorage.setItem('shop.wishList', JSON.stringify(wishList));

        this.forceUpdate();
    }

    /**
     * Zlicza sumaryczną ilość pozycji w wishlist
     * 
     * @return {Integer}
     */
    wishListCount = () => {
        let { wishList } = this.props;
        return wishList.length;
    }

    removeFromWishList = (index) => {
        let { wishList } = this.props;
        wishList = [ ...wishList ];
        wishList.splice(index, 1);
        this.updateWishList(wishList);
    }

}

WhishList.defaultProps = {
    wishList: []
}

const mapStateToProps = (state) => {
    return {
        wishList: state.boost[`shop.widget.wishList`]
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateValue
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WhishList);