import React from 'react';
import { Route } from 'react-router-dom';
import ListNews from './list';
import Single from './single';

class News extends React.Component {
    render() {
        console.log('News', this.props)
        return (
            <React.Fragment>
                <Route exact path={`${this.props.route}`} component={ListNews}/>
                <Route exact path={`${this.props.route}/:id_articles`} component={Single}/>
            </React.Fragment>
        )
    }
}

export default News;