import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import store, { history } from './boost/store'
import App from './boost'
import { api_url, language } from './config';
import axios from 'axios';
import '../assets/scss/app.scss';
import 'node_modules/toastr/build/toastr.min.css';
window.toastr = require('toastr');
import 'owl.carousel/dist/assets/owl.carousel.css';
window.owlCarousel  = require('owl');

axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    //"X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    "baseURL": api_url,
    "language": language
};

const target = document.querySelector('#root');
/**
 * Głowny komponent inicjalizujacy aplikacje.
 * W nim znajduje się definicja redux oraz router
 */
export const AppInit = () => (
    // Komponent stworzony tylko i wyłącznie w dla dokumentacji
    <span></span>
);
render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    target
)
