import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Views from 'views';
import Loader from 'partials/loader';
import { withRouter } from 'react-router-dom';
import API from 'boost/API';
import axios from 'axios';
import Page404 from '../404';
import Lang from './lang';

class App extends React.Component {

    lang = Lang[global.language];

    state = {
        loaded: false,
        route_map: [],
        route_flag: false
    }

    componentDidMount() {
            API.post('/api/refresh', {client: 'shop'}).then(res => {
                if(res.status == 200) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
                    global.user_token = res.data.accessToken;
                }
              
                    this.setState({loaded: true, route_map: [
                        {
                            slug: 'moje-strony',
                            module: 'page-list'
                        },
                        {
                            slug: 'nowosci',
                            module: 'news'
                        },
                        {
                            slug: 'home',
                            module: 'home'
                        },
                        {
                            slug: '',
                            module: 'shop'
                        },
                        {
                            slug: this.lang.slug.shop,
                            module: 'shop'
                        },
                        {
                            slug: this.lang.slug.slug_user_account,
                            module: 'user'
                        },
                        {
                            slug: this.lang.slug.page,
                            module: 'description-pages'
                        }
                    ]});
            })
    }

    shouldComponentUpdate(nexProps, nextState) {
        if(this.state.loaded !== nextState.loaded) {
            return true;
        } else {
            if(nexProps.location.key !== this.props.location.key ) {
                return true;
            } else {
                return false;
            }
        }
    }

    componentDidUpdate() {
        this.addSrcSctiptAfterRoot();
    }

    addSrcSctiptAfterRoot() {
        let google_translate_element_init = document.querySelector('#googleTranslateElementInit');
        if (google_translate_element_init !== null) {
             google_translate_element_init.parentNode.removeChild(google_translate_element_init);
        }

        setTimeout(() => {
            let root = document.querySelector('#root');
            let add_script = document.createElement('script');
            add_script.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
            add_script.id = 'googleTranslateElementInit';      
            root.parentNode.insertBefore(add_script, root.nextSibling);


            let goog_gt_tt = document.querySelector('#goog-gt-tt');
            if (goog_gt_tt) {
                goog_gt_tt.parentNode.removeChild(goog_gt_tt);
            }

            let goog_te_spinner_pos = document.querySelector('.goog-te-spinner-pos');
            if (goog_te_spinner_pos) {
                goog_te_spinner_pos.parentNode.removeChild(goog_te_spinner_pos);
            }
        }, 500);
    }

    render() {
        return(
            this.state.loaded ?
            <Switch>
                <Route exact path='/' component={(props) => <DispachURL { ...props } route_map = { this.state.route_map } />} />
                <Route exact path={`/home`} component={(props) => <DispachURL { ...props } route_map = { this.state.route_map } />} />
                <Route exact path={`/${this.lang.slug.shop}/:all*`} component={(props) => <DispachURL { ...props } route_map = { this.state.route_map } />} />
                <Route exact path={`/${this.lang.slug.page}/:all*`} component={(props) => <DispachURL { ...props } route_map = { this.state.route_map } />} />
                <Route exact path={`/${this.lang.slug.slug_user_account}/:all*`} component={(props) => <DispachURL { ...props } route_map = { this.state.route_map } />} />
                <Route component={Page404} />
            </Switch>
            : <div className='preloader'>
                <div className='preloader-wrapper'>
                    <Loader type='small'/>
                </div>
            </div>
        )
    }
}

class DispachURL extends React.Component {
    
    findModuleFromslug(url = '') {
        let rout_tab = url.split('/')
        let cut_slugs = url.split('/').reverse();
        let find_module = '';
        let find_slug = '';
        let params = [];
        for(let i = 0, max = cut_slugs.length; i<max; i++) {
            let module_exit = this.findInRoute(cut_slugs[i]);
            if(module_exit) {
                find_module = module_exit;
                find_slug = cut_slugs[i];
                break;
            }
            params.push(rout_tab.pop());
        }
        let route = '';
        rout_tab.map(element => {
            if(element) 
            route +=`/${element}`;
        })
        return { module: find_module, route, slug: find_slug, params: params.reverse() } ;
    }

    findInRoute(slug) {
        let find_module = false;
        for(let i = 0, max = this.props.route_map.length; i<max; i++) {
            if(this.props.route_map[i].slug == slug) {
                find_module = this.props.route_map[i].module;
                break;
            }
        }
        return find_module;
    }

    render() {
        let load_slug = this.findModuleFromslug(this.props.match.url);
        const MODULE = Views[load_slug.module].component;
        window.scrollTo(0, 0);
        return(
            <MODULE {...this.props} {...load_slug}/>
        )
    }
}

export default withRouter(App)