import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Lang from '../lang';
import API from 'boost/API';
import Address from './address';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    setAddress,
    setErrors,
    updateValue,
    SET_CONTACT_ADDRESS, 
    SET_SHIPPING_ADDRESS, 
    SET_INVOICE_DATA 
} from 'boost/dispatch';
import { root_url } from 'app/config';
import Helper from 'helpers';
import Loader from 'partials/loader'

export const STANDARD = 'standard';
export const COD = 'cod';
export const TRADITIONAL = 'traditional';

class Checkout extends Component {

    lang = Lang[global.language];

    state = {
        module_url: '',
        errors: [],
        shipping_form_show: false,
        invoice_form_show: false,
        payment_method: STANDARD,
        payment_gateways: [],
        payment_gateway_name: '',
        courier_id: 0,
        couriers: [],
        delivery_methods_cod: [],
        delivery_methods_standard: [],
        delivery_cost: 0,
        delivery_method_id: 0,
        loader: false,
        remark: '',
        terms_of_use: false,
        withdrawal_from_contract: false,
        target_point_search: '',
        inpost_locker: '',
        show_inpost_locker: false
    }

    render() {

        let { loader, shipping_form_show, invoice_form_show, 
            delivery_cost, payment_method, delivery_methods_standard, 
            payment_gateways, delivery_methods_cod, payment_gateway_name,
            target_point_search, show_inpost_locker } = this.state;

        let { contact_address, shipment_address, invoice_address } = this.props;

        return (
            <Fragment>
                {
                    !loader ? 
                    <div id='main'>

                        <div className='main-header background' style={{ background: 'linear-gradient(rgba(28, 28, 28, 0.3), rgba(28, 28, 28, 0.3)), url("/themes/default/img/backgrounds/background-about.jpg")' }}>
                            <div className="container">
                                <h1 className="main-header-title text-center block">
                                    <span>{this.lang.order}</span>
                                </h1>
                            </div>
                        </div>

                        <div id='breadcrumb'>
                            <div className='container'>
                                <ol className='breadcrumb'>
                                    <li><Link to='/'>{this.lang.home}</Link></li>
                                    <li className='active'><span>{this.lang.order}</span></li>
                                </ol>

                            </div>
                        </div>

                        <div className='checkout-page'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <article>
                                            <form onSubmit={this.submit} className='checkout-form'>
                                                <div className='col2-set'>
                                                    <div className='col-sm-6'>
                                                        <Address 
                                                            address={contact_address}
                                                            header={this.lang.contact_address} 
                                                            action_type={SET_CONTACT_ADDRESS}
                                                        />
                                                    </div>
                                                    {
                                                        shipping_form_show
                                                        ? 
                                                        <Fragment>
                                                            <div className='col-sm-6 shipping-address-copy-btn-container'>
                                                                <a title='' 
                                                                    role='button' 
                                                                    onClick={this.handleCopyFormData.bind(this, SET_SHIPPING_ADDRESS)}
                                                                    className='btn btn-default'>{this.lang.copy_from_contact_address}
                                                                </a>
                                                            </div>
                                                            <div className='col-sm-6'>
                                                                <Address
                                                                    address={shipment_address}
                                                                    header={this.lang.shipping_address}
                                                                    action_type={SET_SHIPPING_ADDRESS}
                                                                />  
                                                            </div>  
                                                        </Fragment> 
                                                        :
                                                        <div className='col-sm-6'>
                                                            <a title=''
                                                                role='button' 
                                                                onClick={this.showShippingForm.bind(this)} 
                                                                className='btn btn-default'>{this.lang.add_shipment_address}
                                                            </a>
                                                        </div>
                                                    }
                                                    <div className='woocommerce-additional-fields col-sm-6 col-xs-12 margin-top-20 margin-bottom-20'>
                                                        <div className='woocommerce-additional-fields__field-wrapper'>
                                                            <p className='form-row notes' id='order_comments_field' data-priority>
                                                                <label htmlFor='remark'>{this.lang.comments_to_order} <span className="optional">({this.lang.optional})</span></label>
                                                                <span className='woocommerce-input-wrapper'>
                                                                    <textarea name="remark" className="input-text form-control" id="order_comments"  value={this.state.remark} onChange={this.updateState} placeholder={this.lang.add_comments_to_order} rows="2" cols="5"></textarea>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className='col-sm-6 clear-left'>
                                                        <div className='pull-left inline-block margin-right-10'>
                                                            <a title='' 
                                                                role='button' 
                                                                onClick={this.showInvoiceForm.bind(this)}  
                                                                className='btn btn-default'>{invoice_form_show ? this.lang.not_need_invoice : this.lang.add_invoice_address}
                                                            </a>
                                                        </div>

                                                        {
                                                            invoice_form_show
                                                            ? 
                                                            <Fragment>
                                                                <div className='inline-block pull-left'>
                                                                    <a title='' 
                                                                        role='button' 
                                                                        onClick={this.handleCopyFormData.bind(this, SET_INVOICE_DATA)}
                                                                        className='btn btn-default'>{this.lang.copy_from_contact_address}
                                                                    </a>
                                                                </div>
                                                                <div className='col-sm-12'></div>
                                                                <Address
                                                                    address={invoice_address}
                                                                    header={this.lang.invoice_address}
                                                                    action_type={SET_INVOICE_DATA}
                                                                />
                                                            </Fragment>
                                                            : ''
                                                        }

                                                    </div>

                                                </div>

                                                <div className='col-sm-12'>        
                                                    <div className='woocommerce-checkout'>
                                                        <h3>{this.lang.your_order}</h3>
                                                    </div>

                                                    <div className='order_review'>
                                                        <div className='table-responsive'>    
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope='col'>{this.lang.product_name}</th>
                                                                        <th scope='col'>{this.lang.quantity}</th>
                                                                        <th scope='col'>{this.lang.price}</th>
                                                                        <th scope='col'>{this.lang.value}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.props.cart.map((item, key) => {
                                                                            return (
                                                                                <tr key={key}>
                                                                                    <td><Link to={this.variationToVariantUrl(item)} title='' >{item.name}</Link></td>
                                                                                    <td><span className='pull-right'>{item.count}</span></td>
                                                                                    <td><span className='pull-right'>{`${Helper.currencyFormatter().format(item.price)}`}</span></td>
                                                                                    <td><span className='pull-right'>{`${Helper.currencyFormatter().format(item.price * item.count)}`}</span></td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colSpan="3">
                                                                            <span className='bold pull-right'>{this.lang.summary}</span>
                                                                        </td>
                                                                        <td colSpan="1">
                                                                            <span className='bold pull-right'>{`${Helper.currencyFormatter().format(this.getOrderAmount())}`}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan="3"><span className='bold pull-right'>{`${this.lang.shipment} - ${this.lang.courier}`}</span></td>
                                                                        <td colSpan="1"><span className='bold pull-right'>{`${Helper.currencyFormatter().format(delivery_cost)}`}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan="3"><span className='bold pull-right'>{this.lang.total}</span></td>
                                                                        <td colSpan="1"><span className='bold pull-right'>{`${Helper.currencyFormatter().format(this.getOrderSum())}`}</span></td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-sm-12">
                                                                <div className="col-sm-6">
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className='payment float-right'>
                                                                        <div className='payment-method-container'>
                                                                            <div className='inline-block'>
                                                                                <ul className='payment-methods'>
                                                                                    <li>
                                                                                        <label htmlFor='payment-method-standard'>
                                                                                            <input id='payment-method-standard' 
                                                                                                type='radio' 
                                                                                                className='input-radio' 
                                                                                                name='payment-method' 
                                                                                                value={STANDARD} 
                                                                                                checked={payment_method === STANDARD}
                                                                                                onChange={this.handlePaymentMethodChange.bind(this, delivery_methods_standard)}
                                                                                                required
                                                                                            /> 
                                                                                            {this.lang.on_line_payments}
                                                                                        </label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <label htmlFor='payment-method-cod'>
                                                                                            <input id='payment-method-cod' 
                                                                                                type='radio' 
                                                                                                className='input-radio' 
                                                                                                name='payment-method' 
                                                                                                value={COD} 
                                                                                                checked={payment_method === COD}
                                                                                                onChange={this.handlePaymentMethodChange.bind(this, delivery_methods_cod)}
                                                                                                required
                                                                                            /> 
                                                                                            {this.lang.cash_on_delivery}
                                                                                        </label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <label htmlFor='payment-method-traditional'>
                                                                                            <input id='payment-method-traditional' 
                                                                                                type='radio' 
                                                                                                className='input-radio' 
                                                                                                name='payment-method' 
                                                                                                value={TRADITIONAL} 
                                                                                                checked={payment_method === TRADITIONAL}
                                                                                                onChange={this.handlePaymentMethodChange.bind(this, delivery_methods_standard)}
                                                                                                required
                                                                                            /> 
                                                                                            {this.lang.traditional_transfer}
                                                                                        </label>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            {
                                                                                payment_method === STANDARD || payment_method === TRADITIONAL
                                                                                ? <Fragment> 
                                                                                        <div>
                                                                                            <p className='bold'>{this.lang.delivery_options}:</p>
                                                                                            {this.couriersList(delivery_methods_standard)}
                                                                                            <ul className='delivery-methods'>
                                                                                                {this.renderDeliveryMethods(delivery_methods_standard)}
                                                                                            </ul>
                                                                                        </div>
                                                                                    {
                                                                                        show_inpost_locker && 
                                                                                            <div>
                                                                                                <p className='bold'>{this.lang.target_point}:</p>
                                                                                                <input 
                                                                                                    id='target_point_search' 
                                                                                                    type='text'
                                                                                                    placeholder={this.lang.set_target_point}
                                                                                                    autoComplete='off'
                                                                                                    className='form-control' 
                                                                                                    name='target_point_search' 
                                                                                                    value={target_point_search}
                                                                                                    onChange={e => this.getPoints(e.target.value, 'target_point')}
                                                                                                    onBlur={this.onBlur}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                    }
                                                                                    {
                                                                                        payment_method !== TRADITIONAL
                                                                                        ?
                                                                                            <div>        
                                                                                                <p className='bold'>{this.lang.payment_methods}:</p>
                                                                                                {
                                                                                                    payment_gateways.map((payment_gateway, key) => {
                                                                                                        return (
                                                                                                            <Fragment key={key} >
                                                                                                                <input type='radio' 
                                                                                                                    id={`${payment_gateway.name}`} 
                                                                                                                    name='payment-gateway' 
                                                                                                                    value={`${payment_gateway.name}`} 
                                                                                                                    className='payment-gateway'
                                                                                                                    checked={payment_gateway_name === payment_gateway.name}
                                                                                                                    onChange={this.handlePaymentGatewayChange.bind(this)}
                                                                                                                    required
                                                                                                                    />
                                                                                                                <label htmlFor={`${payment_gateway.name}`}>
                                                                                                                    <img src={`/${payment_gateway.icon}`} alt={`${payment_gateway.description}`}/>
                                                                                                                </label>
                                                                                                            </Fragment>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        : ''
                                                                                    }
                                                                                </Fragment>
                                                                                : ''
                                                                            }
                                                                            {
                                                                                payment_method === COD
                                                                                ? <Fragment>
                                                                                    <div>
                                                                                        <p className='bold'>{this.lang.delivery_options}:</p>
                                                                                        {this.couriersList(delivery_methods_cod)}
                                                                                        <ul className='delivery-methods'>
                                                                                            {this.renderDeliveryMethods(delivery_methods_cod)}
                                                                                        </ul>
                                                                                    </div>
                                                                                </Fragment> 
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                        <div className='woocommerce float-right'>
                                                                            <div className='form-group'>
                                                                                <input type='checkbox' name='terms_of_use' id='terms_of_use' className='terms_of_use' value={this.state.terms_of_use} checked={this.state.terms_of_use} onChange={this.onCheckboxChange} required />
                                                                                <label htmlFor='terms_of_use'>
                                                                                    {this.lang.agree_with}
                                                                                    <a onClick={e => {
                                                                                        e.preventDefault();
                                                                                        this.goto(`/${this.lang.slug.page}/${this.lang.slug.terms_conditions}`);
                                                                                        return false;
                                                                                    }}
                                                                                    >{this.lang.site_regulations}</a> <sup>*</sup>
                                                                                </label>
                                                                            </div>
                                                                            <div className='form-group'>
                                                                                <input type='checkbox' name='withdrawal_from_contract' id='withdrawal_from_contract' className='withdrawal_from_contract' value={this.state.withdrawal_from_contract} checked={this.state.withdrawal_from_contract} onChange={this.onCheckboxChange} required />
                                                                                <label htmlFor='withdrawal_from_contract'>
                                                                                    {this.lang.reviewed_right_withdraw_from}
                                                                                    <a onClick={e => {
                                                                                        e.preventDefault();
                                                                                        this.goto(`/${this.lang.slug.page}/${this.lang.slug.returns_and_replacements}`);
                                                                                        return false;
                                                                                    }}
                                                                                    >{this.lang.contract}</a> <sup>*</sup>
                                                                                </label>
                                                                            </div>

                                                                            <div className='form-button'>
                                                                                <button type='submit' className='button alt upper float-right' id='place_order'>{this.lang.buy_and_pay}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </form>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    :             
                    <div className='preloader'>
                        <div className='preloader-wrapper'>
                            <Loader type='small'/>
                        </div>
                    </div>
                }
            </Fragment>
        );
    }

    componentDidMount() {
        this.getPayments();
        this.getDeliveryMethods();
        this.updateCartPrice(this.props.cart);
    }

    componentDidUpdate() {
        this.setLoginUserAddress('contact_address', 'Adres korespondencyjny');
    }

    /**
     * Przenosi na określony adres url
     * 
     * @param {string} url - Adres url
     * @return {undefined}
     */
    goto = (url) => {
        window.location.href = url;
    }

    /**
     * Aktualizuje wartość state'ów
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    onCheckboxChange = (e) => {
        this.setState({[e.target.name]: !(e.target.value === 'true')});
    }

    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    /**
     * Aktualizuje ceny produktów w koszyku
     * 
     * @param {Object} data - Dane produktów po rabacie
     * @param {undefined}
     */
    updateCartPrice = (data) => {
        data.map((product, key) => {
            this.props.addCart(false, {
                update_count: parseInt(product.count, 10), 
                price: product.price, 
                id: key
            });
        });
    }

    /**
     * Wyświetla lub ukrywa loader
     * 
     * @return {undefined}
     */
    showLoader = () => {
        this.setState({ loader: !this.state.loader });
    }

    /**
     * Usuwa dane z reduxowego store
     * 
     * @param {String} state - Nazwa state
     * @return {undefined}
     */
    removeState(state) {
        this.props.updateValue({ state });
    }

    /**
     * Ustawia w reduxowym store dane adresowe
     * 
     * @param {String} state - Nazwa state 
     * @param {String} adress_name - Nazwa danych adresowych
     * @return {undefined}
     */
    setLoginUserAddress(state, adress_name) {
        let data = this.getLoginUserAddress(adress_name);
        if (data && typeof this.props[state] === 'undefined') {
            this.props.updateValue({ state: state, data: data });
        }
    }

    /**
     * Tworzy obiekt danych adresowych zalogowanego użytkownika
     * 
     * @param {String} address_name - Typ danych adresowych
     * @return {Object|Boolean}
     */
    getLoginUserAddress(address_name) {
        let user = this.props.user;
        if (typeof user !== 'undefined') {
            let contractor = user.data;
            let address = contractor.addresses.find(address => address.address_name === address_name);
            if (typeof address !== 'undefined') {
                return { ...contractor, ...address };
            }
        }
        return false;
    }

    /**
     * Tworzy url na podstawie obiektu wariantu
     * 
     * @param {Object} variation - Wariant
     * @return {String}
     */
    variationToVariantUrl(variation) {
        return `${this.props.moduleUrl}/${variation.slug}/${variation.id}`;
    }

    /**
     * Metoda pomocnicza zwracająca listę opcji dostawy
     * 
     * @param {Array} delivery_methods - Tablica metod dostawy
     * @return {Array}
     */
    renderDeliveryMethodsHelper(delivery_methods) {
        return delivery_methods.filter(method => 
            (parseInt(method.courier_id, 10) === parseInt(this.state.courier_id, 10)) && method
        );
    }

    /**
     * Zwraca listę opcji dostawy
     * 
     * @param {Array} delivery_methods - Tablica metod dostawy
     * @return {String|Object}
     */
    renderDeliveryMethods(delivery_methods) {
        let methods = this.renderDeliveryMethodsHelper(delivery_methods);
        return this.state.courier_id ? methods.map((method, key) => {
            return (
                <li key={key}>
                    <label htmlFor={`${method.service_id}`}>
                        <input id={`${method.service_id}`}
                            type='radio' 
                            name='delivery-method'
                            value={`${method.service_id}`}
                            checked={(this.state.delivery_method_id === method.id || methods.length === 1) ? true : false}
                            data-delivery-cost={method.price}
                            onChange={this.handleDeliveryMethodChange.bind(this, method)}
                            required
                            />
                        {`${method.description} (${this.lang.estimated_delivery_time}: ${this.lang[`${method.delivery_time}`]})`}
                    </label>
                </li>
            )
        }) : '';
    }

    /**
     * Zwraca kod rabatowy
     * 
     * @return {String}
     */
    getCode() {
        if (localStorage.getItem('shop.cart.code') !== null) {
            return localStorage.getItem('shop.cart.code');
        }
        return '';
    }

    submit = (e) => {
        e.preventDefault();

        this.setState({
            loader: true
        });

        let { delivery_method_id, payment_method, payment_gateway_name, remark, inpost_locker } = this.state;
        let { cart, contact_address, shipping_address, invoice_data } = this.props;
        
        let order_items = cart.map(item => ({
            variation_id: item.id,
            quantity: item.count
        }));

        let code = this.getCode();

        let data = {
            code: code,
            gateway: payment_gateway_name || payment_method,
            redirect_url: `${root_url}/${this.lang.slug.shop}/${this.lang.slug.order_summary}`,
            delivery_method_id: delivery_method_id,
            order_items: order_items,
            contact_address: contact_address,
            shipping_address: shipping_address,
            invoice_data: invoice_data,
            remark: remark,
            inpost_locker: inpost_locker
        }

        // Sprawdzenie czy użytkownik jest zalogowany
        if (typeof this.props.user !== 'undefined') {
            data.user_id = this.props.user.data.user_id;
        }

        let order_date = this.getOrderDate();

        API.post(`${this.state.module_url}/api/shop/checkout/order`, data, false, false, {indices: true}).then(res => {
            if (!res.error) {

                if (typeof res.data.user_alredy_exists !== 'undefined') {
                    this.props.history.push(`/${this.lang.slug_user_account}/${this.lang.slug_login_register}`);
                    toastr.warning(res.data.message);
                } else {
                    let order_summary = { ...res.data.data, payment_method, order_date };
                    this.setOrderSummary(order_summary);

                    // czyszczenie koszyka
                    localStorage.removeItem('shop.cart');
                    localStorage.removeItem('shop.cart.code')
                    
                    if (this.state.payment_method === STANDARD) { 
                        window.location.href = res.data.data.approvalLink;
                    } else {
                        this.props.history.push(`${this.lang.slug.order_summary}`);
                    }
                }
                
            } else {
                this.setState({
                    loader: false
                })
                if (res.data.errors) {

                    let errors = res.data.errors;

                    if ('order_items' in errors) {
                        errors.order_items.map(item => {

                            let cart = JSON.parse(localStorage.getItem('shop.cart'));

                            item.available_quantity.map(quantity_item => {
                                cart.map(cart_item => {
                                    if (quantity_item.variation_id == cart_item.id) {
                                        cart_item.count = quantity_item.quantity;
                                        cart_item.quantity = quantity_item.quantity;
                                    }
                                })
                            });

                            this.updateCart(cart);

                            this.props.history.push(`${this.lang.cart}`);

                            toastr.warning(item.message);
                        });
                    } else {
                        // błędy walidacji
                        this.props.setErrors(errors);
                    }
                } else {
                    toastr.error(res.data.message);
                }
            }
        })
    }

    setOrderSummary = (data) => {
        localStorage.setItem('shop.order.summary', JSON.stringify(data));
    } 

    getOrderDate = () => {
        let date_obj = new Date();
        let month = date_obj.getUTCMonth() + 1;
        let day = date_obj.getUTCDate();
        let year = date_obj.getUTCFullYear();
        return `${("0" + day).slice(-2)}-${("0" + month).slice(-2)}-${year}`;
    }

    showShippingForm = () => {
        this.setLoginUserAddress('shipping_address', 'Adres do wysyłki');
        this.setState({
            shipping_form_show: !this.state.shipping_form_show
        });
    }

    showInvoiceForm = () => {
        let show = !this.state.invoice_form_show;
        let invoice_data = 'invoice_data';
        if (show) {
            this.setLoginUserAddress(invoice_data, 'Adres do faktury');
        } else {
            this.removeState(invoice_data);
        }
        this.setState({ invoice_form_show: show });
    }

    /**
     * Ustawia dane wybranego sposobu płatności
     * 
     * @param {Array} delivery_method - Metody dostawy
     * @param {Object} e - Event zmiany metody płatności
     * @return {undefined}
     */
    handlePaymentMethodChange = (delivery_methods, e) => {
        let id = null;
        let courier_id = this.state.courier_id;
        let methods = this.filterDeliveryMethodByCourierId(delivery_methods, courier_id);
        let payment_method = e.target.value;

        // Jeżeli metody dostawy dla wybranego kuriera istnieją pobieramy 
        // pierwszą, wybieramy ją jako domyślną i oznaczamy select kuriera
        if (typeof methods[0] !== 'undefined') {
            let method = methods[0];
            this.displayCourierInpostLocker(method.service_id);
            id = method.id;
        }

        this.setState({
            payment_method: payment_method,
            delivery_method_id: id
        }, () => {
            this.calculateDeliveryCost(id).then(res => {
                this.setState({
                    delivery_cost: res.delivery_service_cost || 0
                });
            });
        });
    }

    /**
     * Ustawia sposób dostawy oraz informację o cenie
     * 
     * @param {Integer} id - Identyfikator metody dostawy
     * @return {undefined}
     */
    setDeliveryMethod = (id) => {
        this.setState({
            delivery_method_id: id
        }, () => {
            this.calculateDeliveryCost(id).then(res => {
                this.setState({
                    delivery_cost: res.delivery_service_cost || 0
                });
            });
        });
    }

    handleDeliveryMethodChange = (method, e) => {
        this.setDeliveryMethod(method.id);
        this.displayCourierInpostLocker(method.service_id);
    }

    handlePaymentGatewayChange = (e) => {
        this.setState({
            payment_gateway_name: e.target.value
        })
    }

    handleCopyFormData = (action_type) => {
        this.props.setAddress(this.props.contact_address, action_type);
    }

    getOrderAmount = () => {
        let total = 0;
        this.props.cart.map(item => {
            total += parseFloat(item.price) * parseInt(item.count);
        });
        return total;
    }

    getOrderSum = () => {
        return parseFloat(this.state.delivery_cost) + parseFloat(this.getOrderAmount());
    }

    getPayments = () => {
        API.get({url: `${this.state.module_url}/api/payment`}).then(res => {
            this.setState({
                payment_gateways: res.data.data
            })
        })
    }

    getDeliveryMethods = () => {
        API.get({url: `${this.state.module_url}/api/courier-center/courier/delivery-methods`}).then(res => {
            const couriers = res.data.data;

            const delivery_methods_cod = [];
            const delivery_methods_standard = [];

            couriers.map(delivery_method => {
                delivery_method.services.map(service => {
                    const arr_service = service.service_id.split('_');
                    const payment_method = arr_service[arr_service.length - 1];
                    if (payment_method === COD) {
                        delivery_methods_cod.push(service);
                    } else {
                        delivery_methods_standard.push(service)
                    }

                    // Ustawiamy odbiór osobisty również jako opcja za pobraniem
                    if (service.service_id === 'self_pickup') {
                        delivery_methods_cod.push(service);
                    }

                })
            })

            couriers.map(courier => delete courier.services)

            this.setState({
                couriers,
                delivery_methods_standard,
                delivery_methods_cod
            })
        })
    }

    couriersList(delivery_method) {
        return (
            <select onChange={this.handleCourierChange.bind(this, delivery_method)} className='form-control' name='couriers' value={this.state.courier_id} required>
                <option value=''>{this.lang.select}</option>
                {
                    this.state.couriers.map((courier, key) => {
                        return ( 
                            <option key={key} value={`${courier.id}`}>
                                {`${courier.description}`}
                            </option>
                        );
                    })
                }
            </select>
        );
    }

    /**
     * Filtruje metody dostawy dla wybranego kuriera
     * 
     * @param {Array} methods - Metody dostawy
     * @param {Integer} courier_id - Identyfikator kuriera
     * @return {Array}
     */
    filterDeliveryMethodByCourierId(methods, courier_id) {
        return methods.filter(method => method.courier_id == courier_id);
    }

    /**
     * Ustawia dane wybranego kuriera w state
     * 
     * @param {Array} delivery_method - Metody dostawy
     * @param {Object} e - Event zmiany kuriera
     * @return {undefined}
     */
    handleCourierChange = (delivery_methods, e) => {
        let id = null;
        let courier_id = e.target.value;
        let methods = this.filterDeliveryMethodByCourierId(delivery_methods, courier_id);
        let method = {};

        // Jeżeli metody dostawy dla wybranego kuriera istnieją pobieramy 
        // pierwszą, wybieramy ją jako domyślną i oznaczamy select kuriera
        if (typeof methods[0] !== 'undefined') {
            method = methods[0];
            id = method.id;
        }

        this.displayCourierInpostLocker(method.service_id);

        this.setState({
            courier_id: courier_id,
            delivery_method_id: id
        }, () => {
            this.calculateDeliveryCost(id).then(res => {
                this.setState({
                    delivery_cost: res.delivery_service_cost || 0
                });
            });
        });
    }

    /**
     * Usuwa dane z reduxowego store
     * 
     * @param {Array} states - Tablica nazw state
     * @return {undefined}
     */
    removeStates(states) {
        states.forEach(state => this.props.updateValue({ state }));
    }

    componentWillUnmount() {
        this.removeStates(['contact_address', 'shipping_address', 'invoice_data']);
    }

    /**
     * Aktualizuje wartość koszyka
     * 
     * @param {Array} cart - Koszyk
     * @return {undefined}
     */
    updateCart(cart) {
        this.props.updateValue({
            state: 'shop.widget.cart',
            data: cart
        });
        
        localStorage.setItem('shop.cart', JSON.stringify(cart));

        this.forceUpdate();
    }
 
    /**
     * Przelicza koszt dostawy
     * 
     * @param {Integer} delivery_method_id - Identyfikator metody dostawy
     * @return {undefined}
     */
    calculateDeliveryCost = (delivery_method_id) => {
        let { cart } = this.props;

        let order_items = cart.map(variation => ({
            variation_id: variation.id,
            quantity: variation.count
        }));

        let data = { delivery_method_id, order_items };

        return new Promise(resolve => {
            API.post('/api/shop/basket/calculate', data, false, false, { indices: true }).then(res => {
                if (!res.error) {
                    resolve(res.data);
                } else {
                    resolve({ error: true, message: res.data.message });
                }
            });
        });
    }

    /**
     * Umożliwia dynamiczne wyszukiwanie punktów
     *
     * @param {String} address Adres punktu wyszukiwania
     * @param {String} target Pole docelowe
     * @return {undefined}
     */
    getPoints(address, target) {
        this.setState({
            [`${target}_search`]: address,
            inpost_locker: ''
        });

        // tworzenie elementów w których będzie renderowana lista points
        const points = document.querySelector(`input[name="${target}_search"]`);
        const box = document.createElement('div');
        const ul = document.createElement('ul');

        const boxClassName = `${target}_points`;

        // style box
        box.className = boxClassName;
        box.style.width = '100%';
        box.style.marginTop = '5px';
        // style ul
        ul.style.listStyleType = 'none';
        ul.style.padding = 0;

        // sprawdzamy czy lista points istnieje, czy istniej w drzewie DOM
        if (document.getElementsByClassName(boxClassName).length) {
            const parentNode = points.parentNode;
            parentNode.removeChild(parentNode.childNodes[parentNode.childNodes.length - 1]);
        }

        points.after(box);
        box.append(ul);

        clearTimeout(this.state.timeout);

        const timeout = setTimeout(() => {
            // pobieranie pointsów
            API.get({ url: `api/inpost/points?filtered[0][id]=address&filtered[0][value]=${address}` }).then(res => {
                if (!res.error) {
                    res.data.data.map(item => {
                        // elementy listy
                        const li = document.createElement('li');
                        const point = document.createElement('div');

                        li.append(point);
                        ul.appendChild(li);

                        // style elementu listy
                        point.style.padding = '10px';
                        point.style.border = '1px solid #ced4da';
                        point.style.borderRadius = '.25rem';
                        point.style.marginBottom = '1px';
                        point.style.cursor = 'pointer';

                        point.onmouseover = function() {
                            this.style.backgroundColor = '#f4f5f8'; // zmiana koloru po najechaniu myszy
                        };

                        point.onmouseleave = function() {
                            this.style.backgroundColor = '#ffffff'; // zmiana koloru po opuszczeniu pola
                        };

                        point.onclick = () => {
                            //const target_field = document.querySelector(`input[name="${target}"]`) // pole docelowe, którego wartosć będzie przesyłana do backend
                            //target_field.value = item.name // wartość pola docelowego
                            const parentNode = points.parentNode;
                            parentNode.removeChild(parentNode.childNodes[parentNode.childNodes.length - 1]); // usuwanie listy wyboru
                            //points.value = `${item.address} ${item.payment}` // ustawianie wartości pola docelowego
                            this.updateCourierInpostLocker(target, item);
                        };

                        point.innerHTML = `${item.address} ${item.payment}`; // ustawianie tekstu dla każdego elementu listy
                    });
                }
            });
        }, 500);

        this.setState({ timeout });
    }

    /**
     * Aktualizuje informacje dot. punktu docelowego (inpost)
     * 
     * @param {String} target Adres punktu wyszukiwania
     * @param {Object} point Dane punktu docelowego
     * @return {undefined}
     */
    updateCourierInpostLocker = (target, point) => {
        this.setState({
            [`${target}_search`]: `${point.address} ${point.payment}`,
            inpost_locker: point.name
        });
    }

    /**
     * Usuwa informacje dot. punktu docelowego (inpost)
     * 
     * @return {undefined}
     */
    clearCourierInpostLocker = () => {
        this.setState({
            target_point_search: '',
            inpost_locker: ''
        });
    }

    /**
     * Wyświetla i ukrywa pola wyboru punktu odbioru (inpost)
     * 
     * @param {String} service Nazwa usługi
     * @return {undefined}
     */
    displayCourierInpostLocker = (service) => {
        this.setState({ show_inpost_locker: false });
        if (service === 'inpost_locker_standard') {
            this.setState({ show_inpost_locker: true });
        } else {
            this.clearCourierInpostLocker();
        }
    }

    onBlur = (evt) => {
        let { inpost_locker } = this.state;
        if (!inpost_locker) {
            this.clearCourierInpostLocker();
        }
    }

}

Checkout.defaultProps = {
    cart: []
};

const mapStateToProps = (state) => {
    return {
        cart: state.boost[`shop.widget.cart`],
        addCart: state.boost['shop.widget.cart.add'],
        contact_address: state.boost.contact_address,
        shipping_address: state.boost.shipping_address,
        invoice_data: state.boost.invoice_data,
        user: state.boost.user,
        cart: state.boost[`shop.widget.cart`]
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setErrors,
    setAddress,
    updateValue
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Checkout);