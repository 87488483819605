import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Lang from '../lang/';
import Loader from 'partials/loader';
import ContactUs from '../../contact-us';

class PageDescription extends React.Component {

    lang = Lang[global.language];

    state = {
        page: false
    }

    uniqURL = {
        [this.lang.slug.terms_and_conditions_slug]: 'regulamin',
        [this.lang.slug.about_us_slug]: 'o-nas',
        [this.lang.slug.policies_slug]: 'warunki-umowy',
        [this.lang.slug.contact_slug]: 'kontakt',
        [this.lang.slug.how_to_buy_slug]: 'jak-kupowac',
        [this.lang.slug.footwear_how_choose_size_slug]: 'obuwie-jak-dobrac-rozmiar',
        [this.lang.slug.clothing_how_choose_size_slug]: 'odziez-jak-dobrac-rozmiar',
        [this.lang.slug.delivery_slug]: 'dostawa',
        [this.lang.slug.payment_methods_slug]: 'metody-platnosci',
        [this.lang.slug.faq_slug]: 'faq',
        [this.lang.slug.returns_and_replacements_slug]: 'zwrot',
        [this.lang.slug.privacy_policy]: 'polityka-prywatnosci',
        [this.lang.slug.shipping_costs]: 'koszty-wysylki',
        [this.lang.slug.payment_methods]: 'sposoby-platnosci',
        [this.lang.slug.damaged_goods]: 'uszkodzony-towar',
        [this.lang.slug.i_want_to_exchange_the_goods]: 'chce-wymienic-towar',
        [this.lang.slug.refund_or_complaint]: 'zwracam-lub-reklamuje'
    }

    checkUniqURL = (slug) => {
        if (this.uniqURL[slug]) {
            return this.uniqURL[slug];
        }
        return 'not-uniq';
    }

    render() {
        let { page } = this.state;
        let { params } = this.props.match;

        return (
            page ? 
            <React.Fragment>
                <div className='main-header background' style={{ background: 'linear-gradient(rgba(28, 28, 28, 0.3), rgba(28, 28, 28, 0.3)), url("/themes/default/img/backgrounds/background-about.jpg")' }}>
                    <div className="container">
                        <h1 className="main-header-title text-center block">
                            <span>{this.lang[page.slug]}</span>
                        </h1>
                    </div>
                </div>
                <section className='elements-section'>
                    <div className='container'>
                        <div className='row'>
                            <div className={`${params.slug === 'kontakt' ? 'col-md-5' : 'col-md-10'} col-md-offset-1`} dangerouslySetInnerHTML={{__html: page.content}}>
                            </div>
                            {params.slug === 'kontakt' && <ContactUs />}
                        </div>
                    </div>
                </section>
            </React.Fragment>
            :
            <div className='preloader'>
                <div className='preloader-wrapper'>
                    <Loader type='small'/>
                </div>
            </div>
        )
    }

    componentDidMount() {
        let { params } = this.props.match;

        if (!this.props.pages || !this.props.pages[params.slug]) {
            this.props.load({ url: `api/menu-maker/pages/${this.checkUniqURL(params.slug)}`}).then(res => {
                if (!res.error) {
                    this.props.updateValue({ state: 'pages', data: { ...this.props.pages, [params.slug]: res } })
                    this.loadData(res);
                } else if (res.status && res.status === 404) {
                    let prevUrl = this.props.location.prevUrl;
                    if (!!prevUrl) {
                        this.props.history.replace(prevUrl);
                        this.props.history.push({
                            pathname: '/404',
                            state: { 
                                pathname: this.props.match.url
                            }
                        });
                    } else {
                        this.props.history.push("/");
                    }
                }
            });
        } else {
            this.loadData(this.props.pages[params.slug]);
        }
    }

    loadData(data) {
        this.setState({page: data.data})
    }
}

const mapStateToProps = (state) => {
    return {
        loader: state.boost.loader,
        pages: state.boost.pages
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PageDescription);