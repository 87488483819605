import React from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load } from 'boost/dispatch';
import Lang from '../lang';
import { Link } from 'react-router-dom';
import Helper from 'helpers';
import ValidForm from 'helpers/valid-form'

import '../style.scss'

class ListReturnsComponent extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        data: [],
        pages: 10,
        loading: false,
        return_reasons: [],
        return_statuses: []
    }

    id_timeout = 0;

    render() {
        const { data, pages, loading } = this.state;

        return (
            <ReactTable
                columns={[
                    {
                        Header: this.lang.order_number,
                        accessor: 'order_no'
                    },
                    {
                        Header: this.lang.email,
                        accessor: 'email'
                    },
                    {
                        Header: this.lang.order_total,
                        id: 'price',
                        Cell: row => <span>{`${Helper.currencyFormatter().format(row.original.price)}`}</span>,
                    },
                    {
                        Header: this.lang.status,
                        id: 'return_status_id',
                        filterMethod: (filter, row) => {
                            if (filter.value === '') {
                                return true;
                            } else if (row[filter.id] == filter.value) {
                                return true;
                            }
                        },
                        Filter: ({ filter, onChange }) => (
                            <select onChange={event => onChange(event.target.value)} style={{ width: '100%' }} value={filter ? filter.value : ''}>
                                <option value=''>{this.lang.all}</option>
                                {
                                    this.state.return_statuses.map(
                                        (status, key) => <option key={key} value={status.value}>{status.description}</option>
                                    )
                                }
                            </select>
                        ),
                        Cell: row => <span>{row.original.status}</span>,
                        maxWidth: 260
                    },
                    {
                        Header: this.lang.return_reason,
                        id: 'return_reason_id',
                        filterMethod: (filter, row) => {
                            if (filter.value === '') {
                                return true;
                            } else if (row[filter.id] == filter.value) {
                                return true;
                            }
                        },
                        Filter: ({ filter, onChange }) => (
                            <select onChange={event => onChange(event.target.value)} style={{ width: '100%' }} value={filter ? filter.value : ''}>
                                <option value=''>{this.lang.all}</option>
                                {
                                    this.state.return_reasons.map(
                                        (reason, key) => <option key={key} value={reason.value}>{reason.description}</option>
                                    )
                                }
                            </select>
                        ),
                        Cell: row => <span>{row.original.reasons.length > 0 ? row.original.reasons[0].reason : '-'}</span>,
                        maxWidth: 260
                    },
                    {
                        Header: this.lang.return_created_at,
                        accessor: 'created_at'
                    },
                    {
                        Header: this.lang.actions,
                        id: 'edit',
                        sortable: false,
                        maxWidth: 150,
                        filterable: false,
                        Cell: row => 
                            <React.Fragment>
                                <Link to={`${this.props.route}/${this.lang.slug_returns}/${row.original.id}/edit`} className='button' title={this.lang.details}>
                                    <i className='fa fa-pencil-square-o'></i>
                                </Link>
                                <button className='button margin-left-10' onClick={() => this.downloadPDF(row)} title={this.lang.download_pdf_file}>
                                    <i className='fa fa-file-o'></i>
                                </button> 
                            </React.Fragment>
                    }
                ]}
                {...this.lang.tables_langs}
                manual
                data={data}
                pages={pages}
                loading={loading}
                onFetchData={this.fetchData}
                filterable
                defaultPageSize={10}
                minRows={1}
            />
        );
    }

    componentDidMount() {
        this.props.load({ url: `api/admin/get_reasons` }).then(res => {
            this.setState({ return_reasons: res.data });
        });
        this.props.load({ url: `api/admin/get_status` }).then(res => {
            this.setState({ return_statuses: res.data });
        });
    }

    fetchData = (state) => {
        clearTimeout(this.id_timeout);

        this.id_timeout = setTimeout(() => {
            this.setState({ loading: true });

            let page = state.page + 1;
            let params = {
                page,
                per_page: state.pageSize,
            }

            if (state.sorted.length) {
                params['sorted'] = ValidForm.parseToGetString(state.sorted, 'sorted')
            }

            if (state.filtered.length) {
                params['filtered'] = ValidForm.parseToGetString(state.filtered, 'filtered');
            }

            this.props.load({ url: 'api/admin/return_order', data: params }).then(res => {
                if (!res.errors) {
                    this.setState({
                        data: res.data,
                        pages: res.meta.last_page,
                        loading: false
                    });
                } else {
                    this.setState({ loading: false });
                }
            });
        }, 500);
    }

    downloadPDF(row) {
        window.location = `${location.protocol}//${location.host}/api/admin/download_return_pdf/${row.original.id}`;
    }

}

const mapDispatchToProps = dispatch => bindActionCreators({
    load
}, dispatch)

export default connect(
    null,
    mapDispatchToProps,
)(ListReturnsComponent);