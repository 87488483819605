import React from 'react';
import Lang from './lang/';
import API from 'boost/API';
import Loader from 'partials/loader';
import PropTypes from 'prop-types';

class Newsletter extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        email: '',
        loader: false,
        errors: []
    }

    render() {

        let { inModal } = this.props;

        return (
            !this.state.loader ?
            <section>
                <div className="section-header center">
                    <p>{this.lang.subtitle}</p>
                        <h2 className="upper margin-bottom-20">{this.lang.title}</h2>
                    </div>
                <div className='container'>
                    <div className='margin-bottom-50'>
                        <div className='subscible-wrapper subscible-inline'>

                            <div className='row'>
                                <div className={!inModal ? 'col-md-3' : undefined}>
                                    <h3 className='subscribe-title'>{this.lang.subscribe_title}</h3>
                                </div>

                                <div className={!inModal ? 'col-md-3' : undefined}>
                                    <div className='subscribe-comment'>
                                        <p>{this.lang.desc}</p>
                                    </div>
                                </div>

                                <div className={!inModal ? 'col-md-6' : undefined}>
                                    <form onSubmit={this.onSave} className='subscible-form'>
                                        <div className='form-group'>
                                            <label className='sr-only' htmlFor='subscribe-email'>Email</label>
                                            <input type='email' name='email' placeholder={this.lang.input_placeholder} className='form-control' value={this.state.email} onChange={this.updateState} id='subscribe-email' required />
                                            {this.showError('email')}
                                        </div>

                                        <div className='form-submit'>
                                            <button className='btn btn-lg btn-primary' type='submit'>{this.lang.save}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            :             
            <div className='preloader'>
                <div className='preloader-wrapper'>
                    <Loader type='small'/>
                </div>
            </div>
        )
    }

    /**
     * Aktualizuje wartości w state
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    /**
     * Przechwytuje zdarzenie wysłania formularza dodawania adresu email do newslettera
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    onSave = (e) => {
        e.preventDefault();
        this.showLoader();
        this.clearErrors();
        API.post('api/freshmail/newsletter', { email: this.state.email }).then(res => {
            if (!res.error) {
                this.props.close();
                toastr.success(res.data.message);
            } else {
                if (res.data.errors) {
                    this.setState({
                        errors: res.data.errors
                    })
                } else {
                    toastr.error(res.data.message);
                }
            }
            this.showLoader();
        })
    }

    /**
     * Wyświetla lub ukrywa loader
     * 
     * @return {undefined}
     */
    showLoader() {
        this.setState({ loader: !this.state.loader });
    }

    /**
     * Wyświetla błędy formularza zwrócone przez API
     * 
     * @param {String} name - Nazwa pola formularza
     * @return {undefined}
     */
    showError = (name) => {
        let { errors } = this.state;
        if (errors[name]) {
            return (
                <React.Fragment>
                    {
                        errors[name].map((error, key) =>
                            <div className={`text-danger`}  key={key}>{error}</div>
                        )
                    }
                </React.Fragment>
            )
        }
    }

    /**
     * Usuwa z obiektu błedów informacje o błędach walidacji formularza
     * 
     * @return {undefined}
     */
    clearErrors() {
        this.setState({ errors: {} });
    }

}

Newsletter.defaultProps = {
    inModal: false,
    close: () => {}
}

Newsletter.propTypes = {
    inModal: PropTypes.bool,
    close: PropTypes.func
};

export default Newsletter;