import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import countries from './countries.json';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Lang from '../lang';
import { 
    setAddress,
    SET_CONTACT_ADDRESS, 
    SET_SHIPPING_ADDRESS, 
    SET_INVOICE_DATA
} from 'boost/dispatch';

class Address extends Component {

    lang = Lang[global.language];

    state = {
        address_type: '',
        address: {}
    }

    render() {

        let { address_type, address } = this.state;
        
        return (
            <Fragment>
                <div className='woocommerce-checkout'>
                    <h3 className='inline-block width-100'>{this.props.header}</h3>
                </div>
                <div className='form-group pull-left form-row-first'>
                    <label htmlFor='first_name'>{this.lang.first_name} <sup>*</sup></label>
                    <input type='text' name='first_name' value={ typeof address.first_name !== 'undefined' ? address.first_name : '' } placeholder={this.lang.add_first_name} className='form-control' id='first_name' onChange={this.handleInput} required />
                    {this.showError(`${address_type}.first_name`)}
                </div>
                <div className='form-group pull-right form-row-last'>
                    <label htmlFor='last_name'>{this.lang.last_name} <sup>*</sup></label>
                    <input type='text' name='last_name' value={ typeof address.last_name !== 'undefined' ? address.last_name : '' } placeholder={this.lang.add_last_name} className='form-control' id='last_name' onChange={this.handleInput} required />
                    {this.showError(`${address_type}.last_name`)}
                </div>
                <div className='form-group'>
                    <label htmlFor='firm'>{this.lang.firm_name_optional}</label>
                    <input type='text' name='firm' value={ typeof address.firm !== 'undefined' ? address.firm : '' } placeholder={this.lang.add_firm_name_optional} className='form-control' id='firm'onChange={this.handleInput} />
                    {this.showError(`${address_type}.firm`)}
                </div>
                <div className='form-group'>
                    <label htmlFor='nip'>{this.lang.nip_optional}</label>
                    <input type='text' name='nip' value={ typeof address.nip !== 'undefined' ? address.nip : '' } placeholder={this.lang.add_nip_optional} className='form-control' id='nip' onChange={this.handleInput} />
                    {this.showError(`${address_type}.nip`)}
                </div>
                <div className='form-group'>
                    <label htmlFor='country'>{this.lang.country} <sup>*</sup></label>
                    <select className='form-control' name='country' value={ typeof address.country !== 'undefined' ? address.country : '' } id='country' onChange={this.handleInput} required>
                        <option value={''}>{this.lang.select}</option>
                        {
                            countries.map((country, key) => <option value={country[this.lang.country_name_lang]} key={key}>{country[this.lang.country_name_lang]}</option>)
                        }
                    </select>
                    {this.showError(`${address_type}.country`)}
                </div>
                <div className='form-group'>
                    <label htmlFor='street'>{this.lang.street} <sup>*</sup></label>
                    <input type='text' name='street' value={ typeof address.street !== 'undefined' ? address.street : '' } placeholder={this.lang.add_street} className='form-control' id='street' onChange={this.handleInput} required/>
                    {this.showError(`${address_type}.street`)}
                </div>
                <div className='form-group'>
                    <label htmlFor='post_code'>{this.lang.zip_code} <sup>*</sup></label>
                    <input type='text' name='post_code' value={typeof address.post_code !== 'undefined' ? address.post_code : '' } placeholder={this.lang.add_zip_code} className='form-control' id='post_code' onChange={this.handleInput} required/>
                    {this.showError(`${address_type}.post_code`)}
                </div>
                <div className='form-group'>
                    <label htmlFor='city'>{this.lang.city} <sup>*</sup></label>
                    <input type='text' name='city' value={ typeof address.city !== 'undefined' ? address.city : '' } placeholder={this.lang.add_city} className='form-control' id='city' onChange={this.handleInput} required/>
                    {this.showError(`${address_type}.city`)}
                </div>
                <div className='form-group'>
                    <label htmlFor='phone_number'>{this.lang.phone} <sup>*</sup></label>
                    <input type='text' name='phone_number' value={ typeof address.phone_number !== 'undefined' ? address.phone_number : '' } placeholder={this.lang.add_phone} className='form-control' id='phone_number' onChange={this.handleInput} required/>
                    {this.showError(`${address_type}.phone_number`)}
                </div>
                <div className='form-group'>
                    <label htmlFor='email'>{this.lang.email} <sup>*</sup></label>
                    <input type='email' name='email' value={ typeof address.email !== 'undefined' ? address.email : '' } placeholder={this.lang.add_email} className='form-control' id='email' onChange={this.handleInput} required/>
                    {this.showError(`${address_type}.email`)}
                </div>
            </Fragment>
        );
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.address) !== JSON.stringify(this.props.address)) {
            let action_type = this.props.action_type;
            let address_type = this.getFieldName(action_type);
            let addresses = this.getAddressesFromLocalStorage();
            let address = { ...this.props.address };
            addresses[address_type] = address;
            localStorage.setItem('shop.checkout.addresses', JSON.stringify(addresses));
            this.props.setAddress(this.props.address, action_type);
            this.setState({ address });
        }
    }
    
    loadData = () => {
        let action_type = this.props.action_type;
        let address_type = this.getFieldName(action_type);
        let addresses = this.getAddressesFromLocalStorage();
        let address = { ...this.props.address, ...addresses[address_type] };
        addresses[address_type] = address;
        localStorage.setItem('shop.checkout.addresses', JSON.stringify(addresses));
        this.props.setAddress(addresses[address_type], action_type);
        this.setState({ address_type, address });
    }

    getFieldName = (action_type) => {
        let split = action_type.split('/');
        return split[split.length - 1];
    } 

    showError = (name) => {
        let { errors } = this.props;
        if (errors[name]) {
            return (
                <React.Fragment>
                    {
                        errors[name].map((error, key) =>
                            <div className={`text-danger`}  key={key}>{error}</div>
                        )
                    }
                </React.Fragment>
            )
        }
    }

    handleInput = (e) => {
        let prop_name = e.target.name;
        let prop_val = e.target.value;
        let address_type = this.getFieldName(this.props.action_type);
        this.setAddressInlocalStorage(address_type, prop_name, prop_val);
        this.props.setAddress({ [prop_name]: prop_val }, this.props.action_type);
    }

    getAddressFromLocalStorage = (address_type) => {
        let addresses = this.getAddressesFromLocalStorage();
        if (typeof addresses[address_type] !== 'undefined') {
            return addresses[address_type];
        }
        return false;
    }

    getAddressesFromLocalStorage = () => {
        return JSON.parse(localStorage.getItem('shop.checkout.addresses') || '{}');
    }

    setAddressInlocalStorage = (address_type, name, value) => {
        let addresses = this.getAddressesFromLocalStorage();
        if (typeof addresses[address_type] === 'undefined') {
            addresses[address_type] = {};
        }
        addresses[address_type][name] = value;
        localStorage.setItem('shop.checkout.addresses', JSON.stringify(addresses));
    }
}

Address.propTypes = {
    header: PropTypes.string.isRequired,
    action_type: PropTypes.string.isRequired,
    address: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        street: PropTypes.string,
        nip: PropTypes.string,
        firm: PropTypes.string,
        country: PropTypes.string,
        post_code: PropTypes.string,
        city: PropTypes.string,
        phone_number: PropTypes.string,
        email: PropTypes.string
    }),
};

Address.defaultProps = {
    header: '',
    action_type: '',
    address: {
        first_name: '',
        last_name: '',
        street: '',
        nip: '',
        firm: '',
        country: '',
        post_code: '',
        city: '',
        phone_number: '',
        email: ''
    }
};

const mapStateToProps = (state, ownProps) => {
    let address = ownProps.address;
    // Sprawdzamy dla jakiego formularza mają być załadowane dane adresowe
    switch (ownProps.action_type) {
        case SET_CONTACT_ADDRESS:
            return {
                address: { ...address, ...state.boost.contact_address },
                errors: state.boost.errors
            }
        case SET_SHIPPING_ADDRESS:
            return {
                address: { ...address, ...state.boost.shipping_address },
                errors: state.boost.errors
            }
        case SET_INVOICE_DATA:
            return {
                address: { ...address, ...state.boost.invoice_data },
                errors: state.boost.errors
            }
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setAddress
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Address);