export default {
    slug_shop: 'sklep',
    slug_category: 'kategoria',
    slug_part_car_body: 'czesci-karoserii',
    slug_workshop_tools: 'narzedzia-warsztatowe',
    slug_lighting: 'oswietlenie',
    slug_car_windows: 'szyby-samochodowe',
    slug: {
        slug_shop: 'sklep',
        slug_category: 'kategoria',
    }
}