import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Lang from '../lang';

class ProductSearchWidget extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        product: !!this.props.search ? (!!this.props.search.name ? this.props.search.name : '')  : '',
        timeout: null
    }

    render() {
        return (
            <input 
                type='text' 
                name='product'
                placeholder={this.lang.search_product_title}
                className='form-control product-search' 
                onChange={this.handleChangeInput} 
                value={this.state.product}
            />
        );
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.search) !==  JSON.stringify(prevProps.search)) {
            let search = this.props.search;
            search = typeof search !== 'undefined' 
                ? (typeof search.name !== 'undefined' ? search.name : '') 
                : '';
            this.setState({ product: search });
        }
    }

    /**
     * Przechwytuje zdarzenie zmiany vartości pola input
     * 
     * @param { object } e - Event
     * @return { undefined }
     */
    handleChangeInput = (e) => {
        let value = e.target.value;
        this.setState({ product: value });
        this.filterListProducts(value);
    }

    scrollToFilters = () => {
        document.getElementById('breadcrumb').scrollIntoView();
    }

    /**
     * Filtruje listę produktów
     * 
     * @param { string } value - Wyszukiwana fraza
     * @return { undefined }
     */
    filterListProducts = (value) => {
        clearTimeout(this.state.timeout);
        let timeout = setTimeout(() => {
            this.props.updateValue({ state: 'poduct_list_filters', data: { name:  value }, join: true });
            this.scrollToFilters();
        }, 500);
        this.setState({timeout})
    }

}

const mapStateToProps = (state) => {
    return {
        search: state.boost.poduct_list_filters,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue,
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductSearchWidget);