import React from 'react';
import { Route, Link, Switch, Router } from 'react-router-dom';
import Container from '../../../container';

class NewsList extends React.Component {
    render() {
        console.log('NewsList', this.props)
        return (
            <Container type='default'>
                Lista nowości
                <Link to='/moje-strony/nowosci/test'>Idź</Link>
                <Link to='/'>Strona główna</Link>
            </Container>
        )
    }
}

export default NewsList;