import React, { Component, Fragment } from 'react';
import Lang from '../lang';
import Helper from 'helpers';
import { Link } from 'react-router-dom';
import queryString from 'query-string'
import API from 'boost/API';
import BankTransferDetails from './bank-transfe-details';

const MAX_REQUESTS = 10;

class OrderSummary extends Component {

    lang = Lang[global.language];

    set_interval = false;

    requests_number = 0;

    state = {
        approvalLink: '',
        basket_data: [],
        contact_address: {},
        invoice_data: {},
        shipping_address: {},
        shipping_cost: 0,
        total: 0,
        order_number: '',
        payment_method: '',
        order_date: '',
        payment_status: this.lang.new
    }

    render() {
        return (
            <div id='main'>

                <div className='main-header background' style={{ background: 'linear-gradient(rgba(28, 28, 28, 0.3), rgba(28, 28, 28, 0.3)), url("/themes/default/img/backgrounds/background-about.jpg")' }}>
                    <div className="container">
                        <h1 className="main-header-title text-center block">
                            <span>{this.lang.order_summary_title}</span>
                        </h1>
                    </div>
                </div>

                <div id='breadcrumb'>
                    <div className='container'>
                        <ol className='breadcrumb'>
                            <li><Link to='/'>{this.lang.home}</Link></li>
                            <li className='active'><span>{this.lang.checkout_title}</span></li>
                        </ol>
                    </div>
                </div>

                <div className='checkout-page'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <article>
                                    <p>{this.lang.received_your_order}.</p>
                                    <div className='woocommerce'>
                                        <ul className='order_details'>
                                            <li>
                                                {this.lang.order_number}:
                                                <strong>{this.state.order_number}</strong>
                                            </li>
                                            <li>
                                                {this.lang.date}:
                                                <strong>{this.state.order_date}</strong>
                                            </li>
                                            <li>
                                                {this.lang.email}:
                                                <strong>{this.state.contact_address.email}</strong>
                                            </li>
                                            <li>
                                                {this.lang.total}:
                                                <strong>
                                                    <span>{Helper.currencyFormatter().format(this.state.total)}</span>
                                                </strong>
                                            </li>
                                            <li>
                                                {this.lang.payment_method}:
                                                <strong>
                                                    <span>{(this.state.payment_method === 'standard' || this.state.payment_method === 'traditional') ? this.lang.bank_transfer : this.lang.cash_on_delivery}</span>
                                                </strong>
                                            </li>
                                            <li>
                                                {this.lang.payment_status}:
                                                <strong>
                                                    <span>{this.state.payment_status}</span>
                                                </strong>
                                            </li>
                                        </ul>
                                        {this.state.payment_method === 'traditional' && <BankTransferDetails />}
                                        <section className='woocommerce-order-details'>
                                            <h2>{this.lang.order_details}</h2>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope='col'>{this.lang.product_name}</th>
                                                        <th scope='col'>{this.lang.quantity}</th>
                                                        <th scope='col'>{this.lang.price}</th>
                                                        <th scope='col'>{this.lang.value}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.basket_data.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{item.name}</td>
                                                                <td><span className='pull-right'>{item.quantity}</span></td>
                                                                <td><span className='pull-right'>{Helper.currencyFormatter().format(item.price)}</span></td>
                                                                <td><span className='pull-right'>{Helper.currencyFormatter().format(item.quantity * item.price)}</span></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="3">
                                                            <span className='bold pull-right'>{this.lang.summary}</span>
                                                        </td>
                                                        <td colSpan="1">
                                                            <span className='bold pull-right'>
                                                                {Helper.currencyFormatter().format(this.state.total - this.state.shipping_cost)}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="3">
                                                            <span className='bold pull-right'>{`${this.lang.shipment} - ${this.lang.courier}`}</span>
                                                        </td>
                                                        <td colSpan="1">
                                                            <span className='bold pull-right'>
                                                                {Helper.currencyFormatter().format(this.state.shipping_cost)}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="3"><span className='bold pull-right'>{this.lang.payment_method}</span></td>
                                                        <td colSpan="1"><span className='bold pull-right'>{this.state.payment_method === 'standard' ? this.lang.bank_transfer : this.lang.cash_on_delivery}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan="3"><span className='bold pull-right'>{this.lang.total}</span></th>
                                                        <td colSpan="1">
                                                            <span className='bold pull-right'>
                                                                {Helper.currencyFormatter().format(this.state.total)}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </section>
                                        <section className='woocommerce-customer-details'>
                                            <section className='col2-set'>
                                                <div className='col-1'>
                                                    <h2>{this.lang.contact_address}</h2>
                                                    <address>
                                                        {`${this.state.contact_address.first_name} ${this.state.contact_address.last_name}`}
                                                        <br/>
                                                        { this.state.contact_address.firm && 
                                                            <Fragment>
                                                                {`${this.state.contact_address.firm}`}
                                                                <br/>
                                                            </Fragment>
                                                        }
                                                        { this.state.contact_address.nip && 
                                                            <Fragment>
                                                                {`${this.state.contact_address.nip}`}
                                                                <br/>
                                                            </Fragment>
                                                        }
                                                        {`${this.state.contact_address.country}`}
                                                        <br/>
                                                        {`${this.state.contact_address.post_code} ${this.state.contact_address.city}`}
                                                        <br/>
                                                        {`${this.state.contact_address.street}`}
                                                        <p className="woocommerce-customer-details--phone">{`${this.state.contact_address.phone_number}`}</p>
                                                        <p className="woocommerce-customer-details--email">{`${this.state.contact_address.email}`}</p>
                                                    </address>
                                                </div>
                                                <div className='col-2'>
                                                    <h2>{this.lang.invoice_address}</h2>
                                                    <address>
                                                        {`${this.state.invoice_data.first_name} ${this.state.invoice_data.last_name}`}
                                                        <br/>
                                                        {this.state.invoice_data.firm && 
                                                            <Fragment>
                                                                {`${this.state.invoice_data.firm}`}
                                                                <br/>
                                                            </Fragment>
                                                        }
                                                        {this.state.invoice_data.nip && 
                                                            <Fragment>
                                                                {`${this.state.invoice_data.nip}`}
                                                                <br/>
                                                            </Fragment>
                                                        }
                                                        {`${this.state.invoice_data.country}`}
                                                        <br/>
                                                        {`${this.state.invoice_data.post_code} ${this.state.invoice_data.city}`}
                                                        <br/>
                                                        {`${this.state.invoice_data.street}`}
                                                        <p className="woocommerce-customer-details--phone">{`${this.state.invoice_data.phone_number}`}</p>
                                                        <p className="woocommerce-customer-details--email">{`${this.state.invoice_data.email}`}</p>
                                                    </address>
                                                </div>
                                                <div className='col-1'>
                                                    <h2>{this.lang.shipping_address}</h2>
                                                    <address>
                                                        {`${this.state.shipping_address.first_name} ${this.state.shipping_address.last_name}`}
                                                        <br/>
                                                        {this.state.shipping_address.firm &&
                                                            <Fragment>
                                                                {`${this.state.shipping_address.firm}`}
                                                                <br/>
                                                            </Fragment>
                                                        }
                                                        {this.state.shipping_address.nip &&
                                                            <Fragment>
                                                                {`${this.state.shipping_address.nip}`}
                                                                <br/>
                                                            </Fragment>
                                                        }
                                                        {`${this.state.shipping_address.country}`}
                                                        <br/>
                                                        {`${this.state.shipping_address.post_code} ${this.state.shipping_address.city}`}
                                                        <br/>
                                                        {`${this.state.shipping_address.street}`}
                                                        <p className="woocommerce-customer-details--phone">{`${this.state.shipping_address.phone_number}`}</p>
                                                        <p className="woocommerce-customer-details--email">{`${this.state.shipping_address.email}`}</p>
                                                    </address>
                                                </div>
                                            </section>
                                        </section>
                                    </div>
                                </article>
                            </div>
                            <div className='col-md-12'>
                                <div className='order-summary-btn-container'>
                                    <Link to={`${this.props.moduleUrl}`} className='btn btn-lg btn-primary' type='button' >{this.lang.shop}</Link>
                                    {global.user_token && <Link to={`/${this.lang.slug_user_account}`} className='btn btn-lg btn-primary' type='button' >{this.lang.customer_panel}</Link>}
                                </div>    
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount = () => {
        let query = queryString.parse(this.props.location.search);
        // Sprawdzamy czy w zapytaniu url znajdują się określone parametry
        if ('gateway' in query && 'paymentId' in query) {
            this.getPaymentStatus(query.gateway, query.paymentId);
        }
        this.setSummaryData();
    }

    /**
     * Ustawia podsumowanie zamówienia
     * 
     * @return {undefined}
     */
    setSummaryData = () => {
        if (localStorage.getItem('shop.order.summary') !== null) {
            let order_summary = JSON.parse(localStorage.getItem('shop.order.summary'));
            this.setState({ ...order_summary })
            localStorage.removeItem('shop.order.summary')
        } else {
            this.props.history.push('/');
        }
    }

    /**
     * Pobiera status płatności z płatności
     * 
     * @param {String} gateway - Bramka płatności
     * @param {String} payment_id - Identyfikator płatności
     * @return {undefined} 
     */
    getPaymentStatus(gateway, payment_id) {

        if (MAX_REQUESTS <= this.requests_number) {
            if (this.set_interval) {
                clearInterval(this.set_interval);
            }
            return;
        }

        API.get({ url: `/api/payment/${gateway}/${payment_id}` }).then(res => {
            if (this.set_interval) {
                clearInterval(this.set_interval);
            }

            if (!res.error) {
                this.setState({
                    payment_status: res.data.message
                });
            }

            this.set_interval = setInterval(() => {
                this.getPaymentStatus(gateway, payment_id);
            }, 5000);
            
            this.requests_number++
            console.log('this.requests_number', this.requests_number);
        });
    }

    componentWillUnmount() {
        // W momencie odmontowania komponentu usuwamy cykliczne wywoływanie funkcji
        if (this.set_interval) {
            clearInterval(this.set_interval);
        }
    }

}

export default OrderSummary;