export default {
    cookie_info: 'We use cookies and other similar technologies to recognise your browser or device, and provide you product recommendations or display you personalised content, suitable for your needs. Cookies may also be used by approved third parties which collaborate with us like research companies or app providers. You may always change your cookies settings in your web browser. Using our services without changing your cookies settings will result in storing them in device memory.',
    back_top: 'Back to top',
    shop: 'shop',
    search_title: 'Search',
    enter_searching_product_title: 'Search your entry here...',
    info: 'info',
    about_us_title: 'About us',
    policies_title: 'Policies',
    contact_title: 'Contact',
    shopping: 'shopping',
    how_to_buy_title: 'How to buy',
    footwear_how_choose_size_title: 'Footwear - how to choose the size?',
    clothing_how_choose_size_title: 'Clothing - how to choose the size?',
    customer_service: 'customer service',
    delivery_title: 'Delivery',
    returns_and_replacements_title: 'Returns & replacements',
    payment_methods_title: 'Payment methods',
    payment_accept: 'payment accept',
    like_us: 'like us',
    page: 'page',
    slug_about_us: 'about-us',
    slug_policies: 'policies',
    slug_contact: 'contact',
    slug_how_to_buy: 'how-to-buy',
    slug_footwear_how_choose_size: 'footwear-how-choose-size',
    slug_clothing_how_choose_size: 'clothing-how-choose-size',
    slug_delivery: 'delivery',
    slug_payment_methods: 'payment-methods',
    slug_faq: 'faq',
    slug_returns_and_replacements: 'returns-and-replacements',
    slug: {
        shop: 'sklep',
        page: 'strona',
        slug_about_us: 'o-nas',
        slug_policies: 'warunki-umowy',
        slug_contact: 'kontakt',
        slug_how_to_buy: 'jak-kupowac',
        slug_footwear_how_choose_size: 'obuwie-jak-dobrac-rozmiar',
        slug_clothing_how_choose_size: 'odziez-jak-dobrac-rozmiar',
        slug_delivery: 'dostawa',
        slug_returns_and_replacements: 'zwroty-i-reklamacje',
        slug_payment_methods: 'metody-platnosci',
        slug_faq: 'faq',
        shipping_costs: 'koszty-wysylki',
        payment_methods: 'sposoby-platnosci',
        damaged_goods: 'uszkodzony-towar',
        i_want_to_exchange_the_goods: 'chce-wymienic-towar',
        refund_or_complaint: 'zwracam-lub-reklamuje'
    },
    privacy_policy: 'Privacy policy',
    shipping_costs: 'Shipping costs',
    payment_methods: 'Payment methods',
    package_received: 'Package received',
    damaged_goods: 'Damaged goods',
    i_want_to_exchange_the_goods: 'Returns',
    refund_or_complaint: 'Refund or complaint',
    quick_payments: 'Quick payments'
}