export default {
    shop: 'sklep',
    page: 'strona',
    terms_and_conditions: 'regulamin',
    terms_and_conditions_slug: 'regulamin',
    slug_delivery: 'dostawa',
    delivery: 'Dostawa',
    slug_payment_methods: 'metody-platnosci',
    payment_methods: 'Metody platnosci',
    category: 'kategoria',
    slug: {
        shop: 'sklep',
        page: 'strona',
        slug_delivery: 'dostawa',
        terms_and_conditions_slug: 'regulamin',
        slug_payment_methods: 'metody-platnosci',
        category: 'kategoria',
        shop: 'sklep'
    },
    more: 'Więcej...',
    product_categories: 'Kategorie produktów'
}