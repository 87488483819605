import React from 'react';

class ServiceComments extends React.PureComponent {
    render() {
        return (
            <section className="woocommerce-order-details">

                <h2 className="woocommerce-order-details__title">Formularz komentarzy</h2>

                <form >
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='reply-name'>Name <sup>*</sup></label>
                                <input type='text' className='form-control' id='reply-name' placeholder='Name' />
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='reply-email'>Email <sup>*</sup></label>
                                <input type='email' className='form-control' id='reply-email' placeholder='Email' />
                            </div>
                        </div>
                    </div>

                    <div className='form-group'>
                        <label htmlFor='reply-title'>Title <sup>*</sup></label>
                        <input type='text' className='form-control' id='reply-title' placeholder='title' />
                    </div>

                    <div className='form-group'>
                        <label htmlFor='reply-text'>Your review <sup>*</sup></label>
                        <textarea name='reply-text' className='form-control' id='reply-text' rows='7' placeholder='Your review'></textarea>
                    </div>

                    <div className='form-submit clearfix'>
                        <div className='review-rating'>
                            <span className='title'>Your rating:</span>

                            <span className='rating small live'>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>

                        </div>

                        <div className='pull-right'>
                            <button type='submit' className='submit btn btn-lg btn-default'>Submit</button>
                        </div>
                    </div>
                </form>
            </section>
        )
    }
}

export default ServiceComments;