import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Lang from '../lang/';
import Loader from 'partials/loader';
import Breadcrumbs from 'partials/breadcrumbs';
import Image from 'partials/image';
import Helper from 'helpers';
import WebPush from './web-push-notifications';
import API from 'boost/API';
import { ProductView } from 'grips';
import Cookie from 'assets/js/cookie-client/collect-cookie';
import { Link } from 'react-router-dom';
import { MAX_QTY } from '../cart';

class Product extends React.Component {

    renderGrips(area) {
        return (
            ProductView[area].map((Controller, key) => {
                return (
                    <React.Fragment key={key}>
                        {
                            !!Controller.innerRender
                                ?
                                <Controller.innerRender {...this.props.signpost} />
                                :
                                <Controller {...this.props.signpost} />
                        }
                    </React.Fragment>
                );
            })
        )
    }

    state = {
        slug: this.props.match.params.slug || '',
        variation_id: this.props.match.params.variation_id || 0,
        url: this.props.match.url,
        product: false,
        product_comments: [],
        count_to_add: 1,
        variation: {},
        loader: false
    }

    lang = Lang[global.language];
    sMain = {}
    sThumb = {}
    _isMounted = false;

    render() {
        let { product, variation, variation_id, url } = this.state;
        let additional_information = this.additionalInformation(product.variations);
        let rows_additional_information = this.rowsAdditionalInformation(additional_information);

        return (
            !this.state.loader ?
                <React.Fragment>
                    {product ?
                        <React.Fragment>

                            <div className='main-header background' style={{ background: 'linear-gradient(rgba(28, 28, 28, 0.3), rgba(28, 28, 28, 0.3)), url("/themes/default/img/backgrounds/background-product-grid.jpg")' }}>
                                <div className="container">
                                    <h1 className="main-header-title text-center block">
                                        <span>{product.category}</span>
                                    </h1>
                                </div>
                            </div>

                            <Breadcrumbs sites={[{ name: this.lang.products_list_title, to: `/${this.lang.shop}` }, { name: product.name }]} />

                            <div className='container'>

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='product-slider-wrapper thumbs-bottom'>

                                            <div className='swiper-container product-slider-main'>
                                                {
                                                    variation.images.length ?
                                                        <div className='swiper-wrapper'>
                                                            {
                                                                variation.images.map((image, key) => {
                                                                    let src = `${image.path}${`1024x800`}${image.name.substring(image.name.indexOf('.'), image.name.length)}`;
                                                                    return (
                                                                        <div className='swiper-slide' key={key}>
                                                                            <Link to={`/${this.lang.shop}/${this.lang.slug_image_preview}/${btoa(src)}`} className="woocommerce-product-gallery__trigger">
                                                                                <img draggable="false" className="emoji" alt="🔍" src="/themes/default/img/icon-svg/1f50d.svg" />
                                                                            </Link>
                                                                            <figure className='zoom' onMouseMove={this.zoom} onTouchMove={this.zoom} style={{ backgroundImage: `url(${src})` }}>
                                                                                {variation.selling_price !== variation.default_price && <div className='product-promotion'>{this.lang.promotion}</div>}
                                                                                <img src={src} alt={image.alt} />
                                                                            </figure>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        <div className='swiper-wrapper'>
                                                            <div className='swiper-slide'>
                                                                <Link
                                                                    to={`/${this.lang.shop}/${this.lang.slug_image_preview}/${btoa(`/themes/default/img/samples/products/product/large/product-1.png`)}`}
                                                                    className="woocommerce-product-gallery__trigger"
                                                                >
                                                                    <img draggable="false" className="emoji" alt="🔍" src="/themes/default/img/icon-svg/1f50d.svg" />
                                                                </Link>
                                                                <figure className='zoom' onMouseMove={this.zoom} onTouchMove={this.zoom} style={{ backgroundImage: 'url(/themes/default/img/samples/products/product/large/product-1.png)' }}>
                                                                    {variation.selling_price !== variation.default_price && <div className='product-promotion'>{this.lang.promotion}</div>}
                                                                    <img src='/themes/default/img/samples/products/product/large/product-1.png' alt='' />
                                                                </figure>
                                                            </div>
                                                        </div>
                                                }
                                                {/* <div className='swiper-button-prev'><i className='fa fa-chevron-left'></i></div>
                                            <div className='swiper-button-next'><i className='fa fa-chevron-right'></i></div> */}
                                            </div>

                                            <div className='swiper-container product-slider-thumbs'>
                                                {
                                                    variation.images.length ?
                                                        <div className='swiper-wrapper'>
                                                            {
                                                                variation.images.map((image, key) =>
                                                                    <div className='swiper-slide' key={key}>
                                                                        <img src={image.thumb} alt='' />
                                                                    </div>
                                                                )
                                                            }

                                                        </div>
                                                        :
                                                        <div className='swiper-wrapper'>
                                                            <div className='swiper-slide'>
                                                                <img src='/themes/default/img/samples/products/product/large/product-1.png' alt='' />
                                                            </div>
                                                        </div>
                                                }
                                            </div>

                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='product-details-wrapper'>
                                            <h2 className='product-name'>
                                                {this.state.product.name}
                                            </h2>

                                            <div className='product-status'>
                                                {!!this.state.product.oem 
                                                    && <span>
                                                        {`${this.lang.producer_nr}: ${this.state.product.oem}`}
                                                    </span>
                                                }
                                                <br />
                                                <span>{this.lang.product} {variation.quantity > 0 ? this.lang.available : this.lang.unavailable}</span>
                                            </div>

                                            <div className='product-stars'>
                                                <span className='rating'>
                                                    <span className='star'></span>
                                                    <span className='star'></span>
                                                    <span className='star'></span>
                                                    <span className=''></span>
                                                    <span className=''></span>
                                                </span>
                                            </div>

                                            {/* <div className='product-features'>
                                                <h3>{this.lang.product_description_title}:</h3>
                                                <div className='wrap' dangerouslySetInnerHTML={{ __html: product.description }} />
                                            </div> */}

                                            <div className='product-actions-wrapper'>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className='form-group'>
                                                            <label htmlFor='p_size'>{this.lang.variants_title}</label>
                                                            <select
                                                                name='p_size'
                                                                id='p_size'
                                                                className='form-control'
                                                                style={{ width: 'auto' }}
                                                                defaultValue={variation_id}
                                                                onChange={this.changeVaration}
                                                            >
                                                                {
                                                                    product.variations.map((variation, key) =>
                                                                        <option value={variation.id} key={key} > {variation.attributes_list} </option>
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>


                                                </div>
                                                {
                                                    variation.quantity > 0 ?
                                                        <div className="input-number">
                                                            <input type="number" className="input-text qty text" step="1" min="1" max={MAX_QTY} value={this.state.count_to_add} onChange={this.handleInputCount} onFocus={this.handleOnFocus} onBlur={this.handleOnBlur} />
                                                            <button className="number-act number-inc" onClick={() => this.updateCount(1)}><i className="fa fa-plus"></i></button>
                                                            <button className="number-act number-dec" onClick={() => this.updateCount(-1)}><i className="fa fa-minus"></i></button>
                                                        </div>
                                                        : null
                                                }

                                                <p className='price'>
                                                    {this.productPrice(variation)}
                                                </p>

                                                {
                                                    variation.quantity > 0 ?
                                                        <button className='btn btn-lg btn-primary margin-right-15' onClick={this.addToCart}>{this.lang.add_to_cart}</button>
                                                        : null
                                                }
                                                {this.props.wishList !== null && typeof this.props.wishList.find(item => item.id == product.id) !== 'undefined' 
                                                    ? 
                                                    <Link 
                                                        to={`/${this.lang.slug.shop}/${this.lang.slug.wish_list}`} 
                                                        className="Awe_add_to_wishlist awe-button btn btn-lg btn-dark btn-outline" 
                                                    >
                                                        <i className="icon icon-star"></i>
                                                        <span> {this.lang.view_wishlist}</span>
                                                    </Link>
                                                    :
                                                    <a 
                                                        className="Awe_add_to_wishlist awe-button btn btn-lg btn-dark btn-outline" 
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.addToWhishList();
                                                            return false;
                                                        }}
                                                    >
                                                        <i className="icon icon-star"></i>
                                                        <span> {this.lang.add_to_whishlist}</span>
                                                    </a>
                                                }                                                    
                                            </div>

                                            <WebPush variations={product.variations} />

                                            <div className='product-meta'>
                                                <span className='product-category'>
                                                    <span>{this.lang.category_title}: </span>
                                                    <Link to={`${this.props.moduleUrl}/${this.lang.slug.category}/${product.category_slug}`} title={product.category}>{product.category}</Link>
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='product-socials'>
                                    <ul className='list-socials'>

                                        <li><a href='#' data-toggle='tooltip' title='Twitter'><i className='icon icon-twitter'></i></a></li>
                                        <li><a href='#' data-toggle='tooltip' title='Facebook'><i className='icon icon-facebook'></i></a></li>
                                        {/* <li><a href='#' data-toggle='tooltip' title='Dot-Dot'><i className='icon icon-dot-dot'></i></a></li> */}
                                        <li><a href='#' data-toggle='tooltip' title='Google+'><i className='icon icon-google-plus'></i></a></li>
                                        <li><a href='#' data-toggle='tooltip' title='Pinterest'><i className='icon icon-pinterest'></i></a></li>

                                    </ul>
                                </div>



                                <div className='product-details-left'>
                                    <div role='tabpanel' className='product-details'>
                                        <nav>
                                            <ul className="nav" role="tablist">
                                                <li className="description_tab active">
                                                    <a data-toggle="tab" href="#tab-description" aria-expanded="false">{this.lang.description}</a>
                                                </li>
                                                <li className="brand_models">
                                                    <a data-toggle="tab" href="#brand-models" aria-expanded="false">{this.lang.fits_to}</a>
                                                </li>
                                                <li className="additional_information_tab ">
                                                    <a data-toggle="tab" href="#tab-additional_information">{this.lang.delivery_and_cost}</a>
                                                </li>
                                                {!!product.resources.length &&
                                                <li className="resources_tab">
                                                    <a data-toggle="tab" href="#tab-resources" aria-expanded="true">{this.lang.resources}</a>
                                                </li>}
                                                <li className="reviews_tab">
                                                    <a data-toggle="tab" href="#tab-reviews" aria-expanded="true">{this.lang.reviews} ({!!this.state.product_comments ? this.state.product_comments.length : 0})</a>
                                                </li>
                                                <li className="producer_preview_tab">
                                                    <a data-toggle="tab" href="#producer-preview" aria-expanded="true">{this.lang.producer}</a>
                                                </li>
                                                <li className="quality_preview_tab">
                                                    <a data-toggle="tab" href="#quality-preview" aria-expanded="true">{this.lang.quality}</a>
                                                </li>
                                            </ul>
                                        </nav>
                                        <div className='tab-content'>

                                            <div className="tab-pane active" id="tab-description">
                                                <h2>{this.lang.description}</h2>
                                                <div className='wrap' dangerouslySetInnerHTML={{ __html: product.description }} />
                                            </div>

                                            <div className="tab-pane" id="brand-models">
                                                <h2>{this.lang.fits_to}</h2>
                                                {!!variation.brand_models && !!variation.brand_models.length 
                                                    ?
                                                    variation.brand_models.map((item, key) => 
                                                        <span key={key} style={{ display: 'block', margin: '5px 0' }}>
                                                            {`${item.brand_name} \\ ${item.name}`}
                                                        </span>
                                                    ) 
                                                    : this.lang.no_matching_models_given
                                                }
                                            </div>

                                            <div className="tab-pane" id="tab-additional_information">
                                                <div className='row'>
                                                    <div className='col-sm-12'>
                                                        {/* <h2>{this.lang.additional_information}</h2> */}
                                                        <table className="shop_attributes">
                                                            <tbody>
                                                                {rows_additional_information}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='col-sm-12'>
                                                        <section className='table width-100'>
                                                            <h4>{this.lang.delivery_cost}</h4>
                                                            <div className='table-row'>
                                                                {
                                                                    !!product.delivery_cost ? (
                                                                        <>
                                                                            <div className='table-cell width-25'>
                                                                                <strong>{`${this.lang.custom_delivery_cost}:`}</strong>
                                                                            </div>
                                                                            <div className='table-cell width-25'>
                                                                                {product.delivery_cost ? `${product.delivery_cost} zł` : `${this.lang.not_applicable}`}
                                                                            </div>
                                                                            <div className='table-cell width-25'>
                                                                                <strong>{`${this.lang.package_quantity}:`}</strong>
                                                                            </div>
                                                                            <div className='table-cell width-25'>
                                                                                {product.package_quantity ? `${product.package_quantity}` : `${this.lang.not_applicable}`}
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div className='table-cell width-100'>
                                                                            {`${this.lang.delivery_costs_as_in}`}<b></b><Link to={{ 
                                                                                pathname: `/${this.lang.slug.page}/${this.lang.slug.shipping_costs}`, 
                                                                                prevUrl: url 
                                                                            }}> {`${this.lang.price_list}`}</Link>.
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </section>
                                                        <section className='table width-100'>
                                                            <h4>{this.lang.dimensions}</h4>
                                                            <div className='table-row'>
                                                                <div className='table-cell width-25'>
                                                                    <strong>{`${this.lang.length}:`}</strong>
                                                                </div>
                                                                <div className='table-cell width-25'>
                                                                    {product.length ? `${product.length} cm`: `${this.lang.not_added}`}
                                                                </div>
                                                                <div className='table-cell width-25'>
                                                                    <strong>{`${this.lang.width}:`}</strong>
                                                                </div>
                                                                <div className='table-cell width-25'>
                                                                    {product.width ? `${product.width} cm` : `${this.lang.not_added}`}
                                                                </div>
                                                            </div>
                                                            <div className='table-row'>
                                                                <div className='table-cell width-25'>
                                                                    <strong>{`${this.lang.height}:`}</strong>
                                                                </div>
                                                                <div className='table-cell width-25'>
                                                                    {product.height ? `${product.height} cm` : `${this.lang.not_added}`}
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='tab-pane' id='tab-resources'>
                                                <ul className='list-group'>
                                                    {
                                                        product.resources.map(resource => (
                                                            <li className='list-group-item' key={resource.id}>
                                                                <h5>{resource.name}</h5>
                                                                <p>{resource.description}</p>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                                         
                                            <div className='tab-pane' id='tab-reviews'>
                                                <div id='reviews'>
                                                    <div id='comments'>
                                                        <h2>{this.lang.reviews}</h2>
                                                        {
                                                            !!this.state.product_comments ?
                                                                <React.Fragment>
                                                                    <ol className='commentlist'>
                                                                        {
                                                                            this.state.product_comments.map((comment, key) =>
                                                                                <li className='review' key={key}>
                                                                                    <h4 className='review-title'>{comment.nick}</h4>
                                                                                    <div className='rating small'>
                                                                                        <span className={`${comment.rating > 0 ? 'star' : ''}`}></span>
                                                                                        <span className={`${comment.rating > 1 ? 'star' : ''}`}></span>
                                                                                        <span className={`${comment.rating > 2 ? 'star' : ''}`}></span>
                                                                                        <span className={`${comment.rating > 3 ? 'star' : ''}`}></span>
                                                                                        <span className={`${comment.rating > 4 ? 'star' : ''}`}></span>
                                                                                    </div>

                                                                                    <div className='review-comment'>
                                                                                        <p>
                                                                                            {comment.content}
                                                                                        </p>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    </ol>
                                                                </React.Fragment>
                                                                : <p className='woocommerce-noreviews'>{this.lang.no_opinion_about_product_yet}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="producer-preview">
                                                <div className='wrap'>
                                                    <h5>{this.lang.producer_name}</h5>
                                                    {`${product.producer_name || this.lang.not_added}`}
                                                </div>
                                                <div className='wrap'>
                                                    <h5>{this.lang.country_origin}</h5>
                                                    {`${product.producer_region || this.lang.not_added}`}
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="quality-preview">
                                                <div className='wrap'>
                                                    {`${product.quality_text || this.lang.not_added}`}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {this.renderGrips(0)}

                            </div>
                        </React.Fragment>
                        :
                        <div className='preloader'>
                            <div className='preloader-wrapper'>
                                <Loader type='small' />
                            </div>
                        </div>
                    }
                </React.Fragment>
                :
                <div className='preloader'>
                    <div className='preloader-wrapper'>
                        <Loader type='small' />
                    </div>
                </div>
        )
    }

    zoom = (e) => {
        let zoomer = e.currentTarget;
        let offsetX = e.nativeEvent.offsetX ? e.nativeEvent.offsetX : e.nativeEvent.touches[0].pageX;
        let offsetY = e.nativeEvent.offsetY ? e.nativeEvent.offsetY : e.nativeEvent.touches[0].pageY;
        let x = offsetX / zoomer.offsetWidth * 100;
        let y = offsetY / zoomer.offsetHeight * 100;
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
    }

    /**
     * Generuje widok ceny produktu
     * 
     * @param {Object} variation - Wariant
     * @return {Object}
     */
    productPrice(variation) {
        return (
            variation.selling_price !== variation.default_price
                ?
                <React.Fragment>
                    <del>
                        <span className='amount'>
                            {Helper.currencyFormatter().format(variation.default_price)}
                        </span>
                    </del>
                    {` `}
                    <ins>
                        <span className='amount'>
                            {`${Helper.currencyFormatter().format(variation.selling_price)}`}
                        </span>
                    </ins>
                </React.Fragment>
                :
                <span className='amount'>
                    {`${Helper.currencyFormatter().format(variation.selling_price)}`}
                </span>
        );
    }

    /**
     * Tworzy obiekt dodatkowych informacji produktu
     * 
     * @param {Array} variations - Warianty
     * @return {Object}
     */
    additionalInformation(variations) {
        let attr = {};
        if (typeof variations !== 'undefined') {
            variations.map(variation => {
                variation.attributes.map(attribute => {
                    if (attribute.attribute_name in attr) {
                        attr[attribute.attribute_name] = [...attr[attribute.attribute_name], attribute.attribute_value_value];
                    } else {
                        attr[attribute.attribute_name] = [attribute.attribute_value_value];
                    }
                    attr[attribute.attribute_name] = [...new Set(attr[attribute.attribute_name])];
                })
            });
        }
        return attr;
    }

    /**
     * Renderuje wiersze tabeli dodatkowych informacji
     * 
     * @param {Object} additional_information - Obiekt dodatkowych informacji produktu
     * @return {Object}
     */
    rowsAdditionalInformation(additional_information) {
        let rows = [];
        let key = 0;
        for (let property in additional_information) {
            if (additional_information.hasOwnProperty(property)) {
                rows.push(
                    <tr key={key++}>
                        <th>{property}</th>
                        <td >{additional_information[property].join()}</td>
                    </tr>
                );
            }
        }
        return rows;
    }

    /**
     * Przechwytuje zdarzenie dezaktuwacji pola wprowadzania ilości produktów
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    handleOnBlur = (e) => {
        let count_to_add = parseInt(e.target.value, 10);
        if (isNaN(count_to_add) || count_to_add <= 0) {
            this.setState({ count_to_add: 1 });
        }
    }

    /**
     * Przechwytuje zdarzenie aktywacji pola wprowadzania ilości produktów
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    handleOnFocus = (e) => {
        let count_to_add = parseInt(e.target.value, 10);
        if (isNaN(count_to_add) || count_to_add === 0) {
            this.setState({ count_to_add: '' });
        }
    }

    /**
     * Przechwytuje zdarzenie zmiany wartości pola wprowadzania ilości produktów
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    handleInputCount = (e) => {
        let count_to_add = e.target.value;

        if (count_to_add > MAX_QTY) {
            return;
        }

        if (count_to_add <= 0) {
            count_to_add = '';
        }

        // Jeżeli wartość jest większa od 0 (string) rzutujemy ją na number 
        // aby przy inkrementacj i dekrementacji (przyciski +/-) nie łączyło string z integer
        if (parseInt(count_to_add, 10) > 0) {
            count_to_add = parseInt(count_to_add, 10);
        }

        if (count_to_add > this.state.variation.quantity) {
            count_to_add = this.state.variation.quantity
        }

        this.setState({ count_to_add });
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.load({ url: `/api/shop/products-by-slug/${this.state.slug}` }).then(res => {
            if (!res.error) {
                this.props.updateValue({ state: 'product_view', data: res.data })
                this.loadData(res);
            } else if (res.status && res.status === 404) {
                this.props.history.push({
                    pathname: '/404',
                    state: {
                        pathname: this.props.match.url
                    }
                })
            }
        });
    }

    componentDidUpdate() {
        this.setVariationIdInUrl();
    }

    /**
     * Ustawia id wariantu w url
     * 
     * @return {undefined}
     */
    setVariationIdInUrl() {
        let { variation } = this.state;
        if (!(Object.keys(variation).length === 0)) {
            let slug = this.variationToVariantUrl(variation);
            this.setUrl(slug);
        }
    }

    loadData(data) {
        this.setState({ product: data.data, variation: this.currentVariation(data.data.variations) }, this.initSwiper);
        this.props.load({ url: `api/comments/comment/product/${data.data.id}` }).then(res => {
            if (this._isMounted) {
                this.setState({ product_comments: res.data });
            }
        });

        this.sendCookie(data.data.id);
    }

    sendCookie = (id) => {
        let cookie = {
            "product_id": id,
            "url": `${this.props.moduleUrl}/${this.state.slug}`
        };
        Cookie.collect(cookie);
    }

    /**
     * Zwraca obiekt wariantu na podstawie jego identyfikatora z url
     * 
     * @param {Array} variations - Tablica wariantów produktu
     * @return {Object}
     */
    currentVariation(variations) {
        let variation_id = this.state.variation_id;
        let variation = variations[0];

        if (variation_id !== 0) {
            let find_variation = variations.find(item => variation_id == item.id);
            if (typeof find_variation !== 'undefined') {
                return find_variation;
            }
        }

        return variation;
    }

    initSwiper() {
        this.sMain = new Swiper('.product-slider-main');

        this.sThumb = new Swiper('.product-slider-thumbs', {
            spaceBetween: 15,
            slidesPerView: 4,
            touchRatio: 0.2,
            slideToClickedSlide: true,
            centeredSlides: true,
            virtualTranslate: true,
            on: {
                transitionStart: function () {
                    let translate = this.getTranslate();
                    let slidesPerView = this.params.slidesPerView == 'auto ' ? this.slidesPerViewDynamic() : this.params.slidesPerView;
                    if (this.slides.length <= slidesPerView) {
                        return;
                    }

                    var y = 0;
                    var z = 0;
                    var x = 0;

                    if (this.activeIndex > slidesPerView / 2) {
                        translate = this.activeIndex == this.slides.length - 1 ? -this.snapGrid[this.snapGrid.length - 2] : this.translate;

                        if (this.isHorizontal()) {
                            x = this.params.rtl ? -translate : translate;
                        } else {
                            y = translate;
                        }

                        if (this.roundLengths) {
                            x = Math.floor(x);
                            y = Math.floor(y);
                        }
                    }

                    if (this.support.transforms3d) {
                        this.$wrapperEl.transform(("translate3d(" + x + "px, " + y + "px, " + z + "px)"));
                    } else {
                        this.$wrapperEl.transform(("translate(" + x + "px, " + y + "px)"));
                    }
                }
            }
        });

        this.sMain.controller.control = this.sThumb;
        this.sThumb.controller.control = this.sMain;
    }
    updateCount(value) {
        let { count_to_add } = this.state;
        count_to_add += value;

        if (count_to_add > MAX_QTY) {
            return;
        }

        if (count_to_add <= 0) {
            count_to_add = 1;
        }
        if (count_to_add > this.state.variation.quantity) {
            count_to_add = this.state.variation.quantity
        }
        this.setState({ count_to_add });
    }

    addToCart = () => {
        let variation = this.state.variation;
        let count_to_add = this.state.count_to_add;
        this.props.addCart(variation, { update_count: count_to_add });
        this.setState({ count_to_add: 1 });
        let cookie = {
            "product_id": variation.product_id,
            "quantity": count_to_add
        };
        Cookie.collect(cookie, 'add2cart');
    }

    addToWhishList = () => {
        let { product, variation } = this.state;
        product = { ...product, ...{ currentVariation: variation } };
        this.props.addWishList(product);
    }

    /**
     * Wyświetla lub ukrywa loader
     * 
     * @return {undefined}
     */
    showLoader() {
        this.setState({ loader: !this.state.loader });
    }

    changeVaration = (e) => {
        let id = e.target.value;
        let variation = this.state.product.variations.find(variation => variation.id == id);
        this.setState({ variation });
        let slug = this.variationToVariantUrl(variation);
        this.setUrl(slug);
    }
    componentWillUnmount() {
        if ('destroy' in this.sThumb) {
            this.sThumb.destroy();
        }
        if ('destroy' in this.sMain) {
            this.sMain.destroy();
        }
        this._isMounted = false;
    }

    /**
     * Tworzy url na podstawie obiektu wariantu
     * 
     * @param {Object} variation - Wariant
     * @return {String}
     */
    variationToVariantUrl(variation) {
        return `${this.props.moduleUrl}/${variation.slug}/${variation.id}`;
    }

    /**
     * Ustawia url w pasku przeglądarki
     * 
     * @param { string } slug - Slug
     * @return { undefined }
     */
    setUrl(slug) {
        window.history.replaceState('', '', slug);
    }

}

const mapStateToProps = (state) => {
    return {
        loader: state.boost.loader,
        product: state.boost.product_view,
        addCart: state.boost['shop.widget.cart.add'],
        addWishList: state.boost['shop.widget.wishList.add'],
        wishList: state.boost[`shop.widget.wishList`]
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue,
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Product);