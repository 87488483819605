import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load } from 'boost/dispatch';
import Loader from 'partials/loader';
import ValidForm from 'helpers/valid-form'
import Lang from '../lang';
import Details from './details';
import OrderItems from './order-items';
import ReactTable from "react-table";
import Comments from './comments';
import "react-table/react-table.css";
import '../style.scss'

class ReturnDetailsComponent extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        id: this.props.match.params.id,
        order_no: '',
        order_id: 0,
        return_statuses: [],
        email: '',
        sku: '',
        product_name: '',
        price: 0,
        quantity: 0,
        data: [],
        pages: 10,
        loader: true
    }

    id_timeout = 0;

    render() {
        return(
            !this.state.loader 
                ?
                <React.Fragment>

                    <Details 
                        get={'get_reasons'}
                        order_no={this.state.order_no}
                        order_id={this.state.order_id}
                        email={this.state.email}
                    />

                    <OrderItems 
                        sku={this.state.sku}
                        product_name={this.state.product_name}
                        gross={parseFloat(this.state.price)}
                        quantity={this.state.quantity}
                    />

                    <div className='status-history'>
                        <h3 className='upper'>{this.lang.status_history}</h3>
                        <hr />
                        <ReactTable
                            columns={[
                                {
                                    Header: 'ID',
                                    accessor: 'id'
                                },
                                {
                                    Header: this.lang.complaint_status,
                                    id: 'return_status_id',
                                    filterMethod: (filter, row) => {
                                        if (filter.value === '') {
                                            return true;
                                        } else if (row[filter.id] == filter.value) {
                                            return true;
                                        }
                                    },
                                    Filter: ({ filter, onChange }) => (
                                        <select onChange={event => onChange(event.target.value)} style={{ width: '100%' }} value={filter ? filter.value : ''}>
                                            <option value=''>{this.lang.all}</option>
                                            {
                                                this.state.return_statuses.map(
                                                    (reason, key) => <option value={reason.value} key={key} >{reason.description}</option>
                                                )
                                            }
                                        </select>
                                    ),
                                    Cell: row => <span>{row.original.returnStatus.length > 0 ? row.original.returnStatus[0].status_name : '-'}</span>,
                                    maxWidth: 260
                                },
                                {
                                    Header: this.lang.customer_notify,
                                    id: 'notify',
                                    filterMethod: (filter, row) => {
                                        if (filter.value === '') {
                                            return true;
                                        } else if (row[filter.id] == filter.value) {
                                            return true;
                                        }
                                    },
                                    Filter: ({ filter, onChange }) => (
                                        <select onChange={event => onChange(event.target.value)} style={{ width: '100%' }} value={filter ? filter.value : ''}>
                                            <option value=''>{this.lang.all}</option>
                                            <option value='1'>{this.lang.yes}</option>
                                            <option value='0'>{this.lang.no}</option>
                                        </select>
                                    ),
                                    Cell: row => <span>{row.original.notify ? this.lang.yes : this.lang.no}</span>,
                                },
                                {
                                    Header: this.lang.return_created_at,
                                    accessor: 'created_at'
                                }
                            ]}
                            {...this.lang.tables_langs}
                            manual
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            onFetchData={this.fetchData}
                            filterable
                            defaultPageSize={5}
                            minRows={1}
                        />
                    </div>

                    <Comments
                        id={this.state.id}
                        get={'get_return_comment'} 
                        add={'add_return_comment'}
                    />            

                </React.Fragment>
                :
                <Loader type='small' />
        )
    }

    componentDidMount() {
        this.props.load({ url: `api/admin/manage_returns/${this.state.id}` }).then(manage_returns => {
            this.setState({
                order_no: manage_returns.data.order_no,
                order_id: manage_returns.data.order_id,
                email: manage_returns.data.email,
                sku: manage_returns.data.sku,
                product_name: manage_returns.data.product_name,
                price: manage_returns.data.price,
                quantity: manage_returns.data.quantity,
                loader: false
            });
        });

        this.props.load({ url: `api/admin/get_status` }).then(res => {
            this.setState({
                return_statuses: res.data
            });
        })
    }


    fetchData = (state) => {
        clearTimeout(this.id_timeout);

        this.id_timeout = setTimeout(() => {
            this.setState({ loading: true });

            let page = state.page + 1;
            let params = {
                page,
                per_page: state.pageSize,
            }

            if (state.sorted.length) {
                params['sorted'] = ValidForm.parseToGetString(state.sorted, 'sorted')
            }

            if (state.filtered.length) {
                params['filtered'] = ValidForm.parseToGetString(state.filtered, 'filtered');
            }

            this.props.load({ url: `api/admin/return_status_history/${this.state.id}`, data: params }).then(res => {
                if (!res.errors) {
                    this.setState({
                        data: res.data,
                        pages: res.meta.last_page,
                        loading: false
                    });
                } else {
                    this.setState({ loading: false });
                }
            });
        }, 500);
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load
}, dispatch)

export default connect(
    null,
    mapDispatchToProps,
)(ReturnDetailsComponent);