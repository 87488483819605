import React from 'react';
import API from 'boost/API';
import { connect } from 'react-redux';
import Loader from 'partials/loader';
import PropTypes from 'prop-types';
import Lang from '../lang/';

class WebPushNotifications extends React.PureComponent {

    state = {
        variation_id: 0,
        email: '',
        errors : [],
        loader: false,
        notify: ''
    }

    lang = Lang[global.language];

    render() {
        return (
            !this.state.loader 
            ?
            <div className='web-push-notification'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="btn-group">
                            <button className='btn btn-primary web-push-reminder-btn web-push-btn' onClick={() => this.chandleClickBtn('is_reminder')}>{this.lang.notification}</button>
                            {global.user_token ? <button className='btn btn-primary web-push-recommendation-btn web-push-btn' onClick={() => this.chandleClickBtn('is_recommendation')}>{this.lang.recommendation}</button> : '' }
                        </div>
                        {this.state.notify 
                        ? <div className='web-push-data'>
                            <h5>{this.state.notify === 'is_reminder' ? this.lang.isReminderDesc : this.lang.isRecommendationDesc}</h5>
                            <div className='form-group'>
                                <label htmlFor='wp-variation'>{this.lang.variants_title}</label>
                                <select
                                    name='variation_id'
                                    id='wp-variation'
                                    className='form-control'
                                    style={{ width: 'auto' }}
                                    onChange={this.chandleChange}
                                    value={this.state.variation_id}
                                    required
                                >
                                    <option>{this.lang.selectTitle}</option>
                                    {
                                        this.props.variations.map((variation, key) =>
                                            <option value={variation.id} key={key}> {variation.attributes_list} </option>
                                        )
                                    }
                                </select>
                                {this.showError('variation_id')}
                            </div>
                            <div className='form-group'>
                                <div className='row'>
                                    <div className='col-md-10'>
                                        <input type='email' name='email' onChange={this.chandleChange} value={this.state.email} className='form-control' placeholder={this.lang.add_email} required />
                                        {this.showError('email')}
                                    </div>
                                    <div className='col-md-2'>
                                        <button className='btn btn-primary web-push-reminder-btn web-push-send-btn' onClick={() => this.chandleClicSendBtn()}>{this.lang.sendTitle}</button>    
                                    </div>
                                </div>  
                            </div>
                        </div> 
                        : ''}         
                    </div>
                </div>
            </div>
            :      
            <div className='preloader'>
                <div className='preloader-wrapper'>
                    <Loader type='small'/>
                </div>
            </div>
        )
    }

    /**
     * Aktualizuje wartość state
     * 
     * @param { object } e - Event
     * @return { undefined }
     */
    chandleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    /**
     * Przechwytuje kliknięcie przycisku wyboru formularza powiadomienia
     * 
     * @param { string } notify - Typ powiadomienia
     * @return { undefined }
     */
    chandleClickBtn(notify) {
        this.setNotify(notify);
    }

    /**
     * Ustawia typ powiadomienia w state
     * 
     * @param { string } notify - Typ powiadomienia 
     */
    setNotify(notify = '') {
        this.setState({
            notify: notify
        });
    }

    /**
     * Przechwytuje kliknięcie przycisku wysyłania danych do API
     * 
     * @return { undefined }
     */
    chandleClicSendBtn() {
        this.prepData();
    }

    /**
     * Przygotowuje dane zapisane w state do wysłania do API
     * 
     * @return { undefined }
     */
    prepData() {
        let data = { ...this.state };
        data = {...data, ...{[this.state.notify]: true}};
        delete data.errors;
        delete data.loader;
        delete data.notify;
        this.send(data);
    }

    /**
     * Wysyła dane do API
     * 
     * @param { object }  data - Dane foamularza
     * @return { undefined }
     */
    send(data) {
        this.hideShowLoader();
        this.clearErrors();

        // Sprawdzenie czy użytkownik jest zalogowany
        if (typeof this.props.user !== 'undefined') {
            data.user_id = this.props.user.data.user_id;
        }

        API.post('/api/webpush/notification', data).then(res => {
            if(!res.error) {
                toastr.success(res.data.message);
                this.setNotify();
            } else {
                this.setState({ errors: res.data.errors });
            }
            this.hideShowLoader();
        })
    }

    /**
     * Ukrywa lub wyświetla loader w zależności od wartości w state
     * 
     * @return { undefined }
     */
    hideShowLoader() {
        this.setState({
            loader: !this.state.loader
        })
    }

    /**
     * Usuwa błędy walidacji danych formularza zapisane w state
     * 
     * @return { undefined }
     */
    clearErrors() {
        this.setState({
            errors: []
        })
    }

    /**
     * Wyświetla błędy formularza zwrócone przez API
     * 
     * @param { string } name - Nazwa pola formularza
     * @return { undefined }
     */
    showError = (name) => {
        let { errors } = this.state;
        if (errors[name]) {
            return (
                <React.Fragment>
                    {
                        errors[name].map((error, key) =>
                            <div className={`text-danger`}  key={key}>{error}</div>
                        )
                    }
                </React.Fragment>
            )
        }
    }

}

WebPushNotifications.propTypes = {
    variations: PropTypes.array
};

WebPushNotifications.defaultProps = {
    variations: []
};

const mapStateToProps = (state) => {
    return {
        user: state.boost.user
    }
}
export default connect(
    mapStateToProps
)(WebPushNotifications);