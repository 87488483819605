import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load } from 'boost/dispatch';
import Lang from '../lang';
import API from 'boost/API';

const CUSTOMER = 'Customer';

class CommentsComponent extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        message: '',
        comments: []
    }

    render() {
        let { message, comments } = this.state;

        return(
            <div className='comments'>
                <h3 className='upper'>{this.lang.comments}</h3>
                <hr />
                <div className='row'>
                    <div className='col-md-4 border-right'>
                        <form onSubmit={this.submit}>
                            <div className='form-group'>
                                <input className='form-control' name='name' type='text' value={this.lang.customer} disabled />
                            </div>
                            <div className='form-group'>
                                <textarea className='form-control' name='message' placeholder={this.lang.your_comment} value={message} onChange={this.updateState} rows='5' required></textarea>
                            </div>
                            <div className='form-group'>
                                <button type='submit' className='btn btn-primary'>{this.lang.comment}</button>
                            </div>
                        </form>
                    </div>
                    <div className='col-md-8'>
                        <span>{`${comments.length} ${this.lang.comments}`}</span>
                        {
                            comments.map((comment, key) => 
                                <div className='border margin-top-10 padding-10' key={key}>
                                    <div className='inline-block margin-left-10'>
                                        <h5><i className={`fa ${comment.name === CUSTOMER ? 'fa-shopping-cart': 'fa-user'}`} aria-hidden="true"></i> <span>{comment.name}</span></h5>
                                        <p>{comment.comment}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.props.load({ url: `api/admin/${this.props.get}/${this.props.id}/Customer` }).then(res => {
            this.setState({
                comments: res.data
            });
        });
    }

    /**
     * Aktualizuje wartość state
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    /**
     * Dodaj komentarz
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    submit = (e) => {
        e.preventDefault();

        let data_object = {
            message: this.state.message,
            comment_id: this.props.id,
            name: CUSTOMER,
            is_checked: 0,
            is_type: 1
        }

        API.post(`api/admin/${this.props.add}`, data_object).then(res => {
            if (!res.error) {
                let comments = [ ...this.state.comments, res.data.data ];
                this.setState({ comments });
            } else {
                toastr.error(res.data.message)
            }
        })
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load
}, dispatch)

export default connect(
    null,
    mapDispatchToProps,
)(CommentsComponent);