import React from 'react';
import CategorySlider from './category-slider';
import CategoryTree from './category-tree';
import Producers from './producers';
import Attributes from './attributes';
import ProductSearch from './product-search';
import ProductFilter from './product-filter';

export default {
    Category: CategorySlider,
    CategoryTree: CategoryTree,
    Producers: Producers,
    Attributes: Attributes,
    ProductSearch: ProductSearch,
    ProductFilter: ProductFilter
}