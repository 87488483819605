import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load } from 'boost/dispatch';
import API from 'boost/API';

class LangWidget extends React.Component {

    state = {
        lang: ''
    }

    render () {
        let { lang } = this.state;
        return (
            <li>
                <a style={{ display: 'table' }}>
                    <select 
                        className='form-control' 
                        style={{ display: 'table-cell', verticalAlign: 'middle' }} 
                        onChange={this.changeLang}
                        value={lang}
                    >
                        <option value='pl'>PL</option>
                        <option value='en'>EN</option>
                    </select>
                </a>
            </li>
        );
    }

    componentDidMount() {
        let lang = localStorage.getItem('lang');
        if (!!lang) {
            this.setState({ lang });
        }
    }

    componentDidUpdate() {
        if (this.props.user) {
            this.loadLocal();
        }
    }

    loadLocal = () => {
        this.props.load({ url: '/api/admin/localization', state: 'langs', param_result: 'locale' }).then(result => {
            if (result) {
                let current = result.filter(locale => locale.app_locale)[0] || { locale: 'pl' }
                let locale = current.locale;
                let lang = localStorage.getItem('lang');
                if (locale !== lang) {
                    localStorage.setItem('lang', locale);
                    this.setState({ lang: locale });
                    window.location.reload();
                }
            }
        });
    }

    changeLang = (e) => {
        let lang = e.target.value;
        if (this.props.user) {
            API.put('/api/admin/localization', { locale: lang }).then(res => {
                localStorage.setItem('lang', lang);
                this.setState({ lang });
                window.location.reload();
            })
        } else {
            localStorage.setItem('lang', lang);
            this.setState({ lang });
            window.location.reload();
        }
    } 

}

const mapStateToProps = (state) => {
    return {
        user: state.boost.user
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LangWidget);