import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Lang from '../lang';
import Select from 'react-select';
import reactSelectStyles from 'partials/react-select-styles';

class ProducersWidget extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        producers: [],
        producer: null,
    }

    render() {
        let { producer, producers } = this.state;
        return (
            <React.Fragment>
                <h4 className='text-uppercase widget-title'><span>{this.lang.producers_title}</span></h4>
                <Select
                    value={producer}
                    onChange={this.handleChange}
                    options={producers}
                    isSearchable={true}
                    isClearable={true}
                    placeholder={this.lang.start_writing}
                    styles={reactSelectStyles}
                />
            </React.Fragment>    
        );
    }

    componentDidMount() {
        if (!this.props.shop_producers) {
            this.props.load({ url: '/api/contractors/producer', data: { per_page: 100 } }).then(res => {
                this.props.updateValue({ state: 'shop_producers', data: res});
                this.loadData(res);
            })
        } else {
            this.loadData(this.props.shop_producers);
        }
    }

    /**
     * Przechwytuje zmianę wartości elementu select i ustawia state
     * 
     * @param { object } producer - Obiekt producenta
     * @return { undefined }
     */
    handleChange = (producer) => {
        let value = producer !== null ? producer.value : null;
        this.setState({ producer });
        this.props.updateValue({ state: 'poduct_list_filters', data: { producer: value }, join: true });
        this.scrollToFilters();
    }

    scrollToFilters = () => {
        document.getElementById('shop-widgets-filters-producer').scrollIntoView();
    }

    /**
     * Tworzy tablicę obiektów opcji odpowiednią dla select
     * 
     * @param { array } producers - Tablica producentów
     * @return { array }
     */
    createOptions = (producers) => {
        return producers.map(({id, short_name }) => ({
            value: id,
            label: short_name
        }));
    }

    /**
     * Zapisuje tablicę obiektów opcji dla select w state
     * 
     * @param { object } res - Obiekt odpowiedzi
     * @param { array } red.data - Tablica obiektów producentów
     * @return { undefined }
     */
    loadData = ({ data }) => {
        let producers = this.createOptions(data);
        this.setState({ producers });
    }
}

const mapStateToProps = (state) => {
    return {
        shop_producers: state.boost.shop_producers
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateValue,
    load
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProducersWidget);