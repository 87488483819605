import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Loader from 'partials/loader';
import Lang from '../lang';
import Helper from 'helpers';
import API from 'boost/API';

const COMPLAINT_KEY = 0;
const RETURN_KEY = 1;
const DAYS = 'Days';
const MONTH = 'Month';
const YEAR = 'Year';

class ReturnsComplaintsComponent extends React.PureComponent {
    lang = Lang[global.language];

    state = {
        order_id: this.props.match.params.order_id,
        items: [],
        loader: true
    }

    render() {
        return (
            !this.state.loader ?
                <React.Fragment>
                    <div className='table-responsive'>
                        <table className='shop_table table'>
                            <thead>
                                <tr>
                                    <th>{this.lang.index}</th>
                                    <th>{this.lang.product_name}</th>
                                    <th>{this.lang.order_total}</th>
                                    <th>{this.lang.quantity}</th>
                                    <th>{this.lang.actions}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.items.map((item, key) => 
                                        <tr key={key}>
                                            <td>
                                                {item.sku}
                                            </td>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>
                                                {Helper.currencyFormatter().format(item.gross)}
                                            </td>
                                            <td>
                                                1
                                            </td>
                                            <td>
                                                <button className='button' title={this.lang.complaint} onClick={() => this.complaintItem(item)}>
                                                    <i className='fa fa-shopping-cart'></i>
                                                </button>
                                                <button className='button margin-left-10' title={this.lang.return} onClick={() => this.returnItem(item)}>
                                                    <i className='fa fa-reply'></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <button type='button' className='btn btn-primary' onClick={this.goBack}>{this.lang.back_title}</button>
                </React.Fragment>
                :
                <Loader type='small' />
        );
    }

    componentDidMount() {
        this.props.load({ url: `api/shop/customer/orders/${this.state.order_id}` }).then(res => {
            let items = [];
            res.data.items.map(item => {
                for (let i = 1; item.quantity >= i; i++) {
                    items = [ ...items, { ...item, i } ];
                }
            });
            this.setState({ 
                items: items, 
                loader: false
            });
        });

        if (typeof this.props.time_config === 'undefined' || !this.props.time_config.length) {
            this.props.load({ url: `api/admin/time_configuration?sorted[0][id]=module_name&sorted[0][desc]=false` }).then(res => {
                this.props.updateValue({ data: res.data, state: 'time_config' });
            });
        }
    }

    /**
     * Tworzy obiekt danych dla request
     * 
     * @param {Object} item - Pojedynczy element zamówienia
     * @return {Object}
     */
    dataObject = (item) => {
        let data_obj = {
            'order_id': item.order_id,
            'product_id': item.id,
            'quantity_id': `qty_${item.id}_${item.i}`
        };
        return data_obj;
    }

    /**
     * Dodaje metodę addDays do prototypu Date
     * 
     * @param {String} module_name - Rodzaj żądania klienta
     * @return {Object}
     */
    dateTypeProrotypeAddDaysMethod = (module_name) => {
        let date_config = this.props.time_config;
        let expire_day = parseInt(date_config[module_name].expire_day, 10);

        Date.prototype.addDays = function() {
            let date = new Date(this.valueOf());
            let expire_interval = date_config[module_name].expire_interval;

            switch (expire_interval) {
                case DAYS: 
                    date.setDate(date.getDate() + expire_day);
                    return date;
                case MONTH:
                    date.setMonth(date.getMonth() + expire_day);
                    return date;
                case YEAR:
                    date.setFullYear(date.getFullYear() + expire_day);
                    return date;
                default:
                    return date;
            }
        };
    }
    
    /**
     * Sprawdza czy istnieje możliwość realizacji zwrotu
     * 
     * @param {Object} item - Pojedynczy element zamówienia
     * @return {undefined}
     */
    returnItem = (item) => {
        this.dateTypeProrotypeAddDaysMethod(RETURN_KEY);
        let date = new Date(item.created_at);
        let ordered_date = date.addDays();
        let current_date = new Date();

        if (current_date > ordered_date) {
            toastr.info(this.lang.disable_return_time);
        } else {
            let data_obj = this.dataObject(item);
            API.post('api/admin/return_made', data_obj).then(res => {
                if (!res.error) {
                    API.post('api/admin/complaint_made', data_obj).then(res => {
                        if (!res.error) {
                            this.props.history.push(`${this.props.route}/${this.lang.slug_returns_and_complaints}/${this.lang.slug_return}/${item.order_id}/${item.id}/${item.i}`);
                        } else {
                            toastr.warning(res.data.message);
                        } 
                    })
                } else {
                    toastr.warning(res.data.message);
                }
            });
        }
    }

    /**
     * Sprawdza czy istnieje możliwość realizacji reklamacji
     * 
     * @param {Object} item - Pojedynczy element zamówienia
     * @return {undefined}
     */
    complaintItem = (item) => {
        this.dateTypeProrotypeAddDaysMethod(COMPLAINT_KEY);
        let date = new Date(item.created_at);
        let ordered_date = date.addDays();
        let current_date = new Date();

        if (current_date > ordered_date) {
            toastr.info(this.lang.disable_complaint_time);
        } else {
            let data_obj = this.dataObject(item);
            API.post('api/admin/return_made', data_obj).then(res => {
                if (!res.error) {
                    this.props.history.push(`${this.props.route}/${this.lang.slug_returns_and_complaints}/${this.lang.slug_complaint}/${item.order_id}/${item.id}/${item.i}`);
                } else {
                    toastr.warning(res.data.message);
                }
            });
        }
    }

    /**
     * Umożliwia powrót na poprzednią stronę
     * 
     * @return {undefined}
     */
    goBack = () => {
        this.props.history.goBack();
    }
}

const mapStateToProps = (state) => {
    return {
        time_config: state.boost.time_config,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReturnsComplaintsComponent);