import React from 'react';
import './style.scss';
import OrdersUser from './account/orders';
import OrderUser from './account/order';
import Comments from './account/comments';
import CommentAdd from './account/add-comments';
import Dashboard from './account/dashboard';
import LoginRegister from './account/login-register';
import LostPassword from './account/lost-password';
import EditAccount from './account/edit-account';
import EditAddresses from './account/edit-addresses';
import EditAddress from './account/edit-address';
import AddReturnComplaint from './returns-complaints/add-return-complaint';
import ReturnsComplaints from './returns-complaints'
import ListReturns from './returns-complaints/list-returns';
import ListComplaints from './returns-complaints/list-complaints';
import ReturnDetails from './returns-complaints/return-details';
import ComplaintDetails from './returns-complaints/complaint-details';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue, refreshToken } from 'boost/dispatch';
import Lang from './lang/';
import Loader from 'partials/loader';
import API from 'boost/API';

class User extends React.PureComponent {


    lang = Lang[global.language];
    state = {
        redirect: false
    }
    uniqURL = {
        [this.lang.slug_orders]: 'orders',
    }
    checkUniqURL(slug) {
        if (this.uniqURL[slug]) {
            return slug
        }
        return 'not-uniq';
    }

    render() {
        return (
            global.user_token ?
                <React.Fragment>

                    <div className='main-header background' style={{ background: 'linear-gradient(rgba(28, 28, 28, 0.3), rgba(28, 28, 28, 0.3)), url("/themes/default/img/backgrounds/background-about.jpg")' }}>
                        <div className="container">
                            <h1 className="main-header-title text-center block">
                                <span>{this.lang.myAccountTitle}</span>
                            </h1>
                        </div>
                    </div>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div id='breadcrumb'>
                                    <ol className='breadcrumb'>
                                        <li><Link to={'/'}>{this.lang.home}</Link></li>
                                        <li>{this.lang.myAccountTitle}</li>
                                    </ol>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <article className='post-10 page type-page status-publish hentry woocommerce-account'>

                                    <div className='woocommerce'>
                                        <nav className='woocommerce-MyAccount-navigation'>
                                            <ul>
                                                <li className='woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard'>
                                                    <Link to={`${this.props.route}`}>{this.lang.boardTitle}</Link>
                                                </li>
                                                <li className='woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--orders'>
                                                    <Link to={`${this.props.route}/${this.lang.slug_orders}`}>{this.lang.ordersTitle}</Link>
                                                </li>
                                                {/* <li className='woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--downloads'>
                                                    <Link to={`${this.props.route}/${this.lang.slug_comments}`}>{this.lang.commentsTitle}</Link>
                                                </li> */}
                                                <li className='woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--edit-address'>
                                                    <Link to={`${this.props.route}/${this.lang.slug_edit_addresses}`}>{this.lang.addressesTitle}</Link>
                                                </li>
                                                <li className='woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--edit-account'>
                                                    <Link to={`${this.props.route}/${this.lang.slug_edit_account}`}>{this.lang.accountDetailsTitle}</Link>
                                                </li>
                                                <li className='woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--edit-account'>
                                                    <Link to={`${this.props.route}/${this.lang.slug_returns}`}>{this.lang.returns}</Link>
                                                </li>
                                                <li className='woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--edit-account'>
                                                    <Link to={`${this.props.route}/${this.lang.slug_complaints}`}>{this.lang.complaints}</Link>
                                                </li>
                                                <li className='woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--customer-logout'>
                                                    <a className="cursor-pointer" 
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.logout();
                                                            return false;
                                                        }}
                                                    >{this.lang.logoutTitle}</a>
                                                </li>
                                            </ul>
                                        </nav>

                                        <div className='woocommerce-MyAccount-content'>
                                            <Switch>
                                                <Route exact path={`${this.props.route}/`} component={Dashboard} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_orders}`} component={(props) => <OrdersUser {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_orders}/:id`} component={(props) => <OrderUser {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_comments}`} component={(props) => <Comments {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_add_comment_order}/:order_id`} component={(props) => <CommentAdd {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_add_comment_product}/:product_id`} component={(props) => <CommentAdd {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_edit_account}`} component={(props) => <EditAccount {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_edit_addresses}`} component={(props) => <EditAddresses {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_edit_address}/:address_type`} component={(props) => <EditAddress {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_returns_and_complaints}/:order_id`} component={(props) => <ReturnsComplaints {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_returns_and_complaints}/:module_name/:order_id/:product_id/:i`} component={(props) => <AddReturnComplaint {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_returns}`} component={(props) => <ListReturns {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_complaints}`} component={(props) => <ListComplaints {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_returns}/:id/edit`} component={(props) => <ReturnDetails {...props} route={this.props.route} />} />
                                                <Route exact path={`${this.props.route}/${this.lang.slug_complaints}/:id/edit`} component={(props) => <ComplaintDetails {...props} route={this.props.route} />} />
                                            </Switch>
                                        </div>
                                    </div>

                                </article>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                : this.state.redirect ?
                    <Switch>
                        <Route exact path={`${this.props.route}/${this.lang.slug.slug_login_register}`} component={(props) => <LoginRegister {...props} route={this.props.route} />} />
                        <Route exact path={`${this.props.route}/${this.lang.slug.lost_password_slug}`} component={(props) => <LostPassword {...props} route={this.props.route} />} />                        
                    </Switch>
                    : <div className='preloader'>
                        <div className='preloader-wrapper'>
                            <Loader type='small'/>
                        </div>
                    </div>
        )
    }

    componentDidMount() {
        if (!global.user_token) {
            this.props.refreshToken().then(res => {
                if (!res) {
                    this.setState({ redirect: true })
                } else {
                    this.props.updateValue({ state: 'login_access', data: true });
                    this.getUserData();
                }
            })
        }
    }

    /**
     * Pobiera dane zalogowanego użytkownika
     * 
     * @return {undefined}
     */
    getUserData = () => {
        this.props.load({ url: `/api/shop/customer/account`, state: 'user' });
    }

    logout = () => {
        API.post('/api/logout').then(res => {
            window.location.href = '/';
        })
    }
}

const mapStateToProps = (state) => {
    return {
        loader: state.boost.loader
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue,
    refreshToken
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(User);