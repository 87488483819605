export default {
    control: (base, state) => ({
        ...base,
        border: '1px solid #333',
        borderRadius: 0,
        boxShadow: state.isFocused ? '1px solid #e12227' : '1px solid #333',
        '&:hover': {
           border: state.isFocused ? '1px solid #e12227' : '1px solid #333',
        },
    })
}