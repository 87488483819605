import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import CoreDispatch from './dispatch';

/**
 *  Definicja objektu dispacherow
 */
let dispachers = {};


export default combineReducers({
    router: routerReducer,
    boost: CoreDispatch
})
