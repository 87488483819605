
export default {

    parseToGetString(target, name) {
        let url = '';
        target.map((value, index) => {
            if (typeof value == 'object') {
                Object.keys(value).map(key => {
                    url += `${name}[${index}][${key}]=${value[key]}&`;
                })
            } else {
                url += `${name}[]=${value}&`
            }
        })
        url = url.slice(0, url.length - 1);
        return url;
    },

    serializeValidData(data){
        return this.serializeData(data, true).data;
    },
    serializeData(data, returnDate, validation_rules = {}) {
        
        returnDate = typeof returnDate !='undefined'? returnDate: false;
        const formData = { error: 0, data: {}, resetValid: {} };
        const error = { error: 1, data: {} };
        let onInvalid = 0;
        let radio = {};

        for (const field in data) {
            if (
                data[field] && (
                data[field].tagName == 'INPUT' ||
                data[field].tagName == 'TEXTAREA' ||
                data[field].tagName == 'SELECT' ||
                (typeof data[field].refs !='undefined' && Object.keys(data[field].refs).length && !data[field].refs.modal))
            ) {// Walidujemy tylko elementy będące inputami, textarea oraz objektami zawierające referencje
                let field_form = data[field];
                let key_field = field;
                
                if (key_field.indexOf('_type')) {
                    
                    for (const field_ref in field_form.refs) {
                        field_form = field_form.refs[field_ref];
                    }
                    key_field = key_field.replace('_type', '');
                }
                let valid = this.checkValid(field_form);
                if (field_form.type == 'radio') {
                    key_field = field_form.name;

                    if (!valid) {
                        radio[key_field] = field_form.value;
                    }
                    else if (radio[key_field] != null && valid) {
                        valid = 0;
                    }
                }
                this.removeErrorField(`[name="${field_form.name}"]`);
                error.data[key_field] = { invalid: 0 }; // Resetowanie invalid dla pola
                if (valid && radio[key_field] == null) {
                    error.data[key_field] = { invalid: 1 };
                    this.addErrorToField(`[name="${field_form.name}"]`);
                }
                else {
                    if (field_form.type == 'radio' || field_form.type == 'checkbox') {
                        if (field_form.type == 'radio')
                            formData.data[key_field] = radio[key_field];
                        else
                            formData.data[key_field] = field_form.checked;
                    }
                    else if(field_form.tagName == 'SELECT' && field_form.getAttribute('multiple') != null ){
                        formData.data[key_field] = Array.from(field_form.selectedOptions).map(option => option.value)
                    }
                    else if(field_form.type == 'file') {
                        formData.data[key_field] = field_form.onfiles;
                    }
                    else{
                        formData.data[key_field] = field_form.value;
                    }
                    formData.resetValid[key_field] = { invalid: 0 };
                }
            }
        }
        error.data = this.checkRules({rules: validation_rules, fields: error.data});
        //error.data = this.getRulesFromParent(error.data);
        // Sprawdzanie czy znajduje się jakiś element zawierający invalid Jeśli tak to zostanie zwrócony error
        for (const field in error.data) {
            if (error.data[field].invalid != null && error.data[field].invalid) {
                onInvalid = 1;
            }
        }
        if (onInvalid && !returnDate) {
            this.scrollToError();
            return error;
        }
        return formData;
    },

    checkRules(params) {
        // Sprawdzamy czy w formularzu nie znajdują się pola, w których zostały dodane walidacje ról w atrubucie data-
        params.rules = this.getRulesFromParent(params.fields, params.rules);
        if(params.rules.oneOf) {
            params.rules.oneOf.map(one_rules => {
                let is_valid = false;
                // Sprawdzanie, czy chociaż jeden z elementów jest poprawny
                one_rules.map(element => {
                    if(params.fields[element] && !params.fields[element].invalid){
                        is_valid = true;
                    }
                })
                if(is_valid){
                    one_rules.map(element => {
                        if(params.fields[element]){
                            // czyszczenie komunikatów oraz resetowanie validowanych pól
                            if(params.fields[element]){
                                this.removeErrorField('[name='+element+']');
                            }
                            params.fields[element].invalid = 0;
                        }
                    })
                }
            })
        }
        return params.fields;
    },
    getRulesFromParent(fields, rules){
        let to_validation = false;
        Object.keys(fields).map(key => {
            let element = document.querySelector(`[name="${key}"]`);
            let closest = element.closest('.validation-field');
            let rules = closest ? closest.dataset : {};
            let keys = Object.keys(rules);
            if(keys.length) {
                to_validation = to_validation || {};
                keys.map(index => {
                    if(!to_validation[rules[index]]) {
                        to_validation[rules[index]] = {[index]: rules[index].split(',')}
                    }
                })
            }
        });
        if(to_validation) {
            Object.keys(to_validation).map(key => {
                Object.keys(to_validation[key]).map(type_rule => {
                    if(rules[type_rule]) {
                        rules[type_rule].push(to_validation[key][type_rule]);
                    } else {
                        rules[type_rule] = [to_validation[key][type_rule]];
                    }
                })
            })
        }
        return rules;
    },

    addErrorToField(target, message){
        message = typeof message !='undefined'? message : 'Niepoprawna wartość pola';
        let element = document.querySelector(target);
        if(element && element.type != 'hidden') {
            let closest = element.closest('.validation-field');
            let error = document.createElement('DIV');
            error.setAttribute('class','form-control-feedback');
            error.innerHTML = `${message}`;
            closest.parentNode.insertBefore(error, closest.nextSibling);
            closest.closest('.validation-zone').classList.add('has-danger');
            this.tabElement(target);
            return true;
        }
        return false;
    },
    tabElement(target) {
        let is_tab = document.querySelector(target).closest('.tab-pane');
        let is_form_tab = document.querySelector(target).closest('.tab-content');
        if(is_tab) {
            let id_target = is_tab.getAttribute('id');
            document.querySelector(`.m-tabs__link[href="#${id_target}"]`).click();
        } else if(is_form_tab) {
            let id_target = is_form_tab.getAttribute('data-tab-content');
            document.querySelector(`[data-tab-nav="${id_target}"]`).click();
        }
    },
    removeErrorField(target){
        let field = document.querySelector(target);
        let message = field && field.closest('.validation-field') ? field.closest('.validation-field').nextSibling : false;
        if(message){
            message.remove();
            if(field.closest('.validation-field').nextSibling){
                this.removeErrorField(target);
            }
        }
        if(field && field.closest('.validation-zone')) {
            field.closest('.validation-zone').classList.remove('has-danger');
        }

    },
    showErrors(errors){
        let show_errors = '';
        Object.keys(errors).map(key => {
            this.removeErrorField(`[name="${key}"]`);
            errors[key].map(error_text => {
                if( !this.addErrorToField(`[name="${key}"]`, error_text)) {
                    show_errors += `${error_text}\n`;
                }
            })
        });
        if(show_errors.length) {
            toastr.error(show_errors);
        }
        this.scrollToError();
    },

    scrollToError() {
        let to_scroll = document.querySelector('.has-danger');
        if(to_scroll) {
            document.querySelector('.has-danger').scrollIntoView({
                behavior: 'smooth'
            });
        }
    },

    checkValid(data) {
        if(data.required || data.value != ''){
            let toValid = '';
            if (data.type == 'radio' || data.type == 'checkbox')
                toValid = data.checked;
            else
                toValid = data.value;

            if (data.type == 'email')
                return !this.validateEmail(data.value)

            if (data.min && data.min > data.value.length)
                return 1;

            if (data.dataset.checkFunction == 'pesel')
                return !this.validatePesel(data.value)

            if (data.dataset.checkFunction == 'serialDocument')
                return this.validateSerialDocument(data.value)

            if (data.dataset.checkFunction == 'codePost')
                return this.validateCodePost(data.value)

            if (data.dataset.checkFunction == 'nip')
                return !this.validateNip(data.value)

            if (data.dataset.checkFunction == 'text')
                return this.validateText(data.value)

            if (data.dataset.checkFunction == 'textAndNumber')
                return this.validateTextAndNumber(data.value)

            if (data.dataset.checkFunction == 'passport')
                return this.validatePassport(data.value)

            if (data.dataset.checkFunction == 'peselORpassport')
                return this.validatePassport(data.value) && !this.validatePesel(data.value);

            if (data.dataset.checkFunction == 'serialDocumentOrPasport'){
                return !(this.validateSerialDocument(data.value) && !this.validatePassport(data.value) || !this.validateSerialDocument(data.value));
            }
            if (data.dataset.checkFunction == 'phone')
                return this.validatePhone(data.value)

            if (data.dataset.checkFunction == 'number')
                return this.validateNumber(data.value)

            if (data.required && !toValid) {
                return 1;
            }
        }
        return 0;
    },
    validateSpecialCharacters(text){
        var reg = /^[a-zA-Z0-9- ]*$/;
        if (reg.test(text) == true)
            return true;
        return false;
    },
    validateLowerCase(text){
        var reg = /[a-z]/;
        if (reg.test(text) == false)
            return true;
        return false;
    },
    validateUppercase(text){
        var reg = /[A-Z]/;
        if (reg.test(text) == false)
            return true;
        return false;
    },
    validateNumberPassword(text) {
        var reg = /\d/;
        if (reg.test(text) == false)
            return true;
        return false;
    },
    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    validateText(text) {
        var reg = /^[a-ząćśńółęA-ZĄĆŚŃÓŁĘ]+$/;
        if (reg.test(text) == false)
            return true;
        return false;
    },
    validateTextAndNumber(text) {
        var reg = /^[a-ząćśńółęA-ZĄĆŚŃÓŁĘ0-9]+$/;
        if (reg.test(text) == false)
            return true;
        return false;
    },
    validateSerialDocument(text) {
        var reg = /^[A-Z]{3}[0-9]{6}$/;
        if (reg.test(text) == false)
            return true;
        return false;
    },
    validateCodePost(text) {
        var reg = /^[0-9]{2}\-[0-9]{3}$/;
        if (reg.test(text) == false)
            return true;
        return false;
    },
    validateNumber(text) {
        var reg = /^[0-9]+$/;
        if (reg.test(text) == false)
            return true;
        return false;
    },
    validatePhone(phone) {
        var regk = /^(([0]{2}|\+)\d{2}[- ]?)?(\d{3}[- ]?\d{3}[- ]?\d{3})$/; // komórkowe
        var regs = /^(([0]{2}|\+)\d{2}[- ]?)?((\(\d{2}\)|\d{2})[- ]?)(\d{3}[- ]?\d{2}[- ]?\d{2}|\d{2}[- ]?\d{2}[- ]?\d{3})$/; //stacjonarne
        return !(regk.test(phone) || regs.test(phone));
    },
    validatePesel(pesel) {
        var reg = /^[0-9]{11}$/;
        if (reg.test(pesel) == false)
            return false;
        else {
            var digits = ('' + pesel).split('');
            if ((parseInt(pesel.substring(4, 6)) > 31) || (parseInt(pesel.substring(2, 4)) > 12))
                return false;
            var checksum = (1 * parseInt(digits[0]) + 3 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 9 * parseInt(digits[3]) + 1 * parseInt(digits[4]) + 3 * parseInt(digits[5]) + 7 * parseInt(digits[6]) + 9 * parseInt(digits[7]) + 1 * parseInt(digits[8]) + 3 * parseInt(digits[9])) % 10;
            if (checksum == 0) checksum = 10;
            checksum = 10 - checksum;
            return (parseInt(digits[10]) == checksum);
        }
    },
    validateNip(nip) {
        var nipWithoutDashes = nip.replace(/-/g, '');
        var reg = /^[0-9]{10}$/;
        if (reg.test(nipWithoutDashes) == false) {
            return false;
        }
        else {
            var digits = ('' + nipWithoutDashes).split('');
            var checksum = (6 * parseInt(digits[0]) + 5 * parseInt(digits[1]) + 7 * parseInt(digits[2]) + 2 * parseInt(digits[3]) + 3 * parseInt(digits[4]) + 4 * parseInt(digits[5]) + 5 * parseInt(digits[6]) + 6 * parseInt(digits[7]) + 7 * parseInt(digits[8])) % 11;

            return (parseInt(digits[9]) == checksum);
        }
    },
    validatePassport(passport) {
        var reg = /[A-Z]{2}[0-9]{7}$/;
        if (reg.test(passport) == false)
            return true;
        return false;
    },
    checkMimetype(type) {
        switch (type) {
            case '/' : return 'image/jpg';
            case 'i' : return 'image/png';
            case 'R' : return 'image/gif'
        }
        return false;
    }
    
}


