export default {
    on_page: 'na stronie',
    show: 'Pokaż',
    cart_title: 'Koszyk',
    product_name: 'Nazwa produktu',
    qty: 'Ilość',
    subtotal: 'Suma',
    unit: 'Cena',
    cart_empty: 'Twój koszyk jest obecnie pusty',
    sort: 'Sortuj',
    none: 'brak',
    price_asc: 'Cena malejąco',
    price_desc: 'Cena rosnąco',
    add_to_cart: 'Dodaj do koszyka',
    products: 'Produkty',
    product: 'Produkt',
    from: 'od',
    to: 'do',
    of: 'z',
    available: 'dostępny',
    unavailable: 'niedostępny',
    view_cart: 'Zobacz koszyk',
    process_checkout: 'Zamawiam',
    total: 'Suma',
    price: 'Cena',
    checkout_title: 'Podsumowanie',
    categories: 'Kategorie',
    shop: 'sklep',
    category: 'kategoria',
    cart: 'koszyk',
    checkout: 'proces-zamawiania',
    order_summary: 'podsumowanie-zamowienia',
    order_summary_title: 'Podsumowanie zamówienia',
    producers_title: 'Producenci',
    start_writing: 'Zacznij pisać',
    attributes_title: 'Atrybuty',
    search_product_title: 'Szukaj produktu',
    notification: 'powiadomienie',
    recommendation: 'rekomendacja',
    isReminderDesc: 'Powiadomienie o dostępności produktu',
    isRecommendationDesc: 'Polecenie produktu',
    variantsTitle: 'Warianty',
    selectTitle: 'Wybierz',
    sendTitle: 'Wyślij',
    price_from_title: 'Od',
    category_title: 'Kategoria',
    promotion: 'promocja',
    customer_panel: 'Panel klienta',
    slug_user_account: 'konto-uzytkownika',
    slug_login_register: 'logowanie-rejestracja',
    couponTitle: 'Kupon',
    enterRebateCodeTitle: 'Wprowadź swój kod rabatowy',
    checkDiscountTitle: 'Sprawdź rabat',
    cartAddError: 'Wystąpił problem przy dodawaniu produktu do koszyka',
    noAmountAvailable: 'Brak dostępnej ilości w magazynie',
    no_products_to_display_title: 'Brak produktów do wyświetlenia',
    'last-watched': 'Ostatnio oglądane',
    'you-may-like': 'Mogą Ci się podobać',
    'fit-into': 'Pasują do tego produktu',
    coupon_code_title: 'Kod kuponu',
    apply_coupon_title: 'Zastosuj kupon',
    proceed_checkout_title: 'Przejdź do kasy',
    cart_summary_title: 'Podsumowanie koszyka',
    amount_title: 'Kwota',
    new: 'Oczekiwanie na płatność',
    failed: 'Brak płatności',
    success: 'Opłacono',
    canceled: 'Anulowano',
    leave_cart_title: 'Zrezygnuj z zakupów',
    home: 'Strona główna',
    product_details: 'zobacz produkt',
    product_description_title: 'Opis produktu',
    variants_title: 'Warianty',
    currency: 'zł',
    no_comments_for_product_title: 'Brak komentarzy dla tego produktu',
    products_list_title: 'Lista produktów',
    first_name: 'Imię',
    add_first_name: 'Podaj imię',
    last_name: 'Nazwisko',
    add_last_name: 'Podaj nazwisko',
    firm_name_optional: 'Nazwa firmy (opcjonalne)',
    add_firm_name_optional: 'Podaj nazwę firmy (opcjonalne)',
    nip_optional: 'Nip (opcjonalne)',
    add_nip_optional: 'Podaj nip (opcjonalne)',
    country: 'Kraj',
    street: 'Ulica',
    add_street: 'Podaj ulicę',
    zip_code: 'Kod pocztowy',
    add_zip_code: 'Podaj kod pocztowy',
    city: 'Miasto',
    add_city: 'Podaj miasto',
    phone: 'Telefon',
    add_phone: 'Podaj telefon',
    email: 'Email',
    add_email: 'Podaj email',
    your_order: 'Twoje zamówienie',
    contact_address: 'Adres kontaktowy',
    copy_from_contact_address: 'Skopiuj z danych kontaktowych',
    shipping_address: 'Adres do wysyłki',
    add_shipment_address: 'Dodaj dane do wysyłki',
    not_need_invoice: 'Nie potrzebuję faktury',
    add_invoice_address: 'Dodaj dane do faktury',
    invoice_address: 'Adres do faktury',
    product_name: 'Nazwa produktu',
    quantity: 'Ilośc',
    value: 'Wartość',
    summary: 'Podsumowanie',
    shipment: 'Wysyłka',
    courier: 'kurier',
    on_line_payments: 'Płatności on-line',
    cash_on_delivery: 'Za pobraniem',
    delivery_options: 'Opcje dostawy',
    payment_methods: 'Sposoby płatności',
    select: 'Wybierz',
    buy_and_pay: 'Zamawiam z obowiązkiem zapłaty',
    order_number: 'Numer zamówienia',
    date: 'Data',
    payment_method: 'Metoda płatności',
    payment_status: 'Status płatności',
    order_details: 'Szczegóły zamówienia',
    received_your_order: 'Dziękujemy. Otrzymaliśmy Twoje zamówienie',
    comments: 'Komentarze',
    country_name_lang: 'name_pl',
    bank_transfer: 'Przelew bankowy',
    slug_image_preview: 'podglad-zdjecia',
    preview: 'Podgląd',
    description: 'Opis',
    additional_information: 'Dodatkowe informacje',
    resources: 'Zasoby',
    reviews: 'Opinie',
    no_opinion_about_product_yet: 'Na razie nie ma opinii o produkcie',
    cart_value_updated: 'Zaktualizowano wartość koszyka',
    item_removed_shopping_cart: 'Usunięto pozycję z koszyka',
    items_removed_shopping_cart: 'Usunięto pozycje z koszyka',
    order: 'Zamówienie',
    comments_to_order: 'Uwagi do zamówienia',
    optional: 'opcjonalne',
    add_comments_to_order: 'Podaj uwagi do zamówienia',
    to_pay: 'Do zapłaty',
    agree_with: 'Zgadzam się z ',
    site_regulations: ' regulaminem serwisu',
    page: 'strona',
    terms_conditions: 'regulamin',
    reviewed_right_withdraw_from: 'Zapoznałem się z prawem odstąpienia od',
    contract: ' umowy',
    returns_and_replacements: 'zwroty-i-reklamacje',
    // Wish list
    wish_list_empty: 'Żadne produkty nie zostały dodane do listy życzeń',
    add_to_whishlist: 'Dodaj do listy życzeń',
    view_wishlist: 'Zobacz listę życzeń',
    wish_list: 'Lista życzeń',
    wishlist_empty: 'Nie dodano żadnych produktów do listy życzeń',
    availability: 'Stan magazynowy',
    available_title: 'Dostępny',
    unavailable_title: 'Niedostępny',
    product_added_wish_list: 'Dodano produkt do listy życzeń',
    personalize: 'Personalizuj',
    // slugi
    slug: {
        shop: 'sklep',
        page: 'strona',
        checkout: 'proces-zamawiania',
        cart: 'koszyk',
        order_summary: 'podsumowanie-zamowienia',
        category: 'kategoria',
        slug_image_preview: 'podglad-zdjecia',
        returns_and_replacements: 'zwroty-i-reklamacje',
        terms_conditions: 'regulamin',
        wish_list: 'lista-zyczen',
        policies_slug: 'warunki-umowy',
        shipping_costs: 'koszty-wysylki',
    },
    '0d': 'przesyłka natychmiastowa',
    '1d': '1 dzień',
    '2d': '2 dni',
    '3d': '3 dni',
    estimated_delivery_time: 'Przewidywany czas dostawy',
    traditional_transfer: 'Przelew tradycyjny',
    bank_transfer_details: 'Dane do przelewu bankowego',
    bank_name: 'Nazwa banku',
    bank_account_number: 'Numer konta bankowego',
    include_order_number: 'W tytule przelewu należy podać numer zamówienia',
    firm_name: 'Nazwa firmy',
    address: 'Adres',
    delivery_cost: 'Koszt dostawy',
    package_quantity: 'Ilość w paczce',
    dedicated_cost: 'Dedykowany koszt',
    not_added: 'Nie podano',
    dimensions: 'Wymiary',
    length: 'Długość', 
    width: 'Szerokość',
    height: 'Wysokość',
    custom_delivery_cost: 'Niestandardowy koszt dostawy',
    not_applicable: 'Nie dotyczy',
    target_point: 'Nazwa punktu docelowego',
    set_target_point: 'Podaj nazwę punktu docelowego',
    brand: 'Marka',
    model: 'Model',
    fits_to: 'Pasuje do',
    no_matching_models_given: 'Nie podano pasujących modeli',
    no_options_message: 'Brak opcji',
    select_brand: 'Wybierz markę, aby uzyskać listę modeli',
    code: 'Kod',
    select_car_brand_model: 'Wybierz markę i model samochodu',
    only_available: 'Tylko dostępne',
    producer_nr: 'Nr producenta',
    delivery_and_cost: 'Dostawa i koszt',
    i_accept_the_terms_of_the: 'Akceptuję warunki',
    regulations: 'regulaminu',
    privacy_policy: 'polityki prywatności',
    producer: 'Producent',
    quality: 'Jakość',
    producer_name: 'Nazwa producenta',
    country_origin: 'Kraj pochodzenia',
    delivery_costs_as_in: 'Koszty dostawy zgodne z',
    price_list: 'cennikiem'
}