import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Image from 'partials/image';
import Cookies from 'universal-cookie';
import Lang from '../lang';
import Helper from 'helpers';

class CategoryProductWidget extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        module_url: '',
        products: []
    }

    uniq = this.props.uniq;
    $carousel = null

    render() {
        return (
            <div className='row'>
                <div className={`col-md-12 col-sm-12 ${this.props.toRight ? 'col-md-pull-3 col-sm-pull-4' : ''}`}>
                    {
                        <React.Fragment>
                            <div className='section-header center'>
                                <div className='upper margin-bottom-20'>
                                    <h2>{this.lang[`${this.props.uniq}`]}</h2>
                                </div>
                            </div>
                            {
                                !this.state.products.length ?
                                    <div className='section-header center'>
                                        <p>{this.lang.no_products_to_display_title}</p>
                                    </div>
                                    :
                                    <div className='products owl-carousel' data-items='4' ref="product_grid">
                                        {
                                            this.state.products.map((product, index) =>
                                                <div className='product product-grid' key={index}>
                                                    <div className='product-media'>
                                                        <div className='product-thumbnail border'>
                                                            <Link to={`/${this.lang.slug.shop}/${product.slug}`}>
                                                                {
                                                                    product.image ?
                                                                        <Image
                                                                            image={product.image}
                                                                            size='300x300'
                                                                            className='current'
                                                                        />
                                                                        : <img src='/themes/default/img/samples/products/index/clothing/4.jpg' alt='' className='current' />
                                                                }

                                                            </Link>
                                                        </div>
                                                        <div className='product-hover'>
                                                            <div className='product-actions'>
                                                                <Link to={`/${this.lang.slug.shop}/${product.slug}`} className='awe-button product-add-cart' data-toggle='tooltip' title={this.lang.preview}>
                                                                    <i className='icon icon-eye'></i>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='product-body'>
                                                        <h2 className='product-name'>
                                                            <Link to={`/${this.lang.slug.shop}/${product.slug}`} title={product.name}>{product.name}</Link>
                                                        </h2>
                                                        <div className='product-category'>
                                                            <Link to={`${this.lang.slug.shop}/${this.lang.slug.category}/${product.category_slug}`} title={product.category_name}>{product.category_name}</Link>
                                                        </div>
                                                        <div className='product-price'>
                                                            <span className='amount'>{`${Helper.currencyFormatter().format(product.min_selling_price)}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        )
    }
    componentDidMount() {
        let cookies = new Cookies();
        let uuid = cookies.get('uuid');
        let { route_map, uniq, product_view, product, load, updateValue } = this.props;

        if (route_map) {
            let to_url = route_map.filter(element => element.module == 'shop' && element.slug != '');
            if (to_url.length) {
                this.setState({ module_url: `/${to_url[0].slug}` });
            }
        }

        if (typeof uuid !== 'undefined') {
            let category_id = uniq === 'fit-into' ? (!!product_view ? product_view.category_id : '') : '';
            load({ url: `api/item-suggest/${uniq}/${uuid}/${category_id}` }).then(res => {
                if (!!res.data) {
                    let data = res.data;
                    if (!!product_view) {
                        data = data.filter(item => item.id != product_view.id);
                    }
                    data = data.sort(() => Math.random() - 0.5);
                    updateValue({ state: 'shop.widget.product', data: { ...product, [uniq]: data } })
                    this.loadData(data);
                }
            });
        }
    }

    loadData(data) {
        this.setState({ products: data });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.products.length != this.state.products.length) {
            if (this.$carousel) {
                this.$carousel.owlCarousel('destroy');
                this.initCarousel()
            } else {
                this.initCarousel()
            }
        }
    }

    initCarousel() {
        this.$carousel = $(this.refs['product_grid']);
        var data = this.$carousel.data();

        var items = this.$carousel.data('items');
        if (!items) items = 4;

        // Responsive
        var responsive = {};

        if (items == 4) {
            responsive = {
                0: { items: 1 },
                480: { items: 2 },
                768: { items: 3 },
                980: { items: 4 }
            };
        } else if (items == 3) {
            responsive = {
                0: { items: 1 },
                480: { items: 2 },
                768: { items: 2 },
                992: { items: 3 }
            };
        }

        // Default setting
        var sDefault = {
            margin: 30,
            nav: true,
            dots: false,
            items: items,
            responsive: responsive
        };

        this.$carousel.owlCarousel($.extend(sDefault, data));
    }

    componentWillUnmount() {
        if (this.$carousel !== null) {
            this.$carousel.owlCarousel('destroy');
        }
    }
}

CategoryProductWidget.defaultProps = {
    toRight: false,
    uniq: ''
}

const mapStateToProps = (state) => {
    return {
        loader: state.boost.loader,
        product: state.boost[`shop.widget.product`],
        product_view: state.boost.product_view,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CategoryProductWidget);