import React from 'react';
import { HomeView } from 'grips';
import Lang from './lang';

class Home extends React.PureComponent {

    lang = Lang[global.language];

    renderGrips(area) {
        return (
            HomeView[area].map((Controller, key) => {
                return (
                    <React.Fragment key={key}>
                        {
                            !!Controller.innerRender
                                ?
                                <Controller.innerRender { ...this.props.signpost}/>
                                :
                                <Controller { ...this.props.signpost}/>
                        }
                    </React.Fragment>
                );
            })
        )
    }

    render() {
        return (
            <React.Fragment>
                <section>
                    {this.renderGrips(0)}
                </section>

                <section className='border-bottom border-top'>
                    <div className='container'>
                        <div className='policy-wrapper'>

                            <div className='row'>

                                <div className='col-md-4 col-sm-4'>
                                    <div className='policy'>
                                        <div className='policy-icon'>
                                            <i className='icon icon-dolar-circle'></i>
                                        </div>

                                        <div className='policy-text'>
                                            <h4>{this.lang.safe_shopping}</h4>
                                            <p>{this.lang.online_24h}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-4'>
                                    <div className='policy'>
                                        <div className='policy-icon'>
                                            <i className='icon icon-telephone'></i>
                                        </div>

                                        <div className='policy-text'>
                                            <h4>{this.lang.easy_contact}</h4>
                                            <p>{this.lang.call}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-4 col-sm-4'>
                                    <div className='policy'>
                                        <div className='policy-icon'>
                                            <i className='icon icon-user-circle'></i>
                                        </div>

                                        <div className='policy-text'>
                                            <h4>{this.lang.quality_quarantee}</h4>
                                            <p>{this.lang.easy_shopping}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section>
                    <div className='container'>

                        <div className='home-products padding-vertical-60'>
                            {this.renderGrips(1)}
                        </div>

                    </div>
                </section>

                <div className='container'>
                    <div className='divider'></div>
                </div>

                {/* {this.renderGrips(3)} */}
                
            </React.Fragment>
        )
    }
}

export default Home;