import React from "react";
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Lang from '../lang/';
import Helper from 'helpers';
import API from 'boost/API';

class CartWidget extends React.PureComponent {

    state = {
        module_url: ''
    }

    lang = Lang[global.language];

    render() {
        return (
            <li className='menubar-cart'>
                <Link to={`${this.state.module_url}/${this.lang.slug.cart}`} title='' className='awemenu-icon menu-shopping-cart'>
                    <i className='icon icon-shopping-bag'></i>
                    <span className='awe-hidden-text'>Cart</span>
                    {
                        this.props.cart && this.props.cart.length > 0 && <span className='cart-number'>{this.cartCount()}</span>
                    }
                </Link>

                <ul className='submenu megamenu'>
                    <li>
                        <div className='container-fluid'>
                            {
                                this.props.cart && this.props.cart.length > 0  ?
                                <React.Fragment>
                                    <ul className='whishlist'>
                                        {
                                            this.props.cart.map((product, key) =>
                                                <li key={key}>
                                                    <div className='whishlist-item'>
                                                        <div className='product-image'>
                                                            <Link to={`${this.state.module_url}/${product.slug}`} title={product.name}>
                                                            {
                                                                product.images.length ?
                                                                    <div className='border'><img src={product.images[0].thumb} alt='' /></div>
                                                                    :
                                                                    <div className='border'><img src='/themes/default/img/samples/products/cart/1.jpg' alt='' /></div>
                                                            }
                                                            </Link>
                                                        </div>

                                                        <div className='product-body'>
                                                            <div className='whishlist-name'>
                                                                <h3><Link to={`${this.state.module_url}/${product.slug}`} title={product.name}>{product.name}</Link></h3>
                                                            </div>

                                                            <div className='whishlist-price'>
                                                                <span>{this.lang.price}: </span>
                                                                <strong>{`${Helper.currencyFormatter().format(product.price)}`}</strong>
                                                            </div>

                                                            <div className='whishlist-quantity'>
                                                                <span>{this.lang.qty}: </span>
                                                                <span>{product.count}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <a
                                                        title='' 
                                                        className='remove cursor-pointer'
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.removeFromCart(key);
                                                            return false;
                                                        }}
                                                    >
                                                        <i className='icon icon-remove'></i>
                                                    </a>
                                                </li>
                                            )
                                        }
                                    </ul>
                                    <div className='menu-cart-total'>
                                        <span>{this.lang.amount_title}</span>
                                        <span className='price'>{`${Helper.currencyFormatter().format(this.calculateTotal())}`}</span>
                                    </div>
                                    <div className='cart-action'>
                                        <Link to={`${this.state.module_url}/${this.lang.slug.cart}`} title='' className='btn btn-lg btn-dark btn-outline btn-block'>{this.lang.view_cart}</Link>
                                        <Link to={`${this.state.module_url}/${this.lang.slug.checkout}`} title='' role='button' className='btn btn-lg btn-primary btn-block'>
                                            {this.lang.process_checkout}
                                        </Link>
                                    </div>
                                </React.Fragment>
                                :
                                <p>{this.lang.cart_empty}</p>
                            }
                        </div>
                    </li>
                </ul>
            </li>
        )
    }

    /**
     * Zlicza sumaryczną ilość sztuk w koszyku
     * 
     * @return {Integer}
     */
    cartCount = () => {
        return this.props.cart.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.count
        }, 0);
    }

    calculateTotal() {
        let total_price = 0;
        this.props.cart.map(product => {
            total_price += product.price * ( product.count || 1);
        })
        return total_price;
    }

    componentDidMount() {
        if(this.props.route_map) {
            let to_url = this.props.route_map.filter(element => element.module == 'shop' && element.slug != '');
            if(to_url.length) {
                this.setState({module_url: `/${to_url[0].slug}`});
            }
        }
        this.loadCartFromLocalStorage();
        this.props.updateValue({ state: 'errors', data: {} });
    }

    removeFromCart = (index) => {
        let { cart } = this.props;
        cart = [ ...cart ];
        cart[index].count = 1;
        cart.splice(index, 1);
        this.calculateCart(cart);
        this.updateCartTooltip(this.lang.item_removed_shopping_cart);
    }

    loadCartFromLocalStorage() {
        let cart = localStorage.getItem('shop.cart');
        this.props.updateValue({ state: 'shop.widget.cart', data: JSON.parse(cart) });
        this.props.updateValue({ state: 'shop.widget.cart.add', data: this.addToCart });
        this.props.updateValue({ state: 'shop.widget.cart.remove', data: this.removeFromCart });
        this.props.updateValue({ state: 'shop.widget.cart.calculate', data: this.calculateCart })
    }

    addToCart = (product = false, params = false) => {
        let { cart } = this.props;
        if(!cart) {
            cart = [];
        }
        let count = params.update_count;
        if(product) {
            let id_array_product;
            let exist_product = cart.filter((element, index) => {
                if(element.id == product.id) {
                        id_array_product = index;
                        return true;
                }
            });
            if(exist_product.length) {
                if(cart[id_array_product].count + count < cart[id_array_product].quantity) {
                    cart[id_array_product].count += count;
                } else {
                    cart[id_array_product].count = cart[id_array_product].quantity;
                }
            } else {
                product.count = count;
                cart.push(product);
            }
        } else {
            if(params.id !== null ) {
                cart[params.id].count = count;
                if (params.price) {
                    cart[params.id].price = params.price;
                }
            }
        }

        this.calculateCart(cart);
    }

    /**
     * Aktualizuje wartość koszyka
     * 
     * @param {Array} cart - Koszyk
     * @return {undefined}
     */
    updateCart(cart) {
        this.props.updateValue({ state: 'shop.widget.cart', data: cart });
        localStorage.setItem('shop.cart', JSON.stringify(cart));
        this.forceUpdate();
    }

    /**
     * Wyświetla tooltip informulący o zmianie wartości koszyka
     * 
     * @param {String} message - Komunikat
     * @return {undefined}
     */
    updateCartTooltip(message) {
        toastr.success(message);
    }

    /**
     * Przelicza wartosc koszyka
     * 
     * @return {undefined}
     */
    calculateCart = (cart) => {
        let cart_calculate = [];

        if (!cart.length) {
            this.updateCart(cart_calculate);
            return;
        }

        let code = this.getCode();

        let order_items = cart.map(variation => ({
            variation_id: variation.id,
            quantity: variation.count
        }));

        let data = { code, order_items };

        API.post('/api/shop/basket/calculate', data, false, false, { indices: true }).then(res => {
            if (!res.error) {

                let data = res.data;
                let code_error = data.code_error;
                let data_data = data.data;

                if (code_error && code !== '') {
                    this.props.updateValue({ state: 'code' });
                    localStorage.removeItem('shop.cart.code');
                    toastr.error(res.data.message);
                } else {
                    this.updateCartTooltip(this.lang.cart_value_updated);
                }

                for (let i = 0; i < cart.length; i++) {
                    if (typeof data_data === 'undefined') {
                        return;
                    }
                    for (let j = 0; j < data_data.length; j++) {
                        let cart_item = cart[i];
                        let res_item = data_data[j]

                        if (cart[i].id == data_data[j].variation_id) {
                            cart_calculate.push({ ...cart_item, ...{ price: res_item.price } });
                        }               
                    }
                }
                this.updateCart(cart_calculate);
            } else {
                this.props.updateValue({ state: 'errors', data: res.data.errors });
                this.props.updateValue({ state: 'code' });
                localStorage.removeItem('shop.cart.code');
            }
        });

    }



    /**
     * Zwraca kod rabatowy
     * 
     * @return {String}
     */
    getCode() {
        if (localStorage.getItem('shop.cart.code') !== null) {
            return localStorage.getItem('shop.cart.code');
        }
        return '';
    }

}
CartWidget.defaultProps = {

}

const mapStateToProps = (state) => {
    return {
        loader: state.boost.loader,
        cart: state.boost[`shop.widget.cart`]
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CartWidget);