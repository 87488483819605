export default {
    shop: 'shop',
    page: 'page',
    slug_user_account: 'user-account',
    // slugi
    slug: {
        shop: 'sklep',
        page: 'strona',
        slug_user_account: 'konto-uzytkownika'
    },
    server_temporarily_unavailable: 'Server temporarily unavailable. Please try again later.'
}