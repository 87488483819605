import React from 'react';
import Lang from '../lang';
import { Link } from 'react-router-dom';
import Loader from 'partials/loader';
import API from 'boost/API';

class LostPassword extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        loader: false,
        email: '',
        errors: {}
    }

    render() {

        let { loader, email } = this.state;

        return (
            !loader ?
            <React.Fragment>

                <div className='main-header background' style={{ background: 'linear-gradient(rgba(28, 28, 28, 0.3), rgba(28, 28, 28, 0.3)), url("/themes/default/img/backgrounds/background-about.jpg")' }}>
                    <div className="container">
                        <h1 className="main-header-title text-center block">
                            <span>{this.lang.lost_password_title}</span>
                        </h1>
                    </div>
                </div>

                <div id='breadcrumb'>
                    <div className='container'>
                        <ol className='breadcrumb'>
                            <li><Link to='/'>{this.lang.home}</Link></li>
                            <li><span>{this.lang.user_account_title}</span></li>
                            <li className='active'><span>{this.lang.lost_password_title}</span></li>
                        </ol>

                    </div>
                </div>

                <div className='login-register-page woocommerce-account'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <article id='page-10' className='post-10 page type-page status-publish hentry'>
                                    <div className='woocommerce'>
                                        <div className="woocommerce-notices-wrapper"></div>
                                        <form className="woocommerce-ResetPassword lost_reset_password" onSubmit={this.submit}>
                                            
                                            <p>{this.lang.not_remember_password_desc_title}</p>
                                            
                                            <div className="woocommerce-form-row woocommerce-form-row--first form-row form-row-first form-group">
                                                <label htmlFor="email">{this.lang.email}</label>
                                                <input className="woocommerce-Input woocommerce-Input--text input-text form-control" value={email} onChange={this.updateState} type="email" name="email" id="email" autoComplete="email" placeholder={this.lang.add_email}/>
                                                {this.showError('email')}
                                            </div>

                                            <div className="woocommerce-form-row form-row form-group">
                                                <button type="submit" className="btn btn-primary">{this.lang.reset_password_title}</button>
                                            </div>

                                        </form>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
            :
            <div className='preloader'>
                <div className='preloader-wrapper'>
                    <Loader type='small'/>
                </div>
            </div>
        );
    }

    /**
     * Aktualizuje dane state
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    /**
     * Przechwytuje zdarzenie wysłania danych formularza odzyskiwania hasła
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    submit = (e) => {
        e.preventDefault();
        this.showLoader();
        API.post(`/api/password/email`, { email: this.state.email } ).then(res => {
            this.clearErrors();
            if (!res.error) {
                toastr.success(res.data.message);
            } else {
                if (res.data.errors) {
                    this.setState({
                        errors: res.data.errors
                    })
                } else {
                    toastr.error(res.data.message);
                }
            }
            this.showLoader();
        })
    }

    /**
     * Wyświetla lub ukrywa loader
     * 
     * @return {undefined}
     */
    showLoader = () => {
        this.setState({ loader: !this.state.loader });
    }

    /**
     * Wyświetla błędy walidacji
     * 
     * @param {String} name - Nazwa input
     * @return {undefined}
     */
    showError = (name) => {
        let { errors } = this.state;
        if (errors[name]) {
            return (
                <React.Fragment>
                    {
                        errors[name].map((error, key) =>
                            <div className={`text-danger`}  key={key}>{error}</div>
                        )
                    }
                </React.Fragment>
            )
        }
    }

    /**
     * Usuwa z obiektu błedów informacje o błedach walidacji pól formularza
     * 
     * @return {undefined} 
     */
    clearErrors = () => {
        this.setState({
            errors: {}
        }); 
    }

}

export default LostPassword;