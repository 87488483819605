import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue, setCategoryIds } from 'boost/dispatch';
import Lang from '../lang';

class CategoryTreeWidget extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        category_list: [],
        children: this.props.children ? true : false,
        category_id: 0
    }

    render() {
        let { category_list } = this.state;
        return (
            <React.Fragment>
                {!this.state.children ? <h4 className='text-uppercase widget-title'><span>{this.lang.categories}</span></h4> : ''}
                <ul>
                    {
                        category_list?.map((category, key) => {
                            let category_id = category.id;
                            let show = (this.state.category_id === category_id) || this.props.category_branch[category_id];
                            return (
                                <li key={key} data-category-id={category_id} ref={this.setRef}>
                                    <a 
                                        onClick={e => {
                                            e.preventDefault();
                                            this.handleClickCategory(category);
                                            return false;
                                        }}
                                        className="cursor-pointer"
                                    >{category.name}</a>
                                    { show && <CategoryTreeWidget { ...this.props } category_id={category_id} children={true} /> }
                                </li>
                            )
                        })
                    }
                </ul>
            </React.Fragment>
        )
    }

    componentDidMount() {
        this.loadCaregory();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            if (prevProps.reset_products_list) {
                // Zwija liste kategorii do poziomu rodziców
                this.resetCategoriesList();
                // Usuwa klasę active z elementów li
                this.removeActiveClass();
            }
        }
        if (this.props.filters && this.props.filters.category) {
            // Usuwa klasę active z elementów li
            this.removeActiveClass();
            // Oznacza element li jako kliknięty
            this.setActiveClass(this.props.filters.category);
        }
    }

    /**
     * Resetuje listę kategorii do kategorii głównych
     * 
     * @return { undefined }
     */
    resetCategoriesList() {
        let category = JSON.parse(localStorage.getItem('shop.client.category'));

        if (category !== null ) {
            // Resetujemy ustawioną gałąź kategorii
            this.props.updateValue({ state: 'category_branch' });
            // Ustawiamy, że przy kolejnej aktualizacji komponentu widok listy produktów nie ma być resetowany
            // this.props.updateValue({ state: 'reset_shop_products_list', data: false });
            // Ustawiamy w store tylko kategorie główne
            this.setState({ category_list: category[0] });
        }
    }

    /**
     * Pobiera kategorie zapisane w localStorage
     * 
     * @return { object }
     */
    getLocalStorageShopClientCategory() {
        return JSON.parse(localStorage.getItem('shop.client.category'));
    }

    /**
     * Zapisuje kategorie w localStorage
     * 
     * @param { object } category - Kategorie
     * @return { undefined }
     */
    setLocalStorageShopClientCategory(category) {
        localStorage.setItem('shop.client.category', JSON.stringify(category));
    }

    /**
     * Zapisuje kategorie dzieci w localStorage
     * 
     * @param { integer } category_id - Identyfikator kategorii
     * @return { undefined }
     */
    setChildrenCategory(category_id) {
        this.props.load({ url: '/api/shop/categories', state: 'category_list', data: { id: category_id, per_page: 100 }, param_result: 'data' }).then(res => {
            let storage_category = this.getLocalStorageShopClientCategory();
            let category = { ...storage_category, ...{ [category_id]: res } };
            this.setLocalStorageShopClientCategory(category);
            this.loadData(res);
        })
    }

    /**
     * Zapisuje kategorie rodziców w localStorage
     * 
     * @return { undefined }
     */
    setParentCategory() {
        this.props.load({ url: '/api/shop/categories', state: 'category_list', data: { per_page: 100 }, param_result: 'data' }).then(res => {
            let category = {0: res};
            this.setLocalStorageShopClientCategory(category);
            this.loadData(res);
        })
    }

    /**
     * Pobiera kategorie z api i zapisuje je w localStorage
     * 
     * @return { undefined }
     */
    loadCaregory() {
        let category_id = this.props.category_id;

        if (category_id) {
            let storage_category = this.getLocalStorageShopClientCategory();
            if (storage_category[category_id]) {
                this.loadData(storage_category[category_id]);
            } else {
                this.setChildrenCategory(category_id);
            }
        } else {
            if (localStorage.getItem('shop.client.category') !== null) {
                let storage_category = this.getLocalStorageShopClientCategory();
                this.loadData(storage_category[0]);
            } else {
                this.setParentCategory();
            }
        }
    }

    /**
     * Zapisuje listę pobranych kategorii w state
     * 
     * @param { array } category_list - Lista kategorii
     * @return { undefined }
     */
    loadData(category_list) {
        this.setState({ category_list });
    }

    /**
     * Przechwytuje zdarzenie kliknięcia wybranej kategorii
     * 
     * @param { object } category - Obiekt kategorii
     * @param { string } category.id - Identyfikator kategorii
     * @param { string } category.slug - Slug kategorii
     * @return { undefined }
     */
    handleClickCategory({ id, slug }) {
        this.setCategoryBranch(id);
        this.removeActiveClass();
        this.setCategorySlug(slug);
        this.setClickedCategory(id);
        this.setUrl(slug);
        this.scrollToFilters();
    }

    scrollToFilters = () => {
        document.getElementById('shop-widgets-filters-category').scrollIntoView();
    }

    /**
     * Ustawia slug kategorii w reduxowym store
     * 
     * @param {String} slug - Nazwa kategorii 
     */
    setCategorySlug(slug) {
        this.props.updateValue({ state: 'shop_category_slug', data: slug });
    }

    /**
     * Ustawia gałąź kategorii w reduxowym store
     * 
     * @param { integer } id - Identyfikator kategorii
     * @return { undefined }
     */
    setCategoryBranch = (id) => {
        let category_branch = this.createCategoryBranch(id);
        this.props.updateValue({ state: 'category_branch', data: category_branch });
    }

    /**
     * Tworzy gałąź klikniętej kategorii
     * 
     * @param { integer } id - Identyfikator kategorii
     * @return { object }
     */
    createCategoryBranch = (id) => {
        let category = JSON.parse(localStorage.getItem('shop.client.category'));
        for (let property in category) {
            if (category.hasOwnProperty(property)) {
                let category_arr = category[property].filter(element => element.id === id);
                if (category_arr.length) {
                    let current_category = category_arr[0];
                    return { ...{ [current_category.id]: current_category }, ...this.createCategoryBranch(current_category.parent_id) };
                }
            }
        }
        return {};
    }


    /**
     * Zapisuje w state identyfikator wybranej kategorii
     * 
     * @param { integer } category_id - Identyfikator kategorii
     * @return { undefined }
     */
    setClickedCategory(category_id) {
        this.setState({ category_id })
        this.props.updateValue({ state: 'poduct_list_filters', data: { category:  category_id }, join: true });
    }

    /**
     * Ustawia url przeglądarki na odpowiedni dla wybranej kategorii
     * 
     * @param { string } slug - Slug kategorii
     * @return { undefined }
     */
    setUrl(slug) {
        window.history.pushState('', '', `/${this.lang.slug.shop}/${this.lang.slug.category}/${slug}`);
    }

    /**
     * Zapisuje w store identyfikatory wszystkich kategorii, które zostały już wyświetlone
     * 
     * @param { object } ref - Obiekt referencji
     * @return { undefined }
     */
    setRef = (ref) => {
        if (ref) {
            let category_id = ref.dataset.categoryId;
            this.props.setCategoryIds({ ...this.props.category_ids, ...{[category_id]: category_id}});
        }
    };

    /**
     * Usuwa klasę active z ze wszystkich elementów listy
     * 
     * @return { undefined }
     */
    removeActiveClass() {
        let category_ids = this.props.category_ids;
        for (let property in category_ids) {
            if (category_ids.hasOwnProperty(property)) {
                let category_id = category_ids[property];
                let li = document.querySelector(`[data-category-id='${category_id}']`);
                if (li !== null) {
                    li.classList.remove('active');
                }
            }
        }
    }

    /**
     * Dodaje klasę active dla elementu listy wybranej kategorii
     * 
     * @param { integer } category_id - Identyfikator kategorii
     * @return { undefined }
     */
    setActiveClass(category_id) {
        let li = document.querySelector(`[data-category-id='${category_id}']`);
        if (li !== null) {
            li.className = 'active';
        }
    }

}

CategoryTreeWidget.defaultProps = {
    category_branch: {}
}

const mapStateToProps = (state) => {
    return {
        category_ids: state.boost.category_ids,
        category_name: state.boost.category_name,
        category_branch: state.boost.category_branch,
        filters: state.boost.poduct_list_filters,
        reset_products_list: state.boost.reset_shop_products_list
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue,
    setCategoryIds
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CategoryTreeWidget);