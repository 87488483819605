import React from 'react';
import Lang from '../lang';
import Loader from 'partials/loader';
import { bindActionCreators } from 'redux';
import API from 'boost/API';
import { connect } from 'react-redux';
import { updateValue } from 'boost/dispatch';

class EditAccount extends React.PureComponent {

    lang = Lang[global.language];

    state = { 
        id: 0,
        email: '',
        name: '',
        first_name: '',
        last_name: '',
        firm: '',
        nip: '',
        phone_number: '',
        personal_information: false,
        terms_of_use: false,
        newsletter: false,
        password: '',
        password_confirmation: '',
        loader: false,   
        errors: {}
    }

    render() {

        let { loader } = this.state;

        return (
            !loader ?
            <section className="woocommerce-order-details">

                <form onSubmit={this.submit} className='woocommerce-EditAccountForm edit-account'>

                    <input type='hidden' name='id' value={this.state.id} />

                    <div className='form-group'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor='first_name'>{this.lang.first_name_title}</label>
                                    <input type='text' className='form-control' id='first_name' name='first_name' value={this.state.first_name} onChange={this.updateState} placeholder={this.lang.add_first_name_title} />
                                    {this.showError('first_name')}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor='last_name'>{this.lang.last_name_title}</label>
                                    <input type='text' className='form-control' id='last_name' name='last_name' value={this.state.last_name} onChange={this.updateState} placeholder={this.lang.add_last_name_title} />
                                    {this.showError('last_name')}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='form-group'>
                        <label htmlFor='name'>{this.lang.name_title} <sup>*</sup></label>
                        <input type='text' className='form-control' id='name' name='name' value={this.state.name} onChange={this.updateState} placeholder={this.lang.add_name_title} required />
                        {this.showError('name')}
                    </div>

                    <div className='form-group'>
                        <label htmlFor='email'>{this.lang.email} <sup>*</sup></label>
                        <input type='email' className='form-control' id='email' name='email' value={this.state.email} onChange={this.updateState} placeholder={this.lang.add_email} autoComplete='new-email' required />
                        {this.showError('email')}
                    </div>

                    <div className='form-group'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor='firm'>{this.lang.firm_title}</label>
                                    <input type='text' className='form-control' id='firm' name='firm' value={this.state.firm} onChange={this.updateState} placeholder={this.lang.add_firm_title} />
                                    {this.showError('firm')}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor='nip'>{this.lang.nip_title}</label>
                                    <input type='text' className='form-control' id='nip' name='nip' value={this.state.nip} onChange={this.updateState} placeholder={this.lang.add_nip_title} />
                                    {this.showError('nip')}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='form-group'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label htmlFor='phone_number'>{this.lang.phone_number_title}</label>
                                    <input type='tel' pattern='^((([0]{2}|\+)\d{2}[- ]?)?(\d{3}[- ]?\d{3}[- ]?\d{3})|(([0]{2}|\+)\d{2}[- ]?)?((\(\d{2}\)|\d{2})[- ]?)(\d{3}[- ]?\d{2}[- ]?\d{2}|\d{2}[- ]?\d{2}[- ]?\d{3}))$' className='form-control' id='phone_number' name='phone_number' value={this.state.phone_number} onChange={this.updateState} placeholder={this.lang.add_phone_number_title} />
                                    {this.showError('phone_number')}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='form-group'>
                        <input type='checkbox' name='personal_information' id='personal_information' className='personal_information' value={this.state.personal_information} checked={this.state.personal_information} onChange={this.onCheckboxChange} />
                        <label htmlFor='personal_information'>{this.lang.personal_information}</label>
                        {this.showError('personal_information')}
                    </div>

                    <div className='form-group'>
                        <input type='checkbox' name='terms_of_use' id='terms_of_use' className='terms_of_use' value={this.state.terms_of_use} checked={this.state.terms_of_use} onChange={this.onCheckboxChange} />
                        <label htmlFor='terms_of_use'>
                            {this.lang.agree_with}
                            <a onClick={e => {
                                e.preventDefault();
                                this.goto('/strona/regulamin');
                                return false;
                            }}>{this.lang.site_regulations}</a>
                        </label>
                        {this.showError('terms_of_use')}
                    </div>

                    <div className='form-group'>
                        <input type='checkbox' name='newsletter' id='newsletter' className='newsletter' value={this.state.newsletter} checked={this.state.newsletter} onChange={this.onCheckboxChange} />
                        <label htmlFor='newsletter'>{this.lang.subscribe_newsletter}</label>
                        {this.showError('newsletter')}
                    </div>

                    <fieldset>
                        <legend>{this.lang.password_change_title}</legend>
                        <div className='form-group'>
                            <label htmlFor='password'>{this.lang.new_password_title}</label>
                            <input type='password' className='form-control' id='password' name='password' value={this.state.password} onChange={this.updateState} placeholder={this.lang.add_new_password_title} autoComplete='new-password' />
                            {this.showError('password')}
                        </div>
                        <div className='form-group'>
                            <label htmlFor='password_confirmation'>{this.lang.confirm_new_password_title}</label>
                            <input type='password' className='form-control' id='password_confirmation' name='password_confirmation' value={this.state.password_confirmation} onChange={this.updateState} placeholder={this.lang.add_confirm_new_password_title} autoComplete='new-password' />
                            {this.showError('password_confirmation')}
                        </div>
                    </fieldset>

                    <div className='form-submit margin-top-10'>
                        <button type='submit' className='btn btn-primary'>{this.lang.save_title}</button>
                    </div>

                </form>
            </section>
            :
            <div className='preloader'>
                <div className='preloader-wrapper'>
                    <Loader type='small'/>
                </div>
            </div>
        );
    }

    componentDidMount() {
        if (typeof this.props.user !== 'undefined') {
            let user = this.props.user.data;

            let state = {
                id: user.contractor_id,
                email: user.email || '',
                name: user.name || '',
                first_name: user.first_name || '',
                last_name: user.last_name || '',
                firm: user.firm || '',
                nip: user.nip || '',
                phone_number: user.phone_number || '',
                personal_information: user.personal_information,
                terms_of_use: user.terms_of_use ,
                newsletter: user.newsletter
            };

            this.setState(state);
        }
    }

    /**
     * Aktualizuje wartość state'ów
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    /**
     * Przechwytuje zdarzenie wysłania danych formularza odzyskiwania hasła
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    submit = (e) => {
        e.preventDefault();
        this.showLoader();
        this.clearErrors();
        let data = { ...this.state };
        delete data.loader;
        delete data.errors;

        if (data.password === '') {
            delete data.password;
        }

        if (data.password_confirmation === '') {
            delete data.password_confirmation;
        }

        API.put(`/api/shop/customer/account`, data ).then(res => {
            if (!res.error) {
                let user = this.props.user.data;
                this.props.updateValue({ state: 'user', data: { data: { ...user, ...data } } });
                toastr.success(res.data.message);
            } else {
                if (res.data.errors) {
                    this.setState({
                        errors: res.data.errors
                    })
                } else {
                    toastr.error(res.data.message);
                }
            }
            this.showLoader();
        });
    }

    /**
     * Wyświetla lub ukrywa loader
     * 
     * @return {undefined}
     */
    showLoader = () => {
        this.setState({ loader: !this.state.loader });
    }

    /**
     * Wyświetla błędy walidacji
     * 
     * @param {String} name - Nazwa input
     * @return {undefined}
     */
    showError = (name) => {
        let { errors } = this.state;
        if (errors[name]) {
            return (
                <React.Fragment>
                    {
                        errors[name].map((error, key) =>
                            <div className={`text-danger`}  key={key}>{error}</div>
                        )
                    }
                </React.Fragment>
            )
        }
    }

    /**
     * Usuwa z obiektu błedów informacje o błedach walidacji pól formularza
     * 
     * @return {undefined} 
     */
    clearErrors = () => {
        this.setState({
            errors: {}
        }); 
    }

    /**
     * Przenosi na określony adres url
     * 
     * @param {String} url - Adres url
     * @return {undefined}
     */
    goto = (url) => {
        window.location.href = url;
    }

    /**
     * Aktualizuje wartość state'ów
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    onCheckboxChange = (e) => {
        this.setState({[e.target.name]: !(e.target.value === 'true')});
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.boost.user
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateValue
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAccount);