export default {
    shop: 'sklep',
    page: 'strona',
    slug_user_account: 'konto-uzytkownika',
    // slugi
    slug: {
        shop: 'sklep',
        page: 'strona',
        slug_user_account: 'konto-uzytkownika'
    },
    server_temporarily_unavailable: 'Serwer chwilowo niedostępny. Spróbuj ponownie później.'
}