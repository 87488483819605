export default {
    loginTitle: 'Zaloguj',
    register: 'Zarejestruj',
    login: 'Login',
    password: 'Hasło',
    forgot_password: 'Zapomniałeś hasła ?',
    username: 'Użytkownik',
    email: 'E-mail',
    add_email: 'Podaj adres e-mail',
    confirm_password: 'Powtórz hasło',
    add_confirm_password: 'Podaj powtórzone hasło',
    orders: 'zamówienia',
    login_register_title: 'Logowanie i rejestracja',
    lost_password_title: 'Odzyskiwanie hasła',
    user_account_title: 'Konto użytkownika',
    slug_orders: 'zamowienia',
    shop_slug: 'sklep',
    slug_comments: 'komentarze',
    slug_add_comment: 'dodaj-komentarz',
    slug_add_comment_order: 'komentarz-zamowienia',
    slug_add_comment_product: 'komenarz-produktu',
    slug_login_register: 'logowanie-rejestracja',
    email_address: 'Adres e-mail',
    add_email_address: 'Podaj adres e-mail',
    user_password: 'Hasło',
    add_password: 'Podaj hasło',
    remember_me: 'Zapamiętaj mnie',
    personal_information: 'Wyrażam zgodę na przetwarzanie moich danych osobowych',
    agree_with: 'Zgadzam się z ',
    site_regulations: ' regulaminem serwisu',
    subscribe_newsletter: 'Chcę zapisać się do newslletera',
    incorrect_login_or_password: 'Niepoprawny login lub hasło',
    sign: 'Logowanie',
    registration: 'Rejestracja',
    logged_in: 'Zostałeś zalogowany',
    boardTitle: 'Tablica',
    commentsTitle: 'Komentarze',
    ordersTitle: 'Zamówienia',
    logoutTitle: 'Wyloguj',
    myAccountTitle: 'Moje konto',
    welcomeUserTitle: 'Witaj użytkowniku',
    order_details: 'Szczegóły zamówienia',
    product_name: 'Nazwa produktu',
    quantity_title: 'Ilość',
    price_title: 'Cena',
    value_title: 'Wartość',
    summary_title: 'Podsumowanie',
    shipment_title: 'Wysyłka',
    courier: 'kurier',
    together_title: 'Razem',
    payment_status_title: 'Status płatności',
    payment_method_title: 'Metoda płatności',
    download_invoice_title: 'Pobierz fakturę',
    lost_password_slug: 'odzyskiwanie-hasla',
    not_remember_password_title: 'Nie pamiętasz hasła ?',
    not_remember_password_desc_title: 'Zapomniałeś hasła ? Wpisz adres e-mail. Dostaniesz link do stworzenia nowego hasła mailem.',
    reset_password_title: 'Resetuj hasło',
    accountDetailsTitle: 'Szczegóły konta',
    slug_edit_account: 'edycja-konta',
    name_title: 'Nazwa',
    add_name_title: 'Podaj nazwę',
    first_name_title: 'Imię',
    add_first_name_title: 'Podaj imię',
    last_name_title: 'Nazwisko',
    add_last_name_title: 'Podaj nazwisko',
    firm_title: 'Firma',
    add_firm_title: 'Podaj nazwę firmy',
    nip_title: 'Nip',
    add_nip_title: 'Podaj nip',
    save_title: 'Zapisz',
    phone_number_title: 'Numer telefonu',
    add_phone_number_title: 'Podaj numer telefonu',
    password_change_title: 'Zmiana hasła',
    new_password_title: 'Nowe hasło (pozostaw puste, aby pozostawić bez zmian)',
    add_new_password_title: 'Podaj nowe hasło',
    confirm_new_password_title: 'Potwierdź nowe hasło',
    add_confirm_new_password_title: 'Powtórz nowe hasło',
    addressesTitle: 'Adresy',
    slug_edit_addresses: 'edycja-adresy',
    slug_edit_address: 'edycja-adres',
    addresses_will_used_desc_title: 'Następujące adresy będą domyślnie używane na stronie realizacji zamówienia.',
    AF_address_title: 'Adres do faktury',
    AW_address_title: 'Adres do wysyłki',
    AK_address_title: 'Adres korespondencyjny',
    not_set_up_address_yet: 'Nie skonfigurowałeś jeszcze tego typu adresu.',
    edit_title: 'Edycja',
    country_title: 'Kraj',
    street_title: 'Ulica',
    add_street_title: 'Podaj ulicę',
    post_code_title: 'Kod pocztowy',
    add_post_code_title: 'Podaj kod pocztowy',
    city_title: 'Miasto',
    add_city_title: 'Podaj miasto',
    cod: 'Za pobraniem',
    paypal: 'PayPal',
    back_title: 'Wróć',
    home: 'strona główna',
    country_name_lang: 'name_pl',
    select: 'Wybierz',
    order: 'Zamówienie',
    date: 'Data',
    status: 'Status',
    total: 'Suma',
    actions: 'Akcje',
    add_comment: 'Dodaj komentarz',
    comment: 'Komentarz',
    add_content_comment: 'Wpisz treść komentarza',
    must_choose_rating: 'Musisz wybrać ocenę',
    your_opinion: 'Twoja ocena',
    add_review: 'Dodaj opinię',
    view: 'Zobacz',
    return: 'Zwrot',
    complaint: 'Reklamacja',
    slug_returns: 'zwroty',
    returns: 'Zwroty',
    slug_complaints: 'reklamacje',
    complaints: 'Reklamacje',
    complaint_reason: 'Powód reklamacji',
    order_number: 'Numer zamówienia',
    order_created_at: 'Data złożenia zamówienia',
    product_information: 'Informacje o produkcie',
    index: 'Indeks',
    slug_returns_and_complaints: 'zwroty-i-reklamacje',
    returns_and_complaints: 'Zwroty i Reklamacje',
    order_total: 'Suma zamówienia',
    quantity: 'Ilość',
    preferred_resolve_method: 'Preferowana metoda rozwiązania',
    disable_return_time: 'Czas na dokonanie zwrotu produktu został przekroczony',
    disable_complaint_time: 'Czas na dokonanie reklamacji został przekroczony',
    slug_return: 'zwrot',
    slug_complaint: 'reklamacja',
    all: 'Wszystko',
    return_reason: 'Przyczyna zwrotu',
    return_created_at: 'Data utworzenia zwrotu',
    complaint_created_at: 'Data złożenia reklamacji',
    details: 'Szczegóły',
    download_pdf_file: 'Pobierz PDF',
    return_order_items: 'Zamówione towary',
    complaint_order_items: 'Zamówione towary',
    sku: 'Sku',
    name: 'Nazwa',
    price: 'Cena',
    status_history: 'Historia zmian statusu',
    customer_notify: 'Powiadomienie klienta',
    complaint_status: 'Status reklamacji',
    yes: 'Tak',
    no: 'Nie',
    comments: 'Komentarze',
    customer: 'Klient',
    your_comment: 'Twój komentarz',
    tables_langs: {
        previousText: 'Poprzedni',
        nextText: 'Następny',
        loadingText: 'Wczytuję...',
        noDataText: 'Brak danych',
        pageText: 'Strona',
        ofText: 'z',
        rowsText: 'wierszy',
    },
    slug_user_account: 'konto-uzytkownika',
    shipping_address: 'Adres wysyłki',
    slug: {
        slug_login_register: 'logowanie-rejestracja',
        lost_password_slug: 'odzyskiwanie-hasla',
        user_account: 'konto-uzytkownika'
    }
}