export default {
    /**
     * Metoda do dynamicznego dodawania scriptów do domumentu. Można dodawać wszystkie
     * na raz lub dodawać z hierarchią tablicy, którą przekazujemy jako parametr
     */
    insertScripts(scripts, hierarchy = false, callback = false) {
        if (hierarchy) {
            let id = typeof hierarchy == 'boolean' ? 0 : hierarchy;
            if (scripts[id]) {
                let test = document.querySelector('script[src="'+scripts[id]+'"]');
                if(!test) {
                    let script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = scripts[id];
                    document.getElementsByTagName('body')[0].appendChild(script);
                    if (script.readyState) {  //IE
                        script.onreadystatechange = () => {
                            if (script.readyState === "loaded" || script.readyState === "complete") {
                                script.onreadystatechange = null;
                                this.insertScripts(scripts, id + 1, callback);
                            }
                        };
                    } else {  //Others
                        script.onload =  () => {
                            this.insertScripts(scripts, id + 1, callback);
                        };
                    }
                } else {
                    this.insertScripts(scripts, id + 1, callback);
                }
            } else {
                if(callback) {
                    callback(true);
                }
            }
        } else {
            scripts.map(url => {
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                document.getElementsByTagName('body')[0].appendChild(script);
            })
        }
    },
    insertStyle(scripts, hierarchy = false, callback = false) {
        if (hierarchy) {
            let id = typeof hierarchy == 'boolean' ? 0 : hierarchy;
            if (scripts[id]) {
                let test = document.querySelector('link[src="'+scripts[id]+'"]');
                if(!test) {
                    let script = document.createElement('link');
                    script.type = 'text/css';
                    script.href = scripts[id];
                    document.getElementsByTagName('body')[0].appendChild(script);
                    if (script.readyState) {  //IE
                        script.onreadystatechange = () => {
                            if (script.readyState === "loaded" || script.readyState === "complete") {
                                script.onreadystatechange = null;
                                this.insertStyle(scripts, id + 1, callback);
                            }
                        };
                    } else {  //Others
                        script.onload =  () => {
                            this.insertStyle(scripts, id + 1, callback);
                        };
                    }
                } else {
                    this.insertStyle(scripts, id + 1, callback);
                }
            } else {
                if(callback) {
                    callback(true);
                }
            }
        } else {
            scripts.map(url => {
                let script = document.createElement('link');
                script.type = 'text/css';
                script.href = url;
                script.rel = 'stylesheet';
                document.getElementsByTagName('head')[0].appendChild(script);
            })
        }
    },

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    currencyFormatter(locales = 'pl-PL', style = 'currency', currency = 'PLN', minimumFractionDigits = 2) {
        return new Intl.NumberFormat(locales, {
            style: style,
            currency: currency,
            minimumFractionDigits: minimumFractionDigits
          });          
    }
}
