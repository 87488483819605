export default {
    loginTitle: 'Sign in',
    register: 'Register',
    login: 'Login',
    password: 'Password',
    forgot_password: 'Forgot your password?',
    username: 'User',
    email: 'Email',
    add_email: 'Enter email addresses',
    confirm_password: 'Set password',
    add_confirm_password: 'Confirm password',
    orders: 'orders',
    login_register_title: 'Sign in or register',
    lost_password_title: 'Recover password',
    user_account_title: 'User account',
    slug_orders: 'orders',
    shop_slug: 'shop',
    slug_comments: 'comments',
    slug_add_comment: 'add-comment',
    slug_add_comment_order: 'comment-order',
    slug_add_comment_product: 'comment-product',
    slug_login_register: 'login-registration',
    email_address: 'Email address',
    add_email_address: 'Enter email address',
    user_password: 'Password',
    add_password: 'Enter password',
    remember_me: 'Remember me',
    personal_information: 'I agree on the processing of personal data',
    agree_with: 'I agree with ',
    site_regulations: ' terms & conditions',
    subscribe_newsletter: 'I want to subscribe newsletter',
    incorrect_login_or_password: 'Incorrect login or password',
    sign: 'Sign in',
    registration: 'Registraion',
    logged_in: 'You were logged in',
    boardTitle: 'Dashboard',
    commentsTitle: 'Comments',
    ordersTitle: 'Orders',
    logoutTitle: 'Sign out',
    myAccountTitle: 'My account',
    welcomeUserTitle: 'Welcome',
    order_details: 'Order details',
    product_name: 'Product name',
    quantity_title: 'Quantity',
    price_title: 'Price',
    value_title: 'Value',
    summary_title: 'Summary',
    shipment_title: 'Shipment',
    courier: 'Courier',
    together_title: 'Total',
    payment_status_title: 'Payment status',
    payment_method_title: 'Payment method',
    download_invoice_title: 'Download invoice',
    lost_password_slug: 'password-recovery',
    not_remember_password_title: 'Forgot your password?',
    not_remember_password_desc_title: 'Forgot your password? Enter your email address. You will receive an email with a link to create new password.',
    reset_password_title: 'Reset password',
    accountDetailsTitle: 'Account details',
    slug_edit_account: 'edit-account',
    name_title: 'Name',
    add_name_title: 'Enter name',
    first_name_title: 'Name',
    add_first_name_title: 'Enter name',
    last_name_title: 'Surname',
    add_last_name_title: 'Enter surname',
    firm_title: 'Company',
    add_firm_title: 'Enter company name',
    nip_title: 'NIP',
    add_nip_title: 'Enter NIP',
    save_title: 'Save',
    phone_number_title: 'Phone number',
    add_phone_number_title: 'Enter phone number',
    password_change_title: 'Change password',
    new_password_title: 'New password (leave empty if you don\'t want to change your password',
    add_new_password_title: 'Enter new password',
    confirm_new_password_title: 'Confirm new password',
    add_confirm_new_password_title: 'Confirm new password',
    addressesTitle: 'Addresses',
    slug_edit_addresses: 'edit-addresses',
    slug_edit_address: 'edit-address',
    addresses_will_used_desc_title: 'Following addresses will be used by default on products order form.',
    AF_address_title: 'Invoice address',
    AW_address_title: 'Shipment address',
    AK_address_title: 'Contact address',
    not_set_up_address_yet: 'You haven\'t set this type of address yet.',
    edit_title: 'Edit',
    country_title: 'Country',
    street_title: 'Street',
    add_street_title: 'Enter street',
    post_code_title: 'Post code',
    add_post_code_title: 'Enter post code',
    city_title: 'City',
    add_city_title: 'Enter city',
    cod: 'Cash on delivery',
    paypal: 'PayPal',
    back_title: 'Back',
    home: 'home',
    country_name_lang: 'name_en',
    select: 'Select',
    order: 'Order',
    date: 'Date',
    status: 'Status',
    total: 'Total',
    actions: 'Actions',
    add_comment: 'Add comment',
    comment: 'Comment',
    add_content_comment: 'Enter the content of the comment',
    must_choose_rating: 'You must choose a rating',
    your_opinion: 'Your opinion',
    add_review: 'Add review',
    view: 'View',
    return: 'Return',
    complaint: 'Complaint',
    slug_returns: 'returns',
    returns: 'Returns',
    slug_complaints: 'complaints',
    complaints: 'Complaints',
    complaint_reason: 'Complaint reason',
    order_number: 'Order number',
    order_created_at: 'Order date',
    product_information: 'Product information',
    index: 'Index',
    slug_returns_and_complaints: 'returns-and-complaints',
    returns_and_complaints: 'Returns & Complaints',
    order_total: 'Order total',
    quantity: 'Quantity',
    preferred_resolve_method: 'Preferred resolve method',
    disable_return_time: 'Time for making a return has been exceeded',
    disable_complaint_time: 'Time for making a complaint has been exceeded',
    slug_return: 'return',
    slug_complaint: 'complaint',
    all: 'All',
    return_reason: 'Return reason',
    return_created_at: 'Return request date',
    complaint_created_at: 'Complaint request date',
    details: 'Details',
    download_pdf_file: 'Download PDF file',
    return_order_items: 'Order items',
    complaint_order_items: 'Order items',
    sku: 'Sku',
    name: 'Name',
    price: 'Price',
    status_history: 'Status history',
    customer_notify: 'Customer notification',
    complaint_status: 'Complaint status',
    yes: 'Yes',
    no: 'No',
    comments: 'Comments',
    customer: 'Customer',
    your_comment: 'Your comment',
    tables_langs: {
        previousText: 'Previous',
        nextText: 'Next',
        loadingText: 'Loading...',
        noDataText: 'No data',
        pageText: 'Page',
        ofText: 'from',
        rowsText: 'Rows',
    },
    slug_user_account: 'user-account',
    shipping_address: 'Shipping address',
    slug: {
        slug_login_register: 'logowanie-rejestracja',
        lost_password_slug: 'odzyskiwanie-hasla',
        user_account: 'konto-uzytkownika'
    }
}