
import API from 'boost/API';
import axios from 'axios';

const STORE = 'boost';
export const ASYNC_REQUESTED = `${STORE}/ASYNC_REQUESTED`;
export const SET_BREADCRUMBS = `${STORE}/SET_BREADCRUMBS`;
export const UPDATE_RECORD = `${STORE}/UPDATE_RECORD`;
export const UPDATE_VALUE = `${STORE}/UPDATE_VALUE`;
export const SET_CONTACT_ADDRESS = `${STORE}/contact_address`;
export const SET_SHIPPING_ADDRESS = `${STORE}/shipping_address`;
export const SET_INVOICE_DATA = `${STORE}/invoice_data`;
export const SET_ERRORS= `${STORE}/SET_ERRORS`;
export const SET_CATEGORY_IDS = `${STORE}/SET_CATEGORY_IDS`;

const initialState = {
    loader: false,
    breadcrumbs: {},
    route_map: false,
    errors: [],
    category_ids: {}
}

export default (state = initialState, action) => {
    switch (action.type) {

        case ASYNC_REQUESTED:
            return {
                ...state,
                loader: true
            }

        case SET_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: Object.assign(state.breadcrumbs, action.breadcrumbs),
            }

        case UPDATE_RECORD:
            let new_state = action.data;
            if (!new_state) {
                new_state = state[action.paramsState]
            }
            return {
                ...state,
                [action.paramsState]: new_state,
                loader: false
            }
        case UPDATE_VALUE:
            if (action.join) {
                let new_date = Object.assign({}, state[action.paramsState], action.data);
                return {
                    ...state,
                    [action.paramsState]: new_date
                }
            } else {
                return {
                    ...state,
                    [action.paramsState]: action.data
                }
            }
        case SET_CONTACT_ADDRESS:
            return {
                ...state,
                contact_address: {...state.contact_address, ...action.data}
            }
        case SET_SHIPPING_ADDRESS:
            return {
                ...state,
                shipping_address: {...state.shipping_address, ...action.data}
            }
        case SET_INVOICE_DATA:
            return {
                ...state,
                invoice_data: {...state.invoice_data, ...action.data}
        }
        case SET_ERRORS:
            return {
                ...state,
                errors: action.data
            }
        case SET_CATEGORY_IDS: {
            return {
                ...state,
                category_ids: {...state.category_ids, ...action.data}
            }
        }
        default:
            return state
    }
}

export const test = (params = { url: '', data: '', state: 'loader', param_result: false }) => {
    return dispatch => {
        dispatch({
            type: ASYNC_REQUESTED,
            data: true
        });
        return new Promise(resolve => {
            let map = [
                {
                    slug: 'moje-strony',
                    module: 'page-list'
                },
                {
                    slug: 'nowosci',
                    module: 'news'
                },
                {
                    slug: '',
                    module: 'home'
                },
                {
                    slug: 'sklep',
                    module: 'shop'
                },
                {
                    slug: 'konto-uzytkownika',
                    module: 'user'
                },
                {
                    slug: 'strona',
                    module: 'description-pages'
                }
            ];
            setTimeout(() => {
                dispatch({
                    type: UPDATE_RECORD,
                    paramsState: params.state,
                    data: map
                })
                resolve(map)
            }, 10, resolve, params, UPDATE_RECORD)
        })
    };
}
export const load = (params = { url: '', data: '', state: 'loader', param_result: false }) => {
    return dispatch => {
        dispatch({
            type: ASYNC_REQUESTED,
            data: true
        });
        return new Promise(resolve => {
            API.get(params).then(res => {
                let response = params.param_result ? res.data[params.param_result] : res.data;
                resolve(response);
                if (params.state) {
                    dispatch({
                        type: UPDATE_RECORD,
                        paramsState: params.state,
                        data: response
                    })
                } else {
                    dispatch({
                        type: ASYNC_REQUESTED,
                        data: false
                    });
                }
            });
        })
    };
}
export const updateValue = (params = { data: false, state: 'loader', join: false }) => {
    return dispatch =>
        dispatch({
            type: UPDATE_VALUE,
            paramsState: params.state,
            data: params.data,
            join: params.join
        });
}

export const refreshToken = () => {
    return dispatch => {
        dispatch({
            type: ASYNC_REQUESTED,
            data: true
        });
        return new Promise(resolve => {
            API.post('/api/refresh', {client: 'shop'}).then(res => {
                if (res.error) {
                    global.user_token = null;
                    resolve(false);
                    dispatch({
                        type: ASYNC_REQUESTED,
                        data: false
                    });
                } else if (res.status == 200) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
                    global.user_token = res.data.accessToken;
                    dispatch({
                        type: ASYNC_REQUESTED,
                        data: false
                    });
                    resolve(res);
                }
            });
        })
    };
}

export const setAddress = (data, action_type) => {
    return dispatch => {
        dispatch({
            type: action_type,
            data: data
        });
    }
}

export const setErrors = (errors) => {
    return dispatch => {
        dispatch({
            type: SET_ERRORS,
            data: errors
        });
    }
}

export const setCategoryIds = (data) => {
    return dispatch => {
        dispatch({
            type: SET_CATEGORY_IDS,
            data: data
        });
    }
}

