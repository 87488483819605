import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load } from 'boost/dispatch';
import Lang from '../lang';

class DetailsComponent extends React.PureComponent {
    lang = Lang[global.language];

    state = {
        reasons: []
    }

    render() {
        let { order_no, order_id, email } = this.props;

        return (
            <div className='details'>
                <h3 className='upper'>{this.lang.details}</h3>
                <hr />
                <div className='form-group'>
                    <label htmlFor='number'>{this.lang.order_number}</label>
                    <input type='text' className='form-control' name='number' value={order_no} disabled />
                </div>
                <div className='form-group'>
                    <label htmlFor='number'>ID</label>
                    <input type='text' className='form-control' name='number' value={order_id} disabled />
                </div>
                <div className='form-group'>
                    <label htmlFor='complaint_reason_id'>{this.lang.complaint_reason}</label>
                    <select className='form-control' name='complaint_reason_id' disabled >
                        {
                            this.state.reasons.map(
                                (reason, key) => <option value={reason.value} key={key} >{reason.description}</option>
                            )
                        }
                    </select>
                </div>
                <div className='form-group'>
                    <label htmlFor='email'>{this.lang.email}</label>
                    <input type='email' className='form-control' name='email' value={email} disabled />
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.props.load({ url: `api/admin/${this.props.get}` }).then(reasons => {
            this.setState({ reasons: reasons.data });
        });
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load
}, dispatch)

export default connect(
    null,
    mapDispatchToProps,
)(DetailsComponent);
