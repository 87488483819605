export default {
    terms_and_conditions_slug: 'terms-conditions',
    regulamin: 'Terms & Conditions',
    about_us_slug: 'about-us',
    'o-nas': 'About us',
    policies_slug: 'policies',
    'warunki-umowy': 'Polices',
    contact_slug: 'contact',
    'kontakt': 'Contact',
    how_to_buy_slug: 'how-to-buy',
    'jak-kupowac': 'How to buy',
    footwear_how_choose_size_slug: 'footwear-how-choose-size',
    'obuwie-jak-dobrac-rozmiar': 'Footwear - how to choose the size?',
    clothing_how_choose_size_slug: 'clothing-how-choose-size',
    'odziez-jak-dobrac-rozmiar': 'Clothing - how to choose the size?',
    delivery_slug: 'delivery',
    'dostawa': 'Delivery',
    payment_methods_slug: 'payment-methods',
    'metody-platnosci': 'Payment methods',
    faq_slug: 'faq',
    'faq': 'FAQ',
    returns_and_replacements_slug: 'returns-and-replacements',
    'zwrot': 'Returns & Replacements',
    slug: {
        terms_and_conditions_slug: 'regulamin',
        about_us_slug: 'o-nas',
        policies_slug: 'warunki-umowy',
        contact_slug: 'kontakt',
        how_to_buy_slug: 'jak-kupowac',
        footwear_how_choose_size_slug: 'obuwie-jak-dobrac-rozmiar',
        clothing_how_choose_size_slug: 'odziez-jak-dobrac-rozmiar',
        delivery_slug: 'dostawa',
        payment_methods_slug: 'metody-platnosci',
        faq_slug: 'faq',
        returns_and_replacements_slug: 'zwroty-i-reklamacje',
        privacy_policy: 'polityka-prywatnosci',
        shipping_costs: 'koszty-wysylki',
        payment_methods: 'sposoby-platnosci',
        damaged_goods: 'uszkodzony-towar',
        i_want_to_exchange_the_goods: 'chce-wymienic-towar',
        refund_or_complaint: 'zwracam-lub-reklamuje'
    }
}