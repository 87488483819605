import React, { Fragment } from 'react';
import Lang from '../lang';
import API from 'boost/API';
import Loader from 'partials/loader';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateValue, load } from 'boost/dispatch';

class LoginRegister extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        email: '',
        password: '',
        rememberme: false,
        reg_email: '',
        reg_password: '',
        password_confirmation: '',
        personal_information: false,
        terms_of_use: false,
        newsletter: false,
        loader: false,
        errors : []
    }

    render() {
        return (
            <Fragment>
                {!this.state.loader 
                ?
                <Fragment>

                    <div className='main-header background' style={{ background: 'linear-gradient(rgba(28, 28, 28, 0.3), rgba(28, 28, 28, 0.3)), url("/themes/default/img/backgrounds/background-about.jpg")' }}>
                        <div className="container">
                            <h1 className="main-header-title text-center block">
                                <span>{this.lang.login_register_title}</span>
                            </h1>
                        </div>
                    </div>

                    <div id='breadcrumb'>
                        <div className='container'>
                            <ol className='breadcrumb'>
                                <li><Link to='/'>{this.lang.home}</Link></li>
                                <li><span>{this.lang.user_account_title}</span></li>
                                <li className='active'><span>{this.lang.login_register_title}</span></li>
                            </ol>

                        </div>
                    </div>

                    <div className='login-register-page woocommerce-account'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <article id='page-10' className='post-10 page type-page status-publish hentry'>
                                        <div className='woocommerce'>
                                            <div id='customer_login' className='u-columns col2-set'>
                                                <div className='u-column1 col-1'>
                                                    <h2>{this.lang.sign}</h2>
                                                    <form className='woocommerce-form woocommerce-form-login login' onSubmit={this.handleLogin}>
                                                        {this.showError('login')}
                                                        <div className='form-group'>
                                                            <label htmlFor='email'>{this.lang.email_address} <sup>*</sup></label>
                                                            <input type='email' name='email' className='form-control' id='email' autoComplete='email' placeholder={this.lang.add_email_address} value={this.state.email} onChange={this.updateValue} required />
                                                        </div>
                                                        <div className='form-group'>
                                                            <label htmlFor='password'>{this.lang.user_password} <sup>*</sup></label>
                                                            <input type='password' name='password' className='form-control' id='password' autoComplete='password' placeholder={this.lang.add_password} value={this.state.password} onChange={this.updateValue} required />
                                                        </div>
                                                        <div className='form-group'>
                                                            <div className='form-button'>
                                                                <button type='submit' className='btn btn-primary' onClick={() => {}}>{this.lang.loginTitle}</button>
                                                                <input name='rememberme' type='checkbox' id='rememberme' value={this.state.rememberme} checked={this.state.rememberme} onChange={this.onCheckboxChange} /> 
                                                                <label htmlFor='rememberme'>{this.lang.remember_me}</label>
                                                            </div>
                                                        </div>
                                                        <div className='form-group'>
                                                            <Link to={`${this.props.route}/${this.lang.slug.lost_password_slug}`}>{this.lang.not_remember_password_title}</Link>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className='u-column2 col-2'>
                                                    <h2>{this.lang.registration}</h2>
                                                    <form className='woocommerce-form woocommerce-form-register register' onSubmit={this.handleRegister}>
                                                        <div className='form-group'>
                                                            <label htmlFor='reg_email'>{this.lang.email_address} <sup>*</sup></label>
                                                            <input type='email' name='reg_email' className='form-control' id='reg_email' autoComplete='reg_email' placeholder={this.lang.add_email_address} value={this.state.reg_email} onChange={this.updateValue} required />
                                                            {this.showError('email')}
                                                        </div>
                                                        <div className='form-group'>
                                                            <label htmlFor='reg_password'>{this.lang.password} <sup>*</sup></label>
                                                            <input type='password' name='reg_password' className='form-control' id='reg_password' autoComplete='reg_password' placeholder={this.lang.add_password} value={this.state.reg_password} onChange={this.updateValue} required />
                                                            {this.showError('password')}
                                                        </div>
                                                        <div className='form-group'>
                                                            <label htmlFor='reg_password_confirmation'>{this.lang.confirm_password} <sup>*</sup></label>
                                                            <input type='password' name='password_confirmation' className='form-control' id='reg_password_confirmation' autoComplete='password_confirmation' placeholder={this.lang.add_confirm_password} value={this.state.password_confirmation} onChange={this.updateValue} required />
                                                            {this.showError('password_confirmation')}
                                                        </div>
                                                        <div className='form-group'>
                                                            <input type='checkbox' name='personal_information' id='personal_information' className='personal_information' value={this.state.personal_information} checked={this.state.personal_information} onChange={this.onCheckboxChange} required />
                                                            <label htmlFor='personal_information'>{this.lang.personal_information} <sup>*</sup></label>
                                                            {this.showError('personal_information')}
                                                        </div>
                                                        <div className='form-group'>
                                                            <input type='checkbox' name='terms_of_use' id='terms_of_use' className='terms_of_use' value={this.state.terms_of_use} checked={this.state.terms_of_use} onChange={this.onCheckboxChange} required />
                                                            <label htmlFor='terms_of_use'>
                                                                {this.lang.agree_with}
                                                                <a className='cursor-pointer' onClick={e => {
                                                                    e.preventDefault();
                                                                    this.goto('/strona/regulamin');
                                                                    return false;
                                                                }}>{this.lang.site_regulations}</a> <sup>*</sup>
                                                            </label>
                                                            {this.showError('terms_of_use')}
                                                        </div>
                                                        <div className='form-group'>
                                                            <input type='checkbox' name='newsletter' id='newsletter' className='newsletter' value={this.state.newsletter} checked={this.state.newsletter} onChange={this.onCheckboxChange} />
                                                            <label htmlFor='newsletter'>{this.lang.subscribe_newsletter}</label>
                                                            {this.showError('newsletter')}
                                                        </div>
                                                        <div className='form-group'>
                                                            <div className='form-button'>
                                                                <button type='submit' className='btn btn-primary' onClick={() => {}}>{this.lang.register}</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
                :
                <div className='preloader'>
                    <div className='preloader-wrapper'>
                        <Loader type='small'/>
                    </div>
                </div>
                }
            </Fragment>
        )
    }

    componentDidMount() {
        this.setState(this.getRememberMe());
    }

    /**
     * Aktualizuje wartość state'ów
     * 
     * @param {object} e - Event
     * @return {undefined}
     */
    updateValue = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    /**
     * Odpowiada za logowanie klienta do sklepu
     * 
     * @param {object} e - Event
     * @return {undefined}
     */
    handleLogin = (e) => {
        e.preventDefault();
        this.showLoader();
        let { email, password } = this.state;
        API.post('/api/login', { email, password , client: 'shop' }).then(res => {
            this.hideLoader();
            if (!res.error) {
                toastr.success(this.lang.logged_in);
                this.setRememberMe();
                this.props.history.push(`/${this.lang.slug.user_account}`);
            } else {
                this.setState({ errors: { login: [this.lang.incorrect_login_or_password] } });
            }
        })
    }

    /**
     * Odpowiada za rejestrację klienta w sklepie
     * 
     * @param {object} e - Event
     * @return {undefined}
     */
    handleRegister = (e) => {
        e.preventDefault();

        this.showLoader();

        let { 
            reg_email, reg_password,  
            password_confirmation, personal_information, 
            terms_of_use, newsletter 
        } = this.state;

        let data = {
            email: reg_email,
            name: reg_email,
            password: reg_password,
            password_confirmation,
            personal_information,
            terms_of_use,
            newsletter
        }
        
        API.post('/api/register', data).then(res => {
            if (!res.error) {
                API.post('/api/login', { email: reg_email, password: reg_password, client: 'shop'}).then(res => {
                    this.hideLoader();
                    if (!res.error) {
                        toastr.success(this.lang.logged_in);
                        this.props.history.push(`/${this.lang.slug.user_account}`);
                    } else {
                        this.setState({ errors: { login: [this.lang.incorrect_login_or_password] } });
                    }
                })
            } else {
                this.hideLoader();
                let { errors } = res.data;
                this.setState({ errors });
            }
        })
    }

    /**
     * Aktualizuje wartość state'ów
     * 
     * @param {object} e - Event
     * @return {undefined}
     */
    onCheckboxChange = (e) => {
        this.setState({[e.target.name]: !(e.target.value === 'true')});
    }

    /**
     * Wyświetla błędy formularza zwrócone przez api
     * 
     * @param {string} name - Nazwa pola formularza
     * @return {undefined}
     */
    showError = (name) => {
        let { errors } = this.state;
        if (errors[name]) {
            return (
                <Fragment>
                    {
                        errors[name].map((error, key) =>
                            <div className={`text-danger`}  key={key}>{error}</div>
                        )
                    }
                </Fragment>
            )
        }
    }

    /**
     * Przenosi na określony adres url
     * 
     * @param {string} url - Adres url
     * @return {undefined}
     */
    goto = (url) => {
        window.location.href = url;
    }

    /**
     * Zapisuje dane logowania do sklepu
     * 
     * @return {undefined}
     */
    setRememberMe() {
        let { email, password, rememberme } = this.state;
        if (rememberme) {
            localStorage.setItem('shop.client.login.data', JSON.stringify({ email, password, rememberme }));
        } else {
            localStorage.removeItem('shop.client.login.data');
        }
    }

    /**
     * Pobiera dane logowania do sklepu
     * 
     * @return {object}
     */
    getRememberMe() {
        if (localStorage.getItem('shop.client.login.data') !== null) {
            return JSON.parse(localStorage.getItem('shop.client.login.data'));
        }
        return {};
    }

    /**
     * Wyświetla loader
     * 
     * @return {undefined}
     */
    showLoader() {
        this.setState({ loader: true })
    }

    /**
     * Ukrywa loader
     * 
     * @return {undefined}
     */
    hideLoader() {
        this.setState({ loader: false })
    }

}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue
}, dispatch)

export default connect(
    null,
    mapDispatchToProps,
)(LoginRegister);