import React from 'react';
import Lang from '../lang';
import Helper from 'helpers';

class OrderItemsComponent extends React.PureComponent {
    lang = Lang[global.language];

    render() {
        let { sku, product_name, gross, quantity } = this.props;

        return (
            <div className='order-items'>
                <h3 className='upper'>{this.lang.complaint_order_items}</h3>
                <hr />
                <table className='table table-bordered table-striped'>
                    <thead>
                        <tr>
                            <th>{this.lang.sku}</th>
                            <th>{this.lang.name}</th>
                            <th>{this.lang.price}</th>
                            <th>{this.lang.quantity}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{sku}</td>
                            <td>{product_name}</td>
                            <td>{`${Helper.currencyFormatter().format(gross)}`}</td>
                            <td>{quantity}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default OrderItemsComponent;