import axios from 'axios';
import { api_url, root_url } from 'app/config';
import ObjectToFormData from 'helpers/object-to-formdata';
import Lang from './lang/';

const lang = Lang[global.language];

export default {
    // Wspólne metody odwołania do API. Każdy request przechodzi przez nie.
    get(params = {url: false, data: false, headers: {}, onlyURL: false}) {
        if (!params.url.match('http') && !params.onlyURL) {
            params.url = api_url + (params.url[0] != '/' ? '/' + params.url : params.url); // Sprawdzenie, czy w url znajduje się '/' na początku.
        }
        
        let query = '';
        if (params.data && !params.url.match('/:')) {
            query = params.url.indexOf('?') > -1 ? '&' : '?'; // Sprawdzenie, czy w url nie ma już dodanego parametru
            for (let value in params.data) {
                if (query.length > 2){
                    query += '&';
                }
                if(`${params.data[value]}`.indexOf('[') > -1) {
                    query += `${params.data[value]}`;
                } else {
                    query += `${value}=${params.data[value]}`;
                }
            }
        } else if(params.data){
            params.url = this.findVariableInUrl(params.url, params.data);
        }

        return new Promise(resolve => {
            axios.get(params.url + query, { headers: { ...params.headers } })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    if(error.response.status === 401) {
                        this.catchError(error).then(result => {
                            if(result) {
                                // Potrzebne dla odświeżenia tokenu podczas pobierania danych
                                axios.get(params.url + query, { headers: params.headers })
                                .then(response => {
                                    resolve(response);
                                })
                            }
                        });
                    } else if (error.response.status === 404) {
                        resolve({ 
                            data: { 
                                status: error.response.status,
                                error: true 
                            }
                        })
                    } else if (error.response.status === 429) {
                        toastr.warning(lang.server_temporarily_unavailable);
                        resolve({ error: true, data: { data: [] }});
                    } else {
                        resolve({ error: true, data: error.response.data });
                        this.catchError(error);
                    }
                });
        })
    },
    put(url, data, headers = false, form_data_cfg = {}) {
        url = api_url + (url[0] != '/' ? '/' + url : url); // Sprawdzenie, czy w url znajduje się '/' na początku.
        data = typeof data != 'undefined' ? data : {};
        headers = headers ? headers : { 'Content-Type': 'application/json' };
        let data_form = this.changeDataToFormData(data, true, form_data_cfg);
        return new Promise(resolve => {
            axios.post(url, data_form, { headers })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    resolve({ error: true, data: error.response.data });
                    this.catchError(error);
                });
        })
    },
    post(url, data = {}, headers = false, custom_url = false, form_data_cfg = {}) {
        if (!custom_url) {
            url = api_url + (url[0] != '/' ? '/' + url : url); // Sprawdzenie, czy w url znajduje się '/' na początku.
        }
        headers = headers ? headers : { 'Content-Type': 'application/json' };
        let data_form = this.changeDataToFormData(data, false,  form_data_cfg);
        return new Promise(resolve => {
            axios.post(url, data_form, { headers })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    if (error.response.status === 429) {
                        toastr.warning(lang.server_temporarily_unavailable);
                        resolve({ error: true, data: { errors: {} }});
                    } else {
                        resolve({ error: true, data: error.response.data });
                        this.catchError(error);
                    }
                    this.catchError(error);
                });
        })
    },
    delete(url, headers) {
        url = api_url + (url[0] != '/' ? '/' + url : url); // Sprawdzenie, czy w url znajduje się '/' na początku.
        headers = typeof headers != 'undefined' ? headers : {};

        return new Promise(resolve => {
            axios.delete(url, { headers })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    resolve({ error: true, data: error.response.data });
                    this.catchError(error);
                });
        })
    },
    // Metoda potrzebna do zmiany danych z formy JSON na formę FormData
    changeDataToFormData(data, put = false, form_data_cfg={}) {
        const form_data = ObjectToFormData(data, form_data_cfg);
        if (put) {
            form_data.append('_method', 'PUT');
        }
        return form_data;
    },

    findVariableInUrl(value, data) {
        let sub = value.slice(value.indexOf('/:') + 2, value.lenght);
        let id = false;
        let url = value;
        if (sub) {
            let last_slash = sub.indexOf('/');
            id = sub;
            if (last_slash > -1) {
                id = sub.slice(0, last_slash)
            }
        }
        if (id) {
            url = value.replace(`:${id}`, data[id]);
        }
        return url;
    },

    // Wspólna metoda obsług błędów. Jeśli pojawi się błąd odpowiedzi zostanie tutaj wychwycony.
    catchError(error) {
        let { status } = error.response;
        
    }
}

HTMLElement.prototype.serialization = function(){
    var obj = {};
    var elements = this.querySelectorAll( "input, select, textarea" );
    for( var i = 0; i < elements.length; ++i ) {
        var element = elements[i];
        var name = element.name;
        var value = element.value;
        if(element.type == 'checkbox' || element.type == 'radio'){
            value = element.checked;
        }
        console.log(value);
        if( name ) {
            obj[ name ] = value;
        }
    }
    return  obj;
}