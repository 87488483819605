import React from 'react';
import { connect } from 'react-redux';
import Lang from '../lang';

class DasboardUser extends React.PureComponent {
    lang = Lang[global.language];

    render() {
        return (
            <React.Fragment>
                {`${this.lang.welcomeUserTitle} `}
                {this.props.user 
                    && ((this.props.user.data.first_name || this.props.user.data.last_name) 
                        ? `${this.props.user.data.first_name} ${this.props.user.data.last_name}` 
                        : `${this.props.user.data.name}`)}
            </React.Fragment>
        )
    }
    
}

const mapStateToProps = (state) => {
    return {
        user: state.boost.user,
    }
}

export default connect(
    mapStateToProps
)(DasboardUser);