import React from 'react';
import { Route, Link, Switch, Router } from 'react-router-dom';

class NewsSingle extends React.Component {
    render() {
        console.log('NewsSingle', this.props)
        return (
            <React.Fragment>
                Aktualność o slagu: {this.props.match.params.id_articles}
            </React.Fragment>
        )
    }
}

export default NewsSingle;