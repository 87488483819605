import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Lang from './lang/';
import Dropdown from './dropdown';

const MOBILE_VIEW = 980;

class Menu extends React.Component {

    lang = Lang[global.language];
    _timeout = null;
    _isMounted = false;

    state = {
        dropdown: false,
        category: null,
        categoryTree: [],
        windowWidth: 0,
        windowHeight: 0,
        numberClicks: 0
    }

    render() {
        let { dropdown, windowWidth, categoryTree } = this.state;
        let mobile = windowWidth <= MOBILE_VIEW;
        return (
            <ul className='awemenu awemenu-right'>
                <li 
                    className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-831 awemenu-item awemenu-fadeup awemenu-has-children ${dropdown ? 'awemenu-active' : ''}`}
                    onMouseLeave={this.handleLeave}
                >
                    <Link 
                        to={`/${this.lang.slug.shop}`} 
                        onClick={this.handleShopClick}
                        onMouseEnter={this.handleHoverDropDown}
                    >
                        {this.lang.product_categories}
                        {mobile && <i className={`awemenu-arrow fa ${dropdown ? 'fa-close' : 'fa-angle-down'}`} onClick={this.handleClickDropDown}></i>}
                    </Link>
                    <Dropdown 
                        data={categoryTree} 
                        handleClick={this.handleClick}
                        parentId={0}
                        parentSlug={this.lang.slug.shop}
                        resetView={this.resetShopProductsList}
                        partUrl={`${this.lang.slug.shop}/${this.lang.slug.category}`}
                        getBranch={this.createCategoryBranch}
                    />
                </li>
                <li className='awemenu-item'>
                    <Link to={`/${this.lang.slug.page}/${this.lang.slug.terms_and_conditions_slug}`}>{this.lang.terms_and_conditions}</Link>
                </li>
            </ul>
        )
    }

    /**
     * Zapisuje kategorie rodziców w localStorage
     * 
     * @return { undefined }
     */
    getParentCategory() {
        this.props.load({ url: '/api/shop/categories', state: 'category_list', data: { per_page: 100 }, param_result: 'data' }).then(res => {
            if (!!res) {
                let category = res.sort((catA, catB) => catA.id - catB.id);
                category = { 0: category };
                this.loadData(category);
            }
        });
    }

    loadData(categoryTree) {
        if (this._isMounted) {
            this.setState({ categoryTree }, () => {
                localStorage.setItem('shop.client.categoryTree', JSON.stringify(categoryTree));
            });
        }
    }

    /**
     * Zapisuje kategorie dzieci w localStorage
     * 
     * @param { integer } id - Identyfikator kategorii
     * @return { undefined }
     */
    getChildrenCategory = (id) => {
        return new Promise(resolve => {
            this.props.load({ url: '/api/shop/categories', state: 'category_list', data: { id: id, per_page: 100 }, param_result: 'data' }).then(res => {
                if (!!res) {
                    let { category } = this.state;
                    let subCategory = res.sort((catA, catB) => catA.id - catB.id);
                    category = { ...category, [id]: subCategory };
                    this.loadData(category);
                    resolve(true);
                }
            });
        });
    }

    handleClick = ({ id, slug }) => {
        this.setCategoryBranch(id);
        this.setCategorySlug(slug);
        this.setClickedCategory(id);
        this.setUrl(slug);
    }

    /**
     * Ustawia slug kategorii w reduxowym store
     * 
     * @param {String} slug - Nazwa kategorii 
     */
    setCategorySlug(slug) {
        this.props.updateValue({ state: 'shop_category_slug', data: slug });
    }

    /**
     * Zapisuje w state identyfikator wybranej kategorii
     * 
     * @param { integer } category_id - Identyfikator kategorii
     * @return { undefined }
     */
    setClickedCategory(category_id) {
        this.props.updateValue({ state: 'poduct_list_filters', data: { category:  category_id }, join: true });
    }

    /**
     * Ustawia url przeglądarki na odpowiedni dla wybranej kategorii
     * 
     * @param { string } slug - Slug kategorii
     * @return { undefined }
     */
    setUrl(slug) {
        window.history.pushState('', '', `/${this.lang.slug.shop}/${this.lang.slug.category}/${slug}`);
    }

    /**
     * Ustawia gałąź kategorii w reduxowym store
     * 
     * @param { integer } id - Identyfikator kategorii
     * @return { undefined }
     */
    setCategoryBranch = (id) => {
        let category_branch = this.createCategoryBranch(id);
        this.props.updateValue({ state: 'category_branch', data: category_branch });
    }

    /**
     * Tworzy gałąź klikniętej kategorii
     * 
     * @param { integer } id - Identyfikator kategorii
     * @return { object }
     */
    createCategoryBranch = (id) => {
        let category = JSON.parse(localStorage.getItem('shop.client.category'));
        for (let property in category) {
            if (category.hasOwnProperty(property)) {
                let category_arr = category[property].filter(element => element.id === id);
                if (category_arr.length) {
                    let current_category = category_arr[0];
                    return { ...{ [current_category.id]: current_category }, ...this.createCategoryBranch(current_category.parent_id) };
                }
            }
        }
        return {};
    }

    /**
     * Resetuje listę produktów
     * 
     * @return { undefined }
     */
    resetShopProductsList = () => {
        this.props.updateValue({ state: 'reset_shop_products_list', data: true });
    }

    handleLeave = () => {
        let { windowWidth } = this.state;
        let mobile = windowWidth <= MOBILE_VIEW;
        if (!mobile) {
            this._timeout = setTimeout(() => {
                if (this._isMounted) {
                    this.setState({ dropdown: false });
                }
            }, 700);
        }
    }

    handleHoverDropDown = () => {
        let { windowWidth } = this.state;
        let mobile = windowWidth <= MOBILE_VIEW;
        if (!mobile) {
            clearTimeout(this._timeout);
            if (this._isMounted) {
                this.setState({ dropdown: true });
            }
        }
    }

    handleClickDropDown = (e) => {
        e.preventDefault();
        let { dropdown } = this.state;
        if (this._isMounted) {
            this.setState({ dropdown: !dropdown });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getCategory();
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);

    }

    getCategory() {
        let categoryTree = JSON.parse(localStorage.getItem('shop.client.categoryTree'));
        if (!categoryTree) { 
            this.props.load({ url: 'api/shop/categories/categoryTree' }).then(res => {
                if (!!res.data) {
                    let categoryTree = res.data;
                    this.loadData(categoryTree);
                }
            });
        } else {
            if (this._isMounted) {
                this.setState({ categoryTree });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        this._isMounted = false;
    }

    updateDimensions = () => {
        if (this._isMounted) {
            this.setState({ 
                windowWidth: window.innerWidth, 
                windowHeight: window.innerHeight
            });
        }
    }

    handleShopClick = (e) => {
        let { windowWidth, numberClicks } = this.state;
        let mobile = windowWidth <= MOBILE_VIEW;
        if (!mobile) {
            this.resetShopProductsList();
        } else {
            // Drugie kliknięcie przenosi do strony sklepu
            if (numberClicks > 0) {
                this.resetShopProductsList();
                return;
            }
            e.preventDefault();
            if (this._isMounted) {
                this.setState({
                    numberClicks: numberClicks + 1,
                    dropdown: true
                });
            }
        }
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue
}, dispatch)

export default connect(
    null,
    mapDispatchToProps,
)(Menu);