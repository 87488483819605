import React from 'react';
import Lang from './lang';

export default class extends React.Component {

    lang = Lang[global.language];

    state = {
        url: typeof this.props.location.state !== 'undefined' ? this.props.location.state.pathname : this.props.location.pathname
    }

    render() {

        return (
            <div className="error-404 background background-color-dark background-image-main-404">
                <div className="fp-table">
                    <div className="fp-table-cell center">
                        <div className="container">
                            <div className="margin-bottom-40">
                                <h1><span className="page"></span><span>404</span></h1>
                                <h2>404 {this.lang.error}</h2>
                            </div>
                            <p>
                                <span>{this.lang.page_doesnt_exist}</span>
                                <a href='/' className="btn btn-lg btn-primary margin-left-10">{this.lang.back_to_prev_page}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.history.replaceState('', '', `${this.state.url}`);
    }
}
