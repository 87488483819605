export default {
    shop: 'shop',
    page: 'page',
    terms_and_conditions: 'terms of use',
    terms_and_conditions_slug: 'terms-conditions',
    slug_delivery: 'delivery',
    delivery: 'Delivery',
    slug_payment_methods: 'payment-methods',
    payment_methods: 'Payment methods',
    category: 'categories',
    slug: {
        shop: 'sklep',
        page: 'strona',
        slug_delivery: 'dostawa',
        terms_and_conditions_slug: 'regulamin',
        slug_payment_methods: 'metody-platnosci',
        category: 'kategoria',
        shop: 'sklep'
    },
    more: 'More...',
    product_categories: 'Product categories'
}