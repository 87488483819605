import React from 'react';
import Fullscreen from "react-full-screen";

class ImagePreview extends React.Component {

    state = {
        path: this.props.match.params.image_path,
        isFull: false,
        showTopBar: true
    }

    render() {
        let { showTopBar, isFull, path } = this.state;
        return (
            <Fullscreen
                enabled={isFull}
                onChange={isFull => this.setState({isFull})}
            >
                <div className='pswp__container' onMouseLeave={this.hideTopBar} onMouseEnter={this.showTopBar}>
                    <table id='image-wrapper'>
                        <tr>
                            <td>
                                <img src={atob(path)} alt='' />
                            </td>
                        </tr>
                    </table>
                    <div className={`pswp__top-bar ${showTopBar ? `pswp__top-bar-show` : `pswp__top-bar-hide`}`}>
                        <button className={`pswp__button pswp__button--close`} onClick={this.goBack} aria-label="Zamknij (Esc)"></button>
                        <button className={`pswp__button ${isFull ? `pswp__button--fs`: ''}`} onClick={this.goFull} aria-label="Przełącz tryb pełnoekranowy"></button>
                    </div>
                </div>
            </Fullscreen>
        )
    }

    /**
     * Umożliwia powrót na poprzednią stronę
     * 
     * @return {undefined}
     */
    goBack = () => {
        this.props.history.goBack();
    }

    /**
     * Ustawia zdjęcie na pełny obraz
     * 
     * @return {undefined}
     */
    goFull = () => {
        this.setState({ isFull: !this.state.isFull });
    }

    /**
     * Wyświetlanie przycisków
     * 
     * @return {undefined}
     */
    showTopBar = () => {
        this.setState({ showTopBar: true });
    }

    /**
     * Ukrywanie przycisków
     * 
     * @return {undefined}
     */
    hideTopBar = () => {
        this.setState({ showTopBar: false });
    }
}

export default ImagePreview;