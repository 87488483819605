export default {
    collect(object, cookie = 'statistics') {
        if (document.cookie.indexOf('uuid=') === -1) {
            this.createUUID();
        }
        let request_data = this.requestData(object, cookie);
        this.sendRequest(request_data);
    },

    guid() {
        return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
    },

    s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    },

    createUUID() {
        let date = new Date();
        date.setTime(date.getTime() + (10 * 365 * 24 * 60 * 60 * 1000));
        document.cookie = 'uuid=' + this.guid() + '; expires=' + date.toGMTString();
    },

    requestData(object, cookie) {
        let cookies = document.cookie;
        let uuid = '';
        cookies = cookies.split('; ');
        for(let i = 0; cookies.length > i; i++) {
            let cur = cookies[i].split('=');
            if (cur[0] === 'uuid') {
                uuid = 'uuid=' + cur[1];
            }
        }
    
        return uuid + ';' + cookie + '=' + JSON.stringify(object);
    },

    createCORSRequest(method, url) {
        let xhr = new XMLHttpRequest();

        if ('withCredentials' in xhr) {
            xhr.open(method, url, true);
        } else if (typeof XDomainRequest != 'undefined') {
            xhr = new XDomainRequest();
            xhr.open(method, url);
        } else {
            xhr = null;
        }

        return xhr;
    },

    sendRequest(request_data) {
        let url = 'https://b2b.iph-shop.pl/cookie-client/save-cookies?apiKey=5d03ae7c3e863&cookie=' + encodeURIComponent(request_data);
        let xhr = this.createCORSRequest('GET', url);
    
        if (!xhr) {
            console.error('CORS not supported')
        }
    
        xhr.send();
    }
}