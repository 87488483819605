import React from 'react';
import { Link } from 'react-router-dom';
//import RegisterLogin from './login-register';
import ReactDOM from 'react-dom';
import API from 'boost/API';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Lang from '../lang/';

class UserWidget extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        module_url: ''
    }

    render() {

        let { user } = this.props;

        return (
            <li className='menubar-account'>
                <a href='#' title='' className='awemenu-icon menu-user'>
                    <i className='icon icon-user-circle'></i>
                    <span className='awe-hidden-text'>Account</span>
                    {user &&
                        <span className='user-logged'>
                            <i className='fa fa-check'></i>
                        </span>
                    }
                </a>
                {
                    !this.props.login_access ?
                        <ul className="submenu dropdown login-register-dropdown">
                            <li>
                                <div className='container-fluid'>
                                    <Link to={`${this.state.module_url}/${this.lang.slug.slug_login_register}`} title=''>
                                        {this.lang.login_register_title}
                                    </Link>
                                </div>
                            </li>
                        </ul>
                        :
                        <ul className='submenu megamenu'>
                            <li>
                                <div className='container-fluid'>
                                    {
                                        this.props.user ?
                                            <div className='header-account word-break-break-all'>
                                                <div className='header-account-avatar'>
                                                    <Link to={this.state.module_url} title=''>
                                                        {
                                                            !this.props.user.data.image ?
                                                                <img src='/themes/default/img/samples/avatars/customers/1.jpg' alt='' className='img-circle' />
                                                                :
                                                                <img src={`data:image/png;base64,${this.props.user.data.image}`} alt='' className='img-circle' style={{width: '62px'}}/>
                                                        }    
                                                    </Link>
                                                </div>
                                                <div className='header-account-username'>
                                                    <h4> 
                                                        <Link to={this.state.module_url} title=''>
                                                            {
                                                                (this.props.user.data.first_name || this.props.user.data.last_name) 
                                                                    ? `${this.props.user.data.first_name} ${this.props.user.data.last_name}` 
                                                                    : `${this.props.user.data.name}`
                                                            }
                                                        </Link>
                                                    </h4>
                                                </div>

                                                <ul>
                                                    <li><Link to={this.state.module_url} title=''>{this.lang.myAccountTitle}</Link></li>
                                                    <li><a className="cursor-pointer" onClick={e => {
                                                        e.preventDefault();
                                                        this.logout();
                                                        return false;
                                                    }}>{this.lang.logoutTitle}</a></li>
                                                </ul>
                                            </div>
                                        :
                                            null
                                    }
                                    
                                </div>
                            </li>
                        </ul>
                }
            </li>
        )
    }

    componentDidMount() {
        if(this.props.route_map) {
            let to_url = this.props.route_map.filter(element => element.module == 'user');
            if(to_url.length) {
                this.setState({module_url: `/${to_url[0].slug}`});
            }
        }

        if (global.user_token) {
            this.props.updateValue({ state: 'login_access', data: true });
            if(!this.props.user) {
                this.getUserData();
            }
        }
    }

    /**
     * Pobiera dane zalogowanego użytkownika
     * 
     * @return {undefined}
     */
    getUserData = () => {
        this.props.load({ url: `/api/shop/customer/account`, state: 'user' });
    }

    logout = () => {
        API.post('/api/logout').then(res => {
            window.location.href = '/';
        })
    }
}

const mapStateToProps = (state) => {
    return {
        loader: state.boost.loader,
        user_token: state.boost.user_token,
        user: state.boost.user,
        login_access: state.boost.login_access
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserWidget);