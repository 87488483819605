import React from 'react';
import Lang from '../lang';

const MOBILE_VIEW = 980;
const MAX_CAT_ON_BRANCH = 8;
const MAX_BRANCH_LEVEL_SIZE = 3;

class DropdownMenuComponent extends React.Component {

    lang = Lang[global.language];

    state = {
        data: this.props.data || [],
        selectedCategoryId: 0,
        windowWidth: 0,
        windowHeight: 0
    }

    render() {
        let { data } = this.state;
        return data.length ? this.renderList() : <React.Fragment />
    }

    componentDidUpdate(prevProps) {
        let { data } = this.props;
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState({ data });
        }
    }

    renderMobile() {
        let { data, selectedCategoryId } = this.state;
        return (
            <ul className="awemenu-submenu awemenu-dropdown">
                {data.map(item => {
                    let { id, name, slug } = item;
                    let show = id === selectedCategoryId;
                    return (
                        <React.Fragment key={id}>
                            <li className={`menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-has-children menu-item-594 awemenu-item awemenu-fadeup awemenu-has-children ${show ? 'awemenu-active' : ''}`} >
                                <a
                                    className='cursor-pointer'
                                    onClick={e => {
                                        e.preventDefault();
                                        this.handleClick(item);
                                        return false;
                                    }}
                                    style={{ wordWrap: 'break-word' }}
                                >
                                    {name}
                                    <i className={`awemenu-arrow fa ${show ? 'fa-close' : 'fa-angle-down'}`} onClick={(e) => this.handleClickDropDown(e, item)}></i>
                                </a>
                                {show && <DropdownMenuComponent { ...this.props } data={item.children} id={id} parentId={id} parentSlug={slug}/>}
                            </li>
                        </React.Fragment>
                    );
                })}
            </ul>
        );
    }

    renderDesktop() {
        let i = 0;
        let { data, id, selectedCategoryId } = this.state;
        let branchLevelSize = Object.keys(this.props.getBranch(id)).length;

        if (branchLevelSize >= MAX_BRANCH_LEVEL_SIZE) {
            return <React.Fragment/>
        }

        return (
            <ul className="awemenu-submenu awemenu-dropdown">
                {data.map((item, key) => {
                    let { id, name, slug } = item;
                    let show = id === selectedCategoryId;
                    i++;

                    if (MAX_CAT_ON_BRANCH > i) {
                        return (
                            <React.Fragment key={id}>
                                <li 
                                    className={`menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-has-children menu-item-594 awemenu-item awemenu-fadeup awemenu-has-children ${show ? 'awemenu-active' : ''}`} 
                                    onMouseLeave={this.handleLeaveDropDown} >
                                    <a 
                                        className='cursor-pointer'
                                        onMouseEnter={() => this.handleHoverDropDown(id)} 
                                        onClick={e => {
                                            e.preventDefault();
                                            this.handleClick(item);
                                            return false;
                                        }}
                                        style={{ wordWrap: 'break-word' }}
                                    >
                                        {name}
                                    </a>
                                    {show && <DropdownMenuComponent { ...this.props } data={item.children} id={id} parentId={id} parentSlug={slug}/>}
                                </li>
                            </React.Fragment>
                        );
                    } else if (MAX_CAT_ON_BRANCH === i) {
                        return (
                            <React.Fragment key={id}>
                                {this.renderMore()}
                            </React.Fragment>
                        );
                    } 

                    return <React.Fragment key={id} />
                })}
            </ul>
        );
    }

    renderMore() {
        let { parentId, parentSlug } = this.props;
        return (
            <li className={`menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-has-children menu-item-594 awemenu-item awemenu-fadeup awemenu-has-children`} >
                <a className="cursor-pointer" onClick={e => {
                    e.preventDefault();
                    this.handleClickMore(parentId, parentSlug);
                    return false;
                }}>
                    {this.lang.more}
                </a>
            </li>
        );
    }

    renderList() {
        let { windowWidth } = this.state;
        let mobile = windowWidth <= MOBILE_VIEW;

        return mobile 
            ? this.renderMobile() 
            : this.renderDesktop();
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ 
            windowWidth: window.innerWidth, 
            windowHeight: window.innerHeight
        });
    }

    handleHoverDropDown(id) {
        this.setState({ selectedCategoryId: id });
    }

    handleLeaveDropDown = () => {
        let { selectedCategoryId } = this.state;
        if (selectedCategoryId !== 0) {
            this.setState({ selectedCategoryId: 0 });
        }
    }

    handleClickDropDown = (e, item) => {
        e.stopPropagation();
        let { selectedCategoryId } = this.state;
        if(selectedCategoryId > 0) {
            this.setState({ selectedCategoryId: 0 });
        } else {
            this.setState({ selectedCategoryId: item.id });
        }
    }

    handleClick(item) {
        let isShop = this.isShop();
        let { selectedCategoryId } = this.state;
        if (isShop) {
            // Jeżeli ten sam przycisk został klikniętu drugi raz z
            // przeglądarka przechodzi do innego widoku
            if(selectedCategoryId === item.id) {
                // this.props.handleClick(item);
                this.goto(`/${this.props.partUrl}/${item.slug}`);
            }
        } else {
            // Jeżeli ten sam przycisk został klikniętu drugi raz z
            // przeglądarka przechodzi do innego widoku
            if(selectedCategoryId === item.id) {
                this.goto(`/${this.props.partUrl}/${item.slug}`);
            }
        }
        this.setState({ selectedCategoryId: item.id });
    }

    handleClickMore(parentId, parentSlug) {
        let isShop = this.isShop();
        if (isShop) {
            // Wraca do roota
            if (parentId === 0) { 
                this.props.resetView();
                window.history.pushState('', '', `/${parentSlug}`);
            // Przechodzi do rodzica
            } else {
                this.props.handleClick({ id: parentId, slug: parentSlug });
            }
        } else {
            this.goto(`/${parentSlug}`);
        }
    }

    isShop() {
        return window.location.href.indexOf(this.lang.slug.shop) > -1;
    }

    goto = (url) => {
        window.location.href = url;
    }
}

export default DropdownMenuComponent;