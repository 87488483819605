export default {
    terms_and_conditions_slug: 'regulamin',
    regulamin: 'Regulamin',
    about_us_slug: 'o-nas',
    'o-nas': 'O nas',
    policies_slug: 'warunki-umowy',
    'warunki-umowy': 'Warunki umowy',
    contact_slug: 'kontakt',
    'kontakt': 'Kontakt',
    how_to_buy_slug: 'jak-kupowac',
    'jak-kupowac': 'Jak kupować',
    footwear_how_choose_size_slug: 'obuwie-jak-dobrac-rozmiar',
    'obuwie-jak-dobrac-rozmiar': 'Obuwie - jak dobrać rozmiar?',
    clothing_how_choose_size_slug: 'odziez-jak-dobrac-rozmiar',
    'odziez-jak-dobrac-rozmiar': 'Odzież - jak dobrać rozmiar?',
    delivery_slug: 'dostawa',
    'dostawa': 'Dostawa',
    payment_methods_slug: 'metody-platnosci',
    'metody-platnosci': 'Metody płatności',
    faq_slug: 'faq',
    'faq': 'FAQ',
    returns_and_replacements_slug: 'zwroty-i-reklamacje',
    'zwrot': 'Zwroty i reklamacje',
    slug: {
        terms_and_conditions_slug: 'regulamin',
        about_us_slug: 'o-nas',
        policies_slug: 'warunki-umowy',
        contact_slug: 'kontakt',
        how_to_buy_slug: 'jak-kupowac',
        footwear_how_choose_size_slug: 'obuwie-jak-dobrac-rozmiar',
        clothing_how_choose_size_slug: 'odziez-jak-dobrac-rozmiar',
        delivery_slug: 'dostawa',
        payment_methods_slug: 'metody-platnosci',
        faq_slug: 'faq',
        returns_and_replacements_slug: 'zwroty-i-reklamacje',
        privacy_policy: 'polityka-prywatnosci',
        shipping_costs: 'koszty-wysylki',
        payment_methods: 'sposoby-platnosci',
        damaged_goods: 'uszkodzony-towar',
        i_want_to_exchange_the_goods: 'chce-wymienic-towar',
        refund_or_complaint: 'zwracam-lub-reklamuje'
    }
}