import React from 'react';
import { Route } from 'react-router-dom';
import Default from './default';
import Home from './home';

class ContainerSwitch extends React.PureComponent {
    render() {
        return (
            ({
                default: (
                    <Default {...this.props} />
                ),
                home: (
                    <Home {...this.props} />
                )
            }[this.props.type])
        )
    }
}

export default ContainerSwitch;