export default {
    cookie_info: 'Strona internetowa wykorzystuje pliki cookies. Używamy informacji zapisanych za pomocą cookies i podobnych technologii m.in. w optymalizacyjnych i statystycznych oraz w celu dostosowania naszych serwisów do indywidualnych potrzeb użytkowników. Mogą też stosować je współpracujący z nami firmy badawcze oraz dostawcy aplikacji multimedialnych. W programie służącym do obsługi internetu można zmienić ustawienia dotyczące cookies. Korzystanie z naszych serwisów internetowych bez zmiany ustawień dotyczących cookies oznacza, że będą one zapisane w pamięci urządzenia.',
    back_top: 'Wróć do góry',
    shop: 'sklep',
    search_title: 'Szukaj',
    enter_searching_product_title: 'Wprowadź wyszukiwany produkt...',
    info: 'informacje',
    about_us_title: 'O nas',
    policies_title: 'Warunki umowy',
    contact_title: 'Kontakt',
    shopping: 'zakupy',
    how_to_buy_title: 'Jak kupować',
    footwear_how_choose_size_title: 'Obuwie - jak dobrać rozmiar?',
    clothing_how_choose_size_title: 'Odzież - jak dobrać rozmiar?',
    customer_service: 'obsługa klienta',
    delivery_title: 'Dostawa',
    returns_and_replacements_title: 'Zwroty i reklamacje',
    payment_methods_title: 'Metody płatności',
    payment_accept: 'Akceptowalne płatności',
    like_us: 'polub nas',
    page: 'strona',
    slug_about_us: 'o-nas',
    slug_policies: 'warunki-umowy',
    slug_contact: 'kontakt',
    slug_how_to_buy: 'jak-kupowac',
    slug_footwear_how_choose_size: 'obuwie-jak-dobrac-rozmiar',
    slug_clothing_how_choose_size: 'odziez-jak-dobrac-rozmiar',
    slug_delivery: 'dostawa',
    slug_payment_methods: 'metody-platnosci',
    slug_faq: 'faq',
    slug_returns_and_replacements: 'zwroty-i-reklamacje',
    slug: {
        shop: 'sklep',
        page: 'strona',
        slug_about_us: 'o-nas',
        slug_policies: 'warunki-umowy',
        slug_contact: 'kontakt',
        slug_how_to_buy: 'jak-kupowac',
        slug_footwear_how_choose_size: 'obuwie-jak-dobrac-rozmiar',
        slug_clothing_how_choose_size: 'odziez-jak-dobrac-rozmiar',
        slug_delivery: 'dostawa',
        slug_returns_and_replacements: 'zwroty-i-reklamacje',
        slug_payment_methods: 'metody-platnosci',
        slug_faq: 'faq',
        shipping_costs: 'koszty-wysylki',
        payment_methods: 'sposoby-platnosci',
        damaged_goods: 'uszkodzony-towar',
        i_want_to_exchange_the_goods: 'chce-wymienic-towar',
        refund_or_complaint: 'zwracam-lub-reklamuje'
    },
    privacy_policy: 'Polityka prywatności',
    shipping_costs: 'Koszty wysyłki',
    payment_methods: 'Sposoby płatności',
    package_received: 'Odebrałem przesyłkę',
    damaged_goods: 'Uszkodzony towar',
    i_want_to_exchange_the_goods: 'Chcę wymienić towar',
    refund_or_complaint: 'Zwracam lub reklamuję',
    quick_payments: 'Szybkie płatności'
}