import React from 'react';
import { Route } from 'react-router-dom';
import Page from './page';

class SwitchDescriptionPages extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Route exact path={`${this.props.route}/:slug`}  component={(props) => <Page {...props} moduleUrl={this.props.route}/>}/>
            </React.Fragment>
        )
    }
}

export default SwitchDescriptionPages;