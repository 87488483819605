import React from 'react';
import { load } from 'boost/dispatch';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import Async from 'react-select/lib/Async';
import Lang from '../lang';
import PropTypes from 'prop-types';
import reactSelectStyles from 'partials/react-select-styles';

const lang = Lang[global.language];

class ProductFilterWidget extends React.Component {

    _timeout = null;

    render() {
        let { 
            name, data, value, 
            no_options_message, async, onChange 
        } = this.props;

        return (
            <React.Fragment>
                {!async 
                ? 
                    <Select
                        value={value}
                        onChange={onChange}
                        options={data}
                        isSearchable={true}
                        isClearable={true}
                        placeholder={lang[name]}
                        styles={reactSelectStyles}
                        noOptionsMessage={() => no_options_message}
                    />
                :
                    <Async 
                        value={value}
                        onChange={onChange}
                        placeholder={lang[name]}
                        isClearable={true}
                        cacheOptions 
                        defaultOptions 
                        loadOptions={this.promiseOptions}
                        styles={reactSelectStyles}
                        noOptionsMessage={() => no_options_message}
                    />
                }
            </React.Fragment>
        );
    }

    promiseOptions = inputValue => {
        let { url, renderParam } = this.props;
        return new Promise(resolve => {

            if (this._timeout !== null) {
                clearTimeout(this._timeout);
            }

            this._timeout = setTimeout(() => {
                let filter = !!inputValue ? `?filtered[0][id]=${renderParam.value}&filtered[0][value]=${inputValue}` : '';
                this.props.load({ url: `${url}${filter}` }).then(res => {
                    if (!res.error) {
                        let data = this.props.setValue(res.data);
                        resolve(data);
                    }
                })
            }, 500);
        });
    }

}

ProductFilterWidget.propTypes = {
    data: PropTypes.array,
    name: PropTypes.string,
    setValue: PropTypes.func,
    value: PropTypes.any,
    no_options_message: PropTypes.string,
    async: PropTypes.bool,
    url: PropTypes.string,
    renderParam: PropTypes.object,
    onChange: PropTypes.func
};

ProductFilterWidget.defaultProps = {
    data: [],
    name: '',
    setValue: () => {},
    value: null,
    no_options_message: lang.no_options_message,
    async: false,
    url: '',
    renderParam: { value: 'id' },
    onChange: () => {}
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load
}, dispatch)

export default connect(
    null,
    mapDispatchToProps,
)(ProductFilterWidget);