import React from 'react';
import Lang from '../lang';
import API from 'boost/API';

class BankTransferDetails extends React.Component {

    lang = Lang[global.language];

    state = {
        bank_name: null,
        bank_account_number: null,
        firm_name: null,
        address: null,
        post_office: null,
        post_code: null,
        email_address: null,
        phone_number: null
    }

    render() {
        return (
            <section className='bank-transfer-details'>
                <h4>{this.lang.bank_transfer_details}</h4>
                <div>
                    <p>
                        <strong>
                            <i>{this.lang.include_order_number}</i>
                        </strong>
                    </p>
                    <p>{`${this.lang.bank_name}: ${this.state.bank_name}`}</p>
                    <p>{`${this.lang.bank_account_number}: ${this.state.bank_account_number}`}</p>
                </div>
                <address>
                    <p><strong>{`${this.lang.address}:`}</strong></p>
                    {this.state.firm_name}<br />
                    {`${this.state.post_code} ${this.state.post_office}`}<br />
                    {this.state.address}<br /> 
                </address>
            </section>
        );
    }

    componentDidMount() {
        API.get({ url: `/api/configuration` }).then(res => {
            if (!res.error) {
                let data = res.data.data;
                this.setState({
                   bank_name: data.seller_bank_account_name,
                   bank_account_number: data.seller_bank_account_number,
                   firm_name: data.seller_name,
                   post_code: data.seller_postal_code,
                   post_office: data.seller_post_office,
                   address: data.seller_address
                })
            }
        });
    }

}

export default BankTransferDetails;