import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import API from 'boost/API';
import Lang from '../lang';

class ServiceAddComments extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        star: 0,
        try_send: false
    }

    render() {
        let {user} = this.props;
        return (
            <section className="woocommerce-order-details">

                <h2 className="woocommerce-order-details__title">{this.lang.add_comment}</h2>
                {
                    user ?
                    <form onSubmit={this.sendComment}>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <label htmlFor='reply-name'>{this.lang.first_name_title} <sup>*</sup></label>
                                    <input type='text' name='nick' className='form-control' id='reply-name' placeholder={this.lang.add_name_title} defaultValue={user.data.name} required/>
                                </div>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label htmlFor='reply-text'>{this.lang.comment} <sup>*</sup></label>
                            <textarea name='reply-text' className='form-control' name='content' id='reply-text' rows='7' placeholder={this.lang.add_content_comment} required></textarea>
                        </div>

                        <div className='form-submit clearfix'>
                            {
                                this.state.try_send && !this.state.star ?
                                    <div className='alert alert-danger'>{this.lang.must_choose_rating}</div>
                                :
                                    null
                            }
                            <div className='review-rating'>
                                <span className='title'>{this.lang.your_opinion}: </span>

                                <span className='rating small live'>
                                    <span className={`${this.state.star > 0 ? 'star' : ''}`} onClick={() => this.setStar(1)}></span>
                                    <span className={`${this.state.star > 1 ? 'star' : ''}`} onClick={() => this.setStar(2)}></span>
                                    <span className={`${this.state.star > 2 ? 'star' : ''}`} onClick={() => this.setStar(3)}></span>
                                    <span className={`${this.state.star > 3 ? 'star' : ''}`} onClick={() => this.setStar(4)}></span>
                                    <span className={`${this.state.star > 4 ? 'star' : ''}`} onClick={() => this.setStar(5)}></span>
                                </span>

                            </div>

                            <div className='pull-right'>
                                <button type='submit' className='submit btn btn-lg btn-default'>{this.lang.add_comment}</button>
                            </div>
                        </div>
                    </form>
                    : null
                }
                
            </section>
        )
    }

    sendComment = (e) => {
        e.preventDefault();
        let data = e.target.serialization();
        this.setState({try_send: true});
        if(this.state.star) {
            let type = {};
            let {params} = this.props.match;
            if(params.order_id) {
                type.order_id = params.order_id
            } else if(params.product_id) {
                type.product_id = params.product_id
            }
            API.post('/api/comments/comment',
                {
                    ...data,
                    rating: this.state.star,
                    contractor_id: this.props.user.data.contractor_id,
                    ...type
                }
            ).then(res => {
                if(res.status == 200) {
                    toastr.success(res.data.message)
                }
                this.props.history.goBack();
            });
        }
    }
    setStar = (val) => {
        this.setState({star: val});
    }
    componentDidMount() {

    }
}
const mapStateToProps = (state) => {
    return {
        loader: state.boost.loader,
        user: state.boost.user,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue,
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ServiceAddComments);