import React from 'react';
import PropTypes from 'prop-types';

class Pagination extends React.PureComponent {
    
    render() {
        let {last_page, page} = this.props;
        return (
            last_page > 1 &&
            <ul className='pagination'>
                 <li className='pagination-prev'>
                    {
                        page > 1 ?
                            <a onClick={e => {
                                e.preventDefault();
                                this.props.onChange(page - 1);
                                return false;
                            }}>
                                <i className='icon icon-arrow-prev'></i>
                            </a>
                        :
                        <span>
                            <i className='icon icon-arrow-prev'></i>
                        </span>
                    }
                </li>
                <li className={ page === 1 ? 'active' : ''}>{this.pageElement(1)}</li>
                {
                    page > 4 ?
                        <li><span>...</span></li>
                    :
                        <li className={ page === 2 ? 'active' : ''}>{this.pageElement(2)}</li>
                }
                {
                    page - 2 > 2 && <li>{this.pageElement(page - 2)}</li>
                }
                {
                    page - 1 > 2 && page - 1 < last_page - 1 && <li>{this.pageElement(page - 1)}</li>
                }
                {
                    page > 2 && page < last_page - 1 && <li className='active'>{this.pageElement(page)}</li>
                }
                {
                    page + 1 < last_page - 1 && page + 1 > 4 && <li>{this.pageElement(page + 1)}</li>
                }
                {
                    page + 2 < last_page - 1 && <li>{this.pageElement(page + 2)}</li>
                }
                {
                    last_page > 2 &&
                    <React.Fragment>
                        {
                            page < last_page - 3 ?
                                <li><span>...</span></li>
                            :
                                last_page > 3 && <li className={ page === last_page - 1 ? 'active' : ''}>{this.pageElement(last_page - 1)}</li>
                        }
                        <li className={ page === last_page ? 'active' : ''}>{this.pageElement(last_page)}</li>
                    </React.Fragment>
                }
               
                
                <li className='pagination-next'>
                    {
                        page < last_page ?
                            <a onClick={e => {
                                e.preventDefault();
                                this.props.onChange(page + 1);
                                return false;
                            }}>
                                <i className='icon icon-arrow-next'></i>
                            </a>
                        :
                            <span>
                                <i className='icon icon-arrow-next'></i>
                            </span>
                    }
                </li>
            </ul>
        )
    }

    pageElement(page) {
        return (
            <a onClick={e => {
                e.preventDefault();
                this.props.onChange(page);
                return false;
            }}>{page}</a>
        )
    }

}

Pagination.defaultProps = {
    last_page: 1,
    page: 1,
    onChange: () => {}
}

Pagination.propTypes = {
    last_page: PropTypes.number,
    page: PropTypes.number,
    onChange: PropTypes.func
}

export default Pagination