import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Lang from '../lang';
import { Link } from 'react-router-dom';
import Loader from 'partials/loader';
import Helper from 'helpers';

class OrdersUser extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        order: {},
        loader: true,
        invoice_url: false
    }

    _isMounted = false;

    render() {

        let total = 0;

        let { order } = this.state;

        return (
            this.state.loader ?
                <Loader type='small' />
                :
                <section className="woocommerce-order-details">
                    
                    <h2 className="woocommerce-order-details__title">{this.lang.order_details}</h2>

                    <div className='table-responsive'>    
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope='col'>{this.lang.product_name}</th>
                                    <th scope='col'>{this.lang.quantity_title}</th>
                                    <th scope='col'>{this.lang.price_title}</th>
                                    <th scope='col'>{this.lang.value_title}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    order.items.map((item, key) => {
                                        {(() => { total += item.gross * item.quantity })()}
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <Link to={`/${this.lang.shop_slug}/${item.slug}/${item.variation_id}`} title='' >                                                    
                                                                {
                                                                    item.images.length 
                                                                        ?
                                                                        <img src={item.images[0].thumb} alt={item.images[0].alt} />
                                                                        : 
                                                                        <img src='/themes/default/img/samples/products/cart/1.jpg' alt='' className='current'/>
                                                                }
                                                            </Link>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <Link to={`/${this.lang.shop_slug}/${item.slug}/${item.variation_id}`} title='' >  
                                                                <span>{item.name}</span>
                                                            </Link>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <Link to={`${this.props.route}/${this.lang.slug_add_comment_product}/${item.product_id}`} className='woocommerce-button button view margin-left-10 pull-right' title={this.lang.add_review}> 
                                                                <i className='fa fa-comment'></i> 
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-right'><span>{item.quantity}</span></td>
                                                <td className='text-right'><span>{`${Helper.currencyFormatter().format(item.gross)}`}</span></td>
                                                <td className='text-right'><span>{`${Helper.currencyFormatter().format(item.gross * item.quantity)}`}</span></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="3" className='text-right'>
                                        <span className='bold'>{this.lang.summary_title}</span>
                                    </td>
                                    <td colSpan="1" className='text-right'>
                                        <span className='bold'>{`${Helper.currencyFormatter().format(total)}`}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className='text-right'><span className='bold'>{`${this.lang.shipment_title} - ${this.lang.courier}`}</span></td>
                                    <td colSpan="1" className='text-right'><span className='bold'>{`${Helper.currencyFormatter().format(order.delivery_service_price)}`}</span></td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className='text-right'><span className='bold'>{this.lang.together_title}</span></td>
                                    <td colSpan="1" className='text-right'><span className='bold'>{`${Helper.currencyFormatter().format(order.total)}`}</span></td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className='text-right'><span className='bold'>{this.lang.payment_status_title}</span></td>
                                    <td colSpan="1" className='text-right'><span className='bold'>{order.status}</span></td>
                                </tr>
                                <tr>
                                    <td colSpan="3" className='text-right'><span className='bold'>{this.lang.payment_method_title}</span></td>
                                    <td colSpan="1" className='text-right'><span className='bold'>{this.lang[order.method_of_payment]}</span></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <h3 className="woocommerce-order-details__title">{this.lang.shipping_address}</h3>

                    <div className='table-responsive'>    
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td colSpan='1'><span className='bold'>{this.lang.first_name_title}</span></td>
                                    <td colSpan="1" className='text-right'><span>{order.shipping.shipping_first_name}</span></td>
                                    <td colSpan='1'><span className='bold'>{this.lang.last_name_title}</span></td>
                                    <td colSpan="1" className='text-right'><span>{order.shipping.shipping_last_name}</span></td>
                                </tr>
                                <tr>
                                    <td colSpan='1'><span className='bold'>{this.lang.firm_title}</span></td>
                                    <td colSpan="1" className='text-right'><span>{order.shipping.shipping_firm}</span></td>
                                    <td colSpan='1'><span className='bold'>{this.lang.nip_title}</span></td>
                                    <td colSpan="1" className='text-right'><span>{order.shipping.shipping_nip}</span></td>
                                </tr>
                                <tr>
                                    <td colSpan='1'><span className='bold'>{this.lang.country_title}</span></td>
                                    <td colSpan="1" className='text-right'><span>{order.shipping.shipping_country}</span></td>
                                    <td colSpan='1'><span className='bold'>{this.lang.street_title}</span></td>
                                    <td colSpan="1" className='text-right'><span>{order.shipping.shipping_street}</span></td>
                                </tr>
                                <tr>
                                    <td colSpan='1'><span className='bold'>{this.lang.post_code_title}</span></td>
                                    <td colSpan="1" className='text-right'><span>{order.shipping.shipping_post_code}</span></td>
                                    <td colSpan='1'><span className='bold'>{this.lang.city_title}</span></td>
                                    <td colSpan="1" className='text-right'><span>{order.shipping.shipping_city}</span></td>
                                </tr>
                                <tr>
                                    <td colSpan='1'><span className='bold'>{this.lang.phone_number_title}</span></td>
                                    <td colSpan="1" className='text-right'><span>{order.shipping.shipping_phone_number}</span></td>
                                    <td colSpan='2'></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {
                        order.invoice &&
                            <div className='pull-right margin-top-10'> 
                                <button type="button" className="btn btn-primary" onClick={this.generateInvoice}>
                                    <span>{this.lang.download_invoice_title}</span>
                                </button>
                            </div>
                    }
                </section>
        )
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.load({ url: `api/shop/customer/orders/${this.props.match.params.id}` }).then(res => {
            if (this._isMounted) {
                this.setState({ loader: false, order: res.data });
            }
        });
    }

    generateInvoice = () => {
        this.props.load({ url: `/api/shop/customer/invoice/${this.state.order.invoice.id}` }).then(res => {
            window.location = res.data[0].url;
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

}

const mapStateToProps = (state) => {
    return {
        loader: state.boost.loader,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue,
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrdersUser);