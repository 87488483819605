import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load } from 'boost/dispatch';
import Loader from 'partials/loader';
import Lang from '../lang';
import API from 'boost/API';
import Helper from 'helpers';

const COMPLAINT = 'complaint';

class AddReturnComplaintComponent extends React.PureComponent {

    lang = Lang[global.language];

    state = {
        module_name: this.props.match.params.module_name,
        order_id: this.props.match.params.order_id,
        product_id: this.props.match.params.product_id,
        i: this.props.match.params.i,
        customer_id: 0,
        number: '',
        name: '',
        sku: '',
        price: '',
        quantity: 1,
        email: '',
        quantity_id: 0,
        complaint_reasons: [],
        return_reasons: [],
        order_created_at: '',
        complaint_reason_id: 0,
        return_reason_id: 0,
        errors: {},
        preferred_resolve_method: '',
        order: {},
        loader: true,
    }

    uniqURL = {
        [this.lang.slug_return]: 'return',
        [this.lang.slug_complaint]: 'complaint',
    }

    checkUniqURL(slug) {
        if (this.uniqURL[slug]) {
            return this.uniqURL[slug]
        }
        return 'not-uniq';
    }

    render() {
        let module_name = this.checkUniqURL(this.state.module_name);

        return (
            !this.state.loader ?
                <form onSubmit={this.submit}>
                    <h3 className='upper'>{this.state.module_name}</h3>
                    <hr />
                    <h4>{this.lang.order_details}</h4>
                    <div className='form-group'>
                        <label htmlFor='number'>{this.lang.order_number}</label>
                        <input type='text' className='form-control' name='number' id='number' value={this.state.number} disabled />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='order_id'>ID</label>
                        <input type='text' className='form-control' name='order_id' id='order_id' value={this.state.order_id} disabled />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='created_at'>{this.lang.order_created_at}</label>
                        <input type='text' className='form-control' name='created_at' id='created_at' value={this.state.order_created_at} disabled />
                    </div>
                    <hr />
                    <h4>{this.lang.product_information}</h4>
                    <div className='form-group'>
                        <label htmlFor='name'>{this.lang.product_name}</label>
                        <input type='text' className='form-control' name='name' id='name' value={this.state.name} disabled />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='sku'>{this.lang.product_index}</label>
                        <input type='text' className='form-control' name='sku' id='sku' value={this.state.sku} disabled />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='price'>{this.lang.price}</label>
                        <input type='text' className='form-control' name='price' id='price' value={Helper.currencyFormatter().format(this.state.price)} disabled />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='quantity'>{this.lang.quantity}</label>
                        <input type='text' className='form-control' name='quantity' id='quantity' value='1' disabled />
                    </div>
                    <div className='form-group'>
                        <label htmlFor={`${module_name}_reason_id`}>{this.lang.complaint_reason} <sup>*</sup></label>
                        <select className='form-control' name={`${module_name}_reason_id`} id={`${module_name}_reason_id`} onChange={this.updateState} value={this.state[`${module_name}_reason_id`]} required >
                            <option value="">{this.lang.select}</option>
                            {
                                this.state[`${module_name}_reasons`].map((reason, key) => 
                                    <option value={reason.value} key={key}>{reason.description}</option>
                                )
                            }
                        </select>
                        {this.showError(`${module_name}_reason_id`)}  
                    </div>
                    <div className='form-group'>
                        <label htmlFor='email'>{this.lang.email} <sup>*</sup></label>
                        <input type='email' className='form-control' name='email' id='email' onChange={this.updateState} value={this.state.email} required />
                        {this.showError('email')}   
                    </div>
                    {
                        COMPLAINT === module_name 
                        && 
                        <div className='form-group'>
                            <label htmlFor='preferred_resolve_method'>{this.lang.preferred_resolve_method}</label>
                            <input type='text' className='form-control' name='preferred_resolve_method' id='preferred_resolve_method' onChange={this.updateState} value={this.state.preferred_resolve_method} />
                        </div>
                    }
                    <div className='form-group'>
                        <button type='submit' className='btn btn-primary margin-right-5'>{this.lang.save_title}</button>
                        <button type='button' className='btn btn-primary' onClick={this.goBack}>{this.lang.back_title}</button>
                    </div>
                </form>
                : 
                <Loader type='small' />
        )
    }

    componentDidMount() {
        this.props.load({ url: `api/shop/customer/orders/${this.state.order_id}` }).then(res => {
            this.loadOrder(res.data);
        });

        let module_name = this.checkUniqURL(this.state.module_name);
        let reasons = COMPLAINT === module_name ? 'get_complaint_reasons' : 'get_reasons';

        this.props.load({ url: `api/admin/${reasons}` }).then(res => {
            this.setState({ [`${module_name}_reasons`]: res.data });
        });
    }

    /**
     * Wyciąga produkt z zamówienia
     * 
     * @param {Object} order - Obiekt zamówienia
     * @return {Object}
     */
    orderProduct(order) {
        return order.items.find(item => item.id == this.state.product_id);
    }

    /**
     * Ustawia dane zamówienia i produktu w store
     * 
     * @param {Object} order - Obiekt zamówienia
     * @return {undefined}
     */
    loadOrder(order) {
        let product = this.orderProduct(order);
        this.setState({
            loader: false,
            customer_id: order.customer_id,
            number: order.number,
            name: product.name,
            sku: product.sku,
            price: product.gross,
            email: order.customer,
            quantity_id: `qty_${product.id}_${this.state.i}`,
            order_created_at: order.created_at,
            order: {
                id: order.id,
                status: order.status,
                number: order.number,
                total: order.total,
                counter: order.counter,
                method_of_payment: order.method_of_payment,
                payment_id: order.payment_id,
                courier_id: order.courier_id || 0,
                delivery_service_id: order.delivery_service_id || 0
            }
        });
    }

    /**
     * Aktualizuje wartość state
     * 
     * @param {Object} e - Event
     * @return {undefined}
     */
    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    /**
     * Umożliwia powrót na poprzednią stronę
     * 
     * @return {undefined}
     */
    goBack = () => {
        this.props.history.goBack();
    }

    requestData() {
        let { 
            order_id, product_id, customer_id, number, 
            name, sku, price, quantity, 
            email, quantity_id, complaint_reason_id, return_reason_id, preferred_resolve_method,
            order 
        } = this.state;

        let request_data = { 
            order_id, product_id, customer_id, number, 
            name, sku, price, quantity, 
            email, quantity_id, complaint_reason_id, return_reason_id, preferred_resolve_method,
            order
        };

        return request_data;
    }

    submit = (e) => {
        e.preventDefault();
        this.showLoader();
        this.clearErrors();
        let request_data = this.requestData();
        let module_name = this.checkUniqURL(this.state.module_name);

        API.post( `api/admin/${module_name}_order`, request_data ).then(res => {
            if (!res.error) {
                toastr.success(res.data.message);
                this.goBack();
            } else {
                if (res.data.errors) {
                    this.setState({
                        errors: res.data.errors
                    })
                } else {
                    toastr.error(res.data.message);
                }
            }
            this.showLoader();
        })
    }

    /**
     * Wyświetla błędy walidacji
     * 
     * @param {String} name - Nazwa input
     * @return {undefined}
     */
    showError = (name) => {
        let { errors } = this.state;
        if (errors[name]) {
            return (
                <React.Fragment>
                    {
                        errors[name].map((error, key) =>
                            <div className={`text-danger`}  key={key}>{error}</div>
                        )
                    }
                </React.Fragment>
            )
        }
    }

    /**
     * Wyświetla lub ukrywa loader
     * 
     * @return {undefined}
     */
    showLoader = () => {
        this.setState({ loader: !this.state.loader });
    }

    /**
     * Usuwa z obiektu błedów informacje o błedach walidacji pól formularza
     * 
     * @return {undefined} 
     */
    clearErrors = () => {
        this.setState({
            errors: {}
        }); 
    }

}

const mapDispatchToProps = dispatch => bindActionCreators({
    load
}, dispatch)

export default connect(
    null,
    mapDispatchToProps,
)(AddReturnComplaintComponent);