import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { load, updateValue } from 'boost/dispatch';
import Lang from '../lang/';
import { Link } from 'react-router-dom';
import Loader from 'partials/loader';
import Helper from 'helpers';

class OrdersUser extends React.PureComponent {
    lang = Lang[global.language];
    state = {
        orders: [],
        loader: true
    }
    render() {
        return (
            this.state.loader ?
                <Loader type='small' />
                : this.state.orders.length ?
                    <table className='woocommerce-orders-table woocommerce-MyAccount-orders shop_table shop_table_responsive my_account_orders account-orders-table'>
                        <thead>
                            <tr>
                                <th className='woocommerce-orders-table__header woocommerce-orders-table__header-order-number'><span className='nobr'>{this.lang.order}</span></th>
                                <th className='woocommerce-orders-table__header woocommerce-orders-table__header-order-date'><span className='nobr'>{this.lang.date}</span></th>
                                <th className='woocommerce-orders-table__header woocommerce-orders-table__header-order-status'><span className='nobr'>{this.lang.status}</span></th>
                                <th className='woocommerce-orders-table__header woocommerce-orders-table__header-order-total'><span className='nobr'>{this.lang.total}</span></th>
                                <th className='woocommerce-orders-table__header woocommerce-orders-table__header-order-actions'><span className='nobr'>{this.lang.actions}</span></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                this.state.orders.map((order, key) =>
                                    <tr className='woocommerce-orders-table__row woocommerce-orders-table__row--status-on-hold order' key={key}>
                                        <td className='woocommerce-orders-table__cell woocommerce-orders-table__cell-order-number' data-title='Order'>
                                            {order.number}
                                        </td>
                                        <td className='woocommerce-orders-table__cell woocommerce-orders-table__cell-order-date' data-title='Date'>
                                            {order.created_at}
                                        </td>
                                        <td className='woocommerce-orders-table__cell woocommerce-orders-table__cell-order-status' data-title='Status'>
                                            {order.status}
                                        </td>
                                        <td className='woocommerce-orders-table__cell woocommerce-orders-table__cell-order-total' data-title='Total'>
                                            {Helper.currencyFormatter().format(this.convertToFloat(order.total))}
                                        </td>
                                        <td className='woocommerce-orders-table__cell woocommerce-orders-table__cell-order-actions' data-title='Actions'>
                                            <Link to={`${this.props.route}/${this.lang.slug_orders}/${order.id}`} className='woocommerce-button button view' title={this.lang.view}>
                                                <i className='fa fa-search'></i>
                                            </Link>
                                            <Link to={`${this.props.route}/${this.lang.slug_add_comment_order}/${order.id}`} className='woocommerce-button button view margin-left-10' title={this.lang.add_review}>
                                                <i className='fa fa-comment'></i>
                                            </Link>
                                            <Link to={`${this.props.route}/${this.lang.slug_returns_and_complaints}/${order.id}`} className='woocommerce-button button view margin-left-10' title={this.lang.returns_and_complaints}>
                                                <i className='fa fa-reply'></i>
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    : <div className="alert alert-info alert-outline">
                        <p>Brak zamówień</p>
                    </div>
        )
    }

    /**
     * Konwertuje string na float
     * 
     * @param {String|Number}
     * @return {Number}
     */
    convertToFloat = (value) => {
        return parseFloat(value.replace(",", "."));
    }

    componentDidMount() {
        this.props.load({ url: `api/shop/customer/orders` }).then(res => {
            this.setState({ loader: false, orders: res.data });
        });
    }

}

const mapStateToProps = (state) => {
    return {
        loader: state.boost.loader,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load,
    updateValue,
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrdersUser);