import React from 'react';
import PropTypes from 'prop-types';
import './custom.scss';

const Loader = (props) =>
    props.type === 'small' ?
    <div className='loader small'></div>
    :
    <div className='awe-page-loading'>
        <div className='awe-loading-wrapper'>
            <div className='awe-loading-icon'>
                <span className='icon icon-logo'></span>
            </div>

            <div className='progress'>
                <div className='progress-bar progress-bar-success' role='progressbar' aria-valuenow='40' aria-valuemin='0' aria-valuemax='100'></div>
            </div>
        </div>
    </div>

Loader.defaultProps = {
    type: 'normal'
}

Loader.propTypes = {
    type: PropTypes.string,
}

export default Loader;